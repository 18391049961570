import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import Swal from "sweetalert2";
import Spinner from "../Shared/Spinner";
import { FileUploader } from "react-drag-drop-files";

const UploadFiles = ({
  data: {
    isImg,
    isMultiple,
    path,
    onComplete,
    customFileName,
    classes,
    containerClasses,
    numericName,
  },
}) => {
  const [Files, setFiles] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  // const { item_name_h, item_desc_h } = ItemData;
  useEffect(() => {
    setFiles(false);
  }, []);
  const handleUploadReq = async (e) => {
    // e.preventDefault();
    setIsLoading(true);
    // return;
    const files = e.length ? e : [e];
    await Promise.all(
      Array.from(files).map(async (file, i) => {
        const formData = new FormData();
        formData.append("files", file);
        formData.append("path", path);
        console.log({ customFileName, numericName, path });
        if (customFileName) {
          formData.append("name", customFileName);
        } else if (numericName) {
          formData.append("name", i + ".png");
        }
        const fileRes = await axios.post(`/api/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log({ fileRes });
      })
    );

    setIsLoading(false);
    setFiles(false);
    onComplete(files);
  };

  if (IsLoading) {
    return <Spinner small />;
  }
  return (
    <>
      {/* <form onSubmit={handleUploadReq} id="upload" class="w-full"> */}
      {/* <form action="http://localhost:3900/api/uploadImg" method="post" class="w-full"> */}
      {/* name field  */}
      <div class="md:flex md:items-center mb-2" className={containerClasses}>
        <div class="md:w-full">
          <div class="flex items-center justify-center w-full">
            <FileUploader
              handleChange={(e) => {
                console.log({ e });
                setFiles(e);
                handleUploadReq(e);
              }}
              name="files"
              types={["JPG", "PNG", "GIF"]}
              multiple={isMultiple}
              required
              classes={"!p-4 w-full !h-[88px] [&>svg]:ml-3 " + classes}
              hoverTitle=" "
              label={
                Files ? Files.length + "Files were selected" : "Click or drag a file here"
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadFiles;
