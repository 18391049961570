import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { languages, translatedWeb, UserContext } from "../../global";
import { PiBroomBold } from "react-icons/pi";
import UpdatePortrait from "../Shared/UpdatePortrait";

const Rest = ({
  itemData,
  handleDelete,
  handleUpdate,
  update,
  setUpdate,
  Lang,
}) => {
  const { date_upd, id, name } = itemData;
  // const getByLang = (e) => e + "_" + Lang;
  // const item_name = itemData[getByLang("item_name")];
  // const item_desc = itemData[getByLang("item_desc")];
  const { rest, RestData, setRestData, DevicesList, clientlanguage } = useContext(UserContext);
  const [IsDeleted, setIsDeleted] = useState(false);
  // const isActive = () => {
  //   let res;
  //   if (categories.length) {
  //     console.log(categories);
  //     categories.every((e) => {
  //       if (e.isActive === "1") {
  //         res = "כן";
  //         return false;
  //       }
  //     });
  //   } else {
  //     res = "לא משוייך לקטגוריה";
  //   }
  //   return res || "לא";
  // };



  const [Edit, setEdit] = useState(false);
  // const handleUpdate = async (item) => {
  //   setEdit((e) => !e);
  // };
  const none = <span style={{ color: "red" }}>X</span>;
  const devicesCount = DevicesList.filter(
    (device) => device.rest == id && !device.isDev
  ).length;
  const handleMDM = async (data) => {
    Swal.fire({
      title: translatedWeb?.rest?.plsWait[clientlanguage],
      html: translatedWeb?.rest?.plsWait[clientlanguage],
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let result = await axios.post(`/mdm/${data.action}`, data.body);

    let res = "הפעולה נשלחה בהצלחה";
    try {
      if (data.body?.policyName) {
        if (data.body?.policyName == "policy0" || data.body?.policyName == "test") {
          res = `<i class="fad fa-lock"></i>  ${translatedWeb?.rest?.allLock[clientlanguage]}`;
        } else {
          res = ` <i class="fad fa-lock-open-alt"></i> ${translatedWeb?.rest?.allOpen[clientlanguage]} `;
        }
      } else if (data?.action == "clearCacheRest")
        res = translatedWeb?.rest?.cleanDataOk[clientlanguage];
      else if (data?.action == "rebootRest")
        res = translatedWeb?.rest?.rebootOk[clientlanguage];
      Swal.fire({
        title: `${res} \n ${translatedWeb?.rest?.succeeded[clientlanguage]} ${result.data?.success} ${translatedWeb?.rest?.of[clientlanguage]} ${result.data?.length}`,
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (err) {
      Swal.fire({
        title: "תקלה: " + err,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  return (
    <tr
      className='border-b-2'
      style={{ background: devicesCount ? "#90EE90" : "#FFCCCB" }}>
      <label
        className='contents cursor-pointer'
        htmlFor='EditModal'
        onClick={() => handleUpdate(itemData)}>
        <td>{id}</td>
        <td>{name || none}</td>
        <td>{devicesCount}</td>
        <td>{date_upd ? new Date(date_upd).toLocaleString("he-IL") : none}</td>
        {/* <td className="flex px-0">
          <label
            onClick={() => handleUpdate({ id, name })}
            htmlFor="EditModal"
            className="mr-0 text-xl text-sky-500 cursor-pointer hover:bg-sky-600 rounded-full hover:text-white px-2 py-1 flex justify-center items-center"
          >
            <i className={"ri-pencil-line"}></i>
          </label> */}

        {/* <i
          onClick={() => handleDelete(name)}
          className="ri-delete-bin-7-line text-xl text-sky-500 cursor-pointer hover:bg-sky-600 rounded-full hover:text-white px-2 py-1"
        ></i> */}
        {/* </td> */}
      </label>
      <td>
        <span style={{ display: "flex", justifyContent: "center" }}>
          <label
            onClick={() =>
              handleMDM({
                action: `changeDevicePolicyRest`,
                body: { policyName: "default", rest: id },
              })
            }
            className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full hover:text-white px-2 py-1 flex justify-center items-center'>
            <i
              className='ri-lock-unlock-line'
              style={{ color: "green" }}></i>
          </label>

          {/* <label
            onClick={() =>
              handleMDM({
                action: `changeDevicePolicyRest`,
                body: { policyName: "policy0", rest: id },
              })
            }
            className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full hover:text-white px-2 py-1 flex justify-center items-center'>
            <i
              className='ri-lock-line'
              style={{ color: "red" }}></i>
          </label> */}
          <label
            onClick={() =>
              handleMDM({
                action: `clearCacheRest`,
                body: { rest: id },
              })
            }
            className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full px-2 py-1 flex justify-center items-center'>
            <PiBroomBold />
          </label>
          <label
            onClick={() =>
              handleMDM({
                action: `rebootRest`,
                body: { rest: id },
              })
            }
            className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full hover:text-white px-2 py-1 flex justify-center items-center'>
            <i
              className='ri-shut-down-line'
              style={{ color: "black" }}></i>
          </label>
          {/* </span> */}
          {/* </td>
      <td> */}
          {/* <span style={{ display: "flex", justifyContent: "space-between" }}> */}

          <label
            onClick={() =>
              handleMDM({
                action: `changeDevicePolicyRest`,
                body: { policyName: "test", rest: id },
              })
            }
            className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full hover:text-white px-2 py-1 flex justify-center items-center'>
            <i
              className='ri-lock-line'
              style={{ color: "red", }}></i>

          </label>
          {/* <label
            onClick={() =>
              handleMDM({
                action: `changeDevicePolicyRest`,
                body: { policyName: "menu5portrait", rest: id },
              })
            }
            className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full hover:text-white px-2 py-1 flex justify-center items-center'>
            <i class="fal fa-tablet-android" style={{ color: "red" }}></i>

          </label> */}
        </span>
      </td>
    </tr>
  );
};

export default Rest;
