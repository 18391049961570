import React, { useState, useContext, useMemo } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { languages, translatedWeb, UserContext } from "../../global";
import { MdImage, MdImageNotSupported } from "react-icons/md";

const Student = ({
  itemData,
  handleDelete,
  handleUpdate,
  update,
  setUpdate,
  Lang,
  Checklist,
  setChecklist
}) => {
  const { name, desc, catOrder, date_upd, id, isActive, order, img, changeBy } = itemData;
  const { rest, RestData, setRestData, clientlanguage } = useContext(UserContext);
  const [IsDeleted, setIsDeleted] = useState(false);

  const [Edit, setEdit] = useState(false);
  const [Checked, setChecked] = useState(false);
  const [descState, setDescState] = useState(false);
  const shorten = (overview, length = 5) => {
    if (!overview?.length) return "";
    let short = overview.split(" ");
    short = short.slice(0, length);
    short = short.join(" ");
    if (short !== overview) {
      if (short.slice(-1) === "," || short.slice(-1) === ".")
        short = short.slice(0, -1);
      short += "...";
    }
    return short;
  };
  const shortDesc = useMemo(() => shorten(desc[Lang]), [desc, Lang]);
  const none = <span style={{ color: "red" }}>X</span>;
  const yes = <span style={{ color: "green" }}>{translatedWeb?.categories?.active[clientlanguage]}</span>;
  const no = <span style={{ color: "red" }}> {translatedWeb?.categories?.inactive[clientlanguage]}</span>;
  const nothing = <span style={{ color: "red" }}>{translatedWeb?.categories?.nothing[clientlanguage]}</span>;

  let toUpdate = Object.assign({}, itemData);
  toUpdate.name = name[Lang];
  toUpdate.desc = desc[Lang];

  const categoriesToNames = () => {
    let string = "";
    // console.log({ RestData });
    RestData.categories.map((category) => {
      if (category.catOrder?.includes(id)) string += category.name[Lang] + "\n";
    });
    return string;
  };
  return (
    <tr className="border-b-2">
      <label
        className="contents cursor-pointer"
        htmlFor="EditModal"
        onClick={() => handleUpdate(toUpdate)}
      >
        <td>
          <input
            onClick={(e) => e.stopPropagation()}
            type="checkbox"
            checked={Checklist.includes(id)}
            onChange={() => {
              // if (!Checklist.includes(id)) {
              setChecklist((e) => {
                if (!e.includes(id)) return [...e, id];
                else {
                  let itemIndex = e.indexOf(id);
                  let newArr = [...e];
                  newArr.splice(itemIndex, 1);
                  return newArr;
                }
              });
            }}
          />
        </td>
        <td>{id}</td>
        <td>
          {name[Lang] || none} {Lang !== "h" && "(" + name["h"] + ")"}
        </td>
        <td
          onMouseEnter={() => shortDesc.includes("...") && setDescState(true)}
          onMouseLeave={() => setDescState(false)}
        >
          {shortDesc || none}
          {descState && <div className="hoverInTd">{desc[Lang]}</div>}
        </td>

        <td style={{ color: img ? "green" : "red", fontSize: "20px" }}>
          {img ? <MdImage /> : <MdImageNotSupported />}
        </td>
        <td>
          {/* <div className="tooltip" data-tip={JSON.stringify(categories)}> */}
          {categoriesToNames() || nothing}
          {/* </div> */}
        </td>
        <td>{isActive ? yes : no}{(!isActive && changeBy) ? <small style={{ color: "rgb(153, 50, 204)" }}> {changeBy}</small> : null}</td>
        <td>{date_upd ? new Date(date_upd).toLocaleString("he-IL") : ""}</td>
        {/* <td className="hidden lg:flex px-0">
          <label
            onClick={() =>
              handleUpdate({ id, name: name[Lang], desc: desc[Lang] })
            }
            htmlFor="EditModal"
            className="mr-0 text-xl text-sky-500 cursor-pointer hover:bg-sky-600 rounded-full hover:text-white px-2 py-1 flex justify-center items-center"
          >
            <i className={"ri-pencil-line"}></i>
          </label>

          <i
            onClick={() => handleDelete(name[Lang])}
            className="ri-delete-bin-7-line text-xl text-sky-500 cursor-pointer hover:bg-sky-600 rounded-full hover:text-white px-2 py-1"
          ></i>
        </td> */}
      </label>
    </tr>
  );
};

export default Student;
