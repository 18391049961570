import React from "react";
import spinner from "../../assets/images/spinner.gif";
import spinner2 from "../../assets/images/spinner2.gif";

const Spinner = ({ small }) => {
  return small ? (
    <div className="flex justify-center items-center">
      <img className={`w-10`} src={spinner2} alt="" />
    </div>
  ) : (
    <div className="w-full h-screen flex justify-center items-center bg-white">
      <img className="w-28 h-28" src={spinner} alt="" />
    </div>
  );
};

export default Spinner;
