import React, { useState, useContext, useEffect, useRef } from "react";
import Spinner from "../Shared/Spinner";
import * as XLSX from "xlsx";
import { UserContext } from "../../global";
import { MapContainer, TileLayer, CircleMarker, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useNavigate } from "react-router-dom";
const Devices = () => {
  const navigate = useNavigate();
  const { rest, setRest, RestData, RestList, setRestList, DevicesList } =
    useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  const componentRef = useRef({});
  const { current: my } = componentRef;

  useEffect(() => {
    (async () => {
      try {
        const devices = await fetch("/socket/onlineDevices", {
          method: "GET",
        });
        my.devices = await devices.json();
        setIsLoading(false);
      } catch {}
      // console.log(await getClosestRest());
    })();
  }, []);
  return !isLoading ? (
    <div dir="ltr" style={{ whiteSpace: "pre-wrap", padding: "3vw" }}>
      {JSON.stringify(my.devices, null, "\t")}
    </div>
  ) : null;
};

export default Devices;
