import React, { useState, useEffect, createContext } from "react";
import Spinner from "./components/Shared/Spinner";
import axios from "axios";
import translateFile from "./components/utils/translateFile.json";
// Helper varibalbes
const clientlanguageStorage = window.localStorage.getItem("language") || "en";
// ************ Global Variables ************
export const auth = window.localStorage.getItem("password");
export const permission = window.localStorage.getItem("permission");
export const translatedWeb = translateFile;
export const versions = {
  22: "1.4",
  23: "1.5",
};
export const languages = {
  h: translatedWeb?.language?.["h"][clientlanguageStorage],
  e: translatedWeb?.language?.["e"][clientlanguageStorage],
  r: translatedWeb?.language?.["r"][clientlanguageStorage],
  f: translatedWeb?.language?.["f"][clientlanguageStorage],
  s: translatedWeb?.language?.["s"][clientlanguageStorage],
  a: translatedWeb?.language?.["a"][clientlanguageStorage],
  c: translatedWeb?.language?.["c"][clientlanguageStorage],
  j: translatedWeb?.language?.["j"][clientlanguageStorage],
  am: translatedWeb?.language?.["am"][clientlanguageStorage],
  ro: translatedWeb?.language?.["ro"][clientlanguageStorage],
  hu: translatedWeb?.language?.["hu"][clientlanguageStorage],
};

export const templateData = {
  menuItems: {
    name: translatedWeb?.templateData?.menuItems?.name[clientlanguageStorage],
    singular:
      translatedWeb?.templateData?.menuItems?.singular[clientlanguageStorage],
    action: { id: "menu_item_id", name: "Item" },
    buttons: { lang: true, display: true, add: true, refresh: true },
    titles: [
      translatedWeb?.templateData?.menuItems?.title?.id[clientlanguageStorage],
      translatedWeb?.templateData?.menuItems?.title?.dishName[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.menuItems?.title?.description[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.menuItems?.title?.price[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.menuItems?.title?.image[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.menuItems?.title?.categories[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.menuItems?.title?.status[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.menuItems?.title?.lastUpdate[
        clientlanguageStorage
      ],
    ],
  },
  categories: {
    name: translatedWeb?.templateData?.categories?.name[clientlanguageStorage],
    singular:
      translatedWeb?.templateData?.categories?.singular[clientlanguageStorage],
    action: { id: "category_id", name: "Cat" },
    buttons: { lang: true, display: true, add: true, refresh: true },
    titles: [
      translatedWeb?.templateData?.categories?.title?.id[clientlanguageStorage],
      translatedWeb?.templateData?.categories?.title?.categoryName[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.categories?.title?.description[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.categories?.title?.image[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.categories?.title?.fatherCategory[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.categories?.title?.status[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.categories?.title?.lastUpdate[
        clientlanguageStorage
      ],
    ],
  },
  devices: {
    name: translatedWeb?.templateData?.devices?.name[clientlanguageStorage],
    singular:
      translatedWeb?.templateData?.devices?.singular[clientlanguageStorage],
    action: { id: "device_serial", name: "Device" },
    buttons: { lang: false, display: true, add: false, refresh: true },
    titles: [
      translatedWeb?.templateData?.devices?.title?.sn[clientlanguageStorage],
      translatedWeb?.templateData?.devices?.title?.apk[clientlanguageStorage],
      translatedWeb?.templateData?.devices?.title?.gsf[clientlanguageStorage],
      translatedWeb?.templateData?.devices?.title?.comments[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.devices?.title?.lastUpdate[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.devices?.title?.actions[
        clientlanguageStorage
      ],
    ],
  },
  allDevices: {
    name: translatedWeb?.templateData?.allDevices?.name[clientlanguageStorage],
    singular:
      translatedWeb?.templateData?.allDevices?.singular[clientlanguageStorage],
    action: { id: "device_serial", name: "Device" },
    buttons: {
      lang: false,
      display: true,
      add: false,
      refresh: true,
      filter: true,
    },
    titles: [
      translatedWeb?.templateData?.allDevices?.title?.sn[clientlanguageStorage],
      translatedWeb?.templateData?.allDevices?.title?.rest[
        clientlanguageStorage
      ],
      // "battery",
      translatedWeb?.templateData?.allDevices?.title?.apk[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.allDevices?.title?.gsf[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.allDevices?.title?.comments[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.allDevices?.title?.lastUpdate[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.allDevices?.title?.actions[
        clientlanguageStorage
      ],
    ],
  },
  restaurants: {
    name: translatedWeb?.templateData?.restaurants?.name[clientlanguageStorage],
    singular:
      translatedWeb?.templateData?.restaurants?.singular[clientlanguageStorage],
    action: { id: "rest_id", name: "Rest" },
    buttons: {
      lang: false,
      display: true,
      add: true,
      refresh: false,
    },
    titles: [
      translatedWeb?.templateData?.restaurants?.title?.id[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.restaurants?.title?.restaurantName[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.restaurants?.title?.pairDevices[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.restaurants?.title?.lastUpdate[
        clientlanguageStorage
      ],
      translatedWeb?.templateData?.restaurants?.title?.actions[
        clientlanguageStorage
      ],
      // "שדרוג לתפריט החדש",
    ],
  },
  users: {
    name: translatedWeb?.templateData?.users?.name[clientlanguageStorage],
    singular:
      translatedWeb?.templateData?.users?.singular[clientlanguageStorage],

    buttons: { lang: true, display: true, add: true, refresh: true },
    action: {
      // id: "rest_id",
      name: "User",
    },

    titles: [
      translatedWeb?.templateData?.users?.restaurantName[clientlanguageStorage],
      // "ID",
      translatedWeb?.templateData?.users?.password[clientlanguageStorage],
      translatedWeb?.templateData?.users?.accessTo[clientlanguageStorage],
      translatedWeb?.templateData?.users?.action[clientlanguageStorage],
    ],
  },
};

export const timeZonesWithUTC = [
  {
    value: "Etc/GMT+12",
    label: translatedWeb?.editModal?.UTC_minus_12[clientlanguageStorage],
  },
  {
    value: "Etc/GMT+11",
    label: translatedWeb?.editModal?.UTC_minus_11[clientlanguageStorage],
  },
  {
    value: "Pacific/Honolulu",
    label: translatedWeb?.editModal?.UTC_minus_10[clientlanguageStorage],
  },
  {
    value: "America/Anchorage",
    label: translatedWeb?.editModal?.UTC_minus_9[clientlanguageStorage],
  },
  {
    value: "America/Los_Angeles",
    label: translatedWeb?.editModal?.UTC_minus_8[clientlanguageStorage],
  },
  {
    value: "America/Phoenix",
    label: translatedWeb?.editModal?.UTC_minus_7_Arizona[clientlanguageStorage],
  },
  {
    value: "America/Denver",
    label:
      translatedWeb?.editModal?.UTC_minus_7_Mountain[clientlanguageStorage],
  },
  {
    value: "America/Chicago",
    label: translatedWeb?.editModal?.UTC_minus_6_Central[clientlanguageStorage],
  },
  {
    value: "America/New_York",
    label: translatedWeb?.editModal?.UTC_minus_5_Eastern[clientlanguageStorage],
  },
  {
    value: "America/Halifax",
    label:
      translatedWeb?.editModal?.UTC_minus_4_Atlantic[clientlanguageStorage],
  },
  {
    value: "America/St_Johns",
    label:
      translatedWeb?.editModal?.UTC_minus_3_30_Newfoundland[
        clientlanguageStorage
      ],
  },
  {
    value: "America/Sao_Paulo",
    label:
      translatedWeb?.editModal?.UTC_minus_3_Brasilia[clientlanguageStorage],
  },
  {
    value: "Etc/GMT+2",
    label:
      translatedWeb?.editModal?.UTC_minus_2_Mid_Atlantic[clientlanguageStorage],
  },
  {
    value: "Atlantic/Azores",
    label: translatedWeb?.editModal?.UTC_minus_1_Azores[clientlanguageStorage],
  },
  {
    value: "Europe/London",
    label: translatedWeb?.editModal?.UTC_0_Greenwich[clientlanguageStorage],
  },
  {
    value: "Europe/Berlin",
    label: translatedWeb?.editModal?.UTC_plus_1_Berlin[clientlanguageStorage],
  },
  {
    value: "Europe/Bucharest",
    label:
      translatedWeb?.editModal?.UTC_plus_2_Bucharest[clientlanguageStorage],
  },
  {
    value: "Asia/Jerusalem",
    label:
      translatedWeb?.editModal?.UTC_plus_2_Jerusalem[clientlanguageStorage],
  },
  {
    value: "Europe/Moscow",
    label: translatedWeb?.editModal?.UTC_plus_3_Moscow[clientlanguageStorage],
  },
  {
    value: "Asia/Tehran",
    label:
      translatedWeb?.editModal?.UTC_plus_3_30_Tehran[clientlanguageStorage],
  },
  {
    value: "Asia/Dubai",
    label: translatedWeb?.editModal?.UTC_plus_4_Dubai[clientlanguageStorage],
  },
  {
    value: "Asia/Kabul",
    label: translatedWeb?.editModal?.UTC_plus_4_30_Kabul[clientlanguageStorage],
  },
  {
    value: "Asia/Karachi",
    label: translatedWeb?.editModal?.UTC_plus_5_Karachi[clientlanguageStorage],
  },
  {
    value: "Asia/Kolkata",
    label:
      translatedWeb?.editModal?.UTC_plus_5_30_Kolkata[clientlanguageStorage],
  },
  {
    value: "Asia/Kathmandu",
    label:
      translatedWeb?.editModal?.UTC_plus_5_45_Kathmandu[clientlanguageStorage],
  },
  {
    value: "Asia/Dhaka",
    label: translatedWeb?.editModal?.UTC_plus_6_Dhaka[clientlanguageStorage],
  },
  {
    value: "Asia/Yangon",
    label:
      translatedWeb?.editModal?.UTC_plus_6_30_Yangon[clientlanguageStorage],
  },
  {
    value: "Asia/Bangkok",
    label: translatedWeb?.editModal?.UTC_plus_7_Bangkok[clientlanguageStorage],
  },
  {
    value: "Asia/Shanghai",
    label: translatedWeb?.editModal?.UTC_plus_8_Shanghai[clientlanguageStorage],
  },
  {
    value: "Asia/Tokyo",
    label: translatedWeb?.editModal?.UTC_plus_9_Tokyo[clientlanguageStorage],
  },
  {
    value: "Australia/Adelaide",
    label:
      translatedWeb?.editModal?.UTC_plus_9_30_Adelaide[clientlanguageStorage],
  },
  {
    value: "Australia/Sydney",
    label: translatedWeb?.editModal?.UTC_plus_10_Sydney[clientlanguageStorage],
  },
  {
    value: "Pacific/Noumea",
    label: translatedWeb?.editModal?.UTC_plus_11_Noumea[clientlanguageStorage],
  },
  {
    value: "Pacific/Fiji",
    label: translatedWeb?.editModal?.UTC_plus_12_Fiji[clientlanguageStorage],
  },
  {
    value: "Pacific/Tongatapu",
    label:
      translatedWeb?.editModal?.UTC_plus_13_Tongatapu[clientlanguageStorage],
  },
];

export const UserContext = createContext();
const GlobalContext = ({ subPages }) => {
  // const [rest, setRest] = useState(3);
  const [rest, setRest] = useState(
    window.localStorage.getItem("defaultRest") || 3
  );
  const [clientlanguage, setClientlanguage] = useState(
    window.localStorage.getItem("language") || "en"
  );
  const [branch, setBranch] = useState(0);
  const [RestData, setRestData] = useState({});
  const [RestList, setRestList] = useState([]);
  const [DevicesList, setDevicesList] = useState([]);
  const [UsersList, setUsersList] = useState([]);
  // const [DevicesList, setDevicesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isRtl = clientlanguage === "he" || clientlanguage === "ar";
  const getRestData = async (setLoading, type) => {
    console.log("getRestData()");
    setLoading(true);
    let finalRes = {};
    if (
      !type ||
      type === "menuItems" ||
      type === "categories" ||
      type === "settings" ||
      type === "devices"
    ) {
      await axios
        .get("/api/rest/" + rest + (type ? "/" + type : ""))
        .then(async (res) => {
          finalRes = res.data;
        });
    }
    // if (!type || type === "devices") {
    //   await axios.get("/api/tabletsListMenu/" + rest).then(async (res) => {
    //     finalRes.devices = res.data;
    //   });
    //   await axios.get("/devices.json").then((res) => {
    //     const devices5 = res.data.filter((e) => e.rest_id == rest);
    //     finalRes.devices = finalRes.devices.concat(devices5);
    //   });
    // }

    if (!type || type === "restaurants") {
      await axios.get("/api/restaurantsListMenu/" + permission).then((res) => {
        setRestList(res.data.reverse());
      });
    }
    if (!type || type === "allDevices") {
      await axios.get("/api/devices").then((res) => {
        console.log({ res });
        setDevicesList(res.data.reverse());
      });
    }
    if ((!type || type === "users") && permission === "all") {
      await axios
        .put(`/api/users`, {
          auth,
        })
        .then((res) => {
          const usersNew = res.data?.map((item) => {
            let found = RestList.find((rest) => {
              return typeof rest.id === "number" && rest.id == item.rest;
            });
            if (item?.rest?.startsWith("group ") && !found) {
              const numberStr = item?.rest?.replace("group", "").trim();
              const number = parseInt(numberStr, 10);
              const restname = RestData?.groups?.find(
                (rest) => rest?.id === number
              );
              found = ` Group ${restname?.name} ID: ${number}`;
            } else if (item?.rest == "all" && !found) {
              found = `Uppsale Admin User`;
            }
            return found ? { ...item, restName: found.name || found } : item;
          });
          setUsersList(res.data.reverse());
        });
    }

    if (!type) {
      setRestData(finalRes);
    } else {
      // const returnedTarget = Object.assign(RestData, finalRes);
      const newRestData = Object.assign({}, RestData);
      const returnedTarget = Object.assign(newRestData, finalRes);
      // console.log({ returnedTarget });
      setRestData(returnedTarget);
    }
    setLoading(false);
  };

  useEffect(() => {
    getRestData(setIsLoading);
    window.localStorage.setItem("defaultRest", rest);
  }, [rest]);

  const toContext = {
    permission,
    RestData,
    getRestData,
    setRestData,
    rest,
    setRest,
    branch,
    setBranch,
    RestList,
    setRestList,
    // DevicesList,
    isLoading,
    DevicesList,
    setDevicesList,
    clientlanguage,
    setClientlanguage,
    isRtl,
    setUsersList,
    UsersList,
  };
  return isLoading ? (
    <Spinner />
  ) : (
    <UserContext.Provider value={toContext}>{subPages}</UserContext.Provider>
  );
};
export default GlobalContext;
