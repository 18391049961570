import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { UserContext, languages, translatedWeb } from "../../global";
import axios from "axios";
import Spinner from "../Shared/Spinner";
import { useCallback } from "react";
import { RgbaStringColorPicker } from "react-colorful";
import { createGlobalStyle } from "styled-components";
import EditArray from "../Shared/EditArray";
import TimePicker from "./TimePicker";
import Swal from 'sweetalert2';



function Preferences() {
  const { rest, RestData, clientlanguage, isRtl } = useContext(UserContext);
  const timer = RestData?.details?.businessHours;
  const [time, setTime] = useState(RestData?.details?.businessHours?.startTime || { hours: 9, minutes: 0 });
  const [endTime, setEndTime] = useState(RestData?.details?.businessHours?.endTime || { hours: 17, minutes: 0 });
  const [imageArray, setImageArray] = useState([{
    id: 2,
    videoUrl: "path/to/video2.mp4",
    description: "Video 2 Description",
  }]);
  const [popupLengthError, setPopupLengthError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [CategoriesOrderOptions, setCategoriesOrderOptions] = useState([]);




  const refresh = () => {
    window.location.reload();
  };



  const GlobalStyle = createGlobalStyle`
 .react-colorful{
  padding: 1.25rem;
  width:20rem !important;
    height: 15rem !important;
    
 }
  `;
  const handleTabitReq = async () => {
    console.log("handleTabitReq");
    setIsLoading(true);
    const auth = window.localStorage.getItem("password");
    // console.log({ rest, id, auth });
    await axios.post(`/api/tabit/new/${rest}`, {});
    console.log("handleTabitReq done");
    setIsLoading(false);
    console.log("handleTabitReq done2");
  }
  const translateRestItems = async ({ langCode, langVal }) => {
    const auth = window.localStorage.getItem("password");
    setIsLoading(true);
    const { id } = RestData.details;
    console.log({ id });
    const result = await Swal.fire({
      title: 'האם אתה בטוח?',
      text: "האם ברצונך לבצע את התרגום?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'כן, תרגם!',
      cancelButtonText: 'לא, בטל'
    });
    if (result.isConfirmed) {
      const response = await axios.post(`/api/translate/${id}`, {
        targetLanguage: langVal,
        newLangCode: langCode,
        auth
      });
      console.log({ response });
      Swal.fire(
        'תרגום בוצע!',
        'התרגום שלך בוצע בהצלחה.',
        'success'
      );

      console.log({ response });
    }
    setIsLoading(false);

  }
  const colors = ["blue", "green", "red", "yellow", "purple", "pink", "orange"];
  const categoriesToNames = (id) => {
    let string = "";
    // console.log({ RestData });
    RestData.categories.every((category) => {
      if (category.catOrder?.includes(id)) {
        string += category.name?.h;
        return false;
      }
      return true;
    });
    return string;
  };
  useEffect(() => {
    let CatAlertItem = [], ItemData = {};

    // CatAlertItem = RestData?.details?.template?.components?.categories?.alertCat;
    // setShowAlertAtCat(CatAlertItem != undefined ? CatAlertItem : []);
    let categoriesOrderOptions = [];
    RestData.categories?.map((item) =>
      categoriesOrderOptions.push({
        value: item.id,
        label:
          item.name.h + " (" + (categoriesToNames(item.id) || "ראשי") + ")",
      })
    );
    setCategoriesOrderOptions(categoriesOrderOptions);
  }, []);
  const hasBusinessDescription = (restData) => {
    const AllCat = restData.categories;
    const homeCategories = restData?.details?.template?.homeCategories;
    return AllCat.some(cat =>
      homeCategories.includes(cat.id) &&
      cat.name.h.startsWith('עסקיות')
    );
  }
  if (isLoading) return <Spinner />
  const Toggle = ({
    data: { options, i, name, show, title, disableUrl, type, desc },
  }) => {
    let bool = options?.show;
    const [BoolState, setBoolState] = useState(Boolean(bool));
    const [link, setlink] = useState(options?.link);
    const [UserColor, setUserColor] = useState(type !== "color" ? null : RestData?.details?.template?.mobile?.components[name] || "");
    const [valueEnglish, setValueEnglish] = useState(options?.title?.e);
    const [valueHebrew, setValueHebrew] = useState(options?.title?.h);
    if (type == "color")
      return <><tr className={`border-b-2 border-r-2 border-r-blue-500 `}>
        <td>
          <div>{title}</div>
          <small>{name}</small>
        </td>
        <td>
          {desc}
        </td>
        <td>
          <input class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-50 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mx-5" defaultValue={UserColor} onChange={(e) => setUserColor(e.target.value)} />
        </td >
        <td className="aspect-w-1 aspect-h-1 bg-gray-300"        >
          <div
            style={{ backgroundColor: UserColor, width: "2vw", height: "2vw" }}          ></div>
        </td >


        <td>
          <button onClick={() => updateMobile(name, null, null, null, null, UserColor, type)} type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" >Update</button>
        </td>
      </tr ></>
    return (
      <>
        <tr className={`border-b-2 border-r-2 border-r-blue-500 `}>
          <td>
            <div>{title}</div>
            <small>{show && name}</small>
          </td>
          <td>
            <div style={{ margin: "0px 0vw" }}>{show ?
              (<><span>Hebrew: <input type="text" id="url" class="w-100 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                defaultValue={valueHebrew}
                onChange={(e) => setValueHebrew(e.target.value)}
                placeholder="Text for Hebrew show" /></span>
                <span>English: <input type="text" id="url" class="w-100 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  defaultValue={valueEnglish}
                  onChange={(e) => setValueEnglish(e.target.value)}
                  placeholder="Text for showing in English" /></span></>) : title == "ברכת המזון" ? "Show Image- Without Translation" : "Shown by the languages on the menu"}
            </div>
          </td>
          <td>
            <input
              type='checkbox'
              className={"toggle " + (BoolState ? "bg-green-500" : "bg-red-500 ")}
              defaultChecked={BoolState}
              onChange={() => setBoolState((e) => !e)}
            />
          </td >
          <td>
            {show && !disableUrl ? (<input type="text" id="url" class="w-100 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              defaultValue={link}
              onChange={(e) => setlink(e.target.value)}
              placeholder="Enter Web Url" />) : "Without a link"}
          </td>
          <td style={{ color: RestData?.details?.template?.mobile?.components[name]?.img ? "unset" : "red", fontSize: 20 }}>
            {RestData?.details?.template?.mobile?.components[name]?.img ? (<img alt={name} style={{
              height: "3vw", margin: 'auto',
            }} src={`/api/thumbnail?url=RestImages/${rest}/mobile/${[name]}.png`}></img>) : (<i class="fad fa-images"></i>)}
          </td>
          <td>{((RestData?.details?.template?.mobile?.components[name]?.show && RestData?.details?.template?.mobile?.components[name]?.link && show) || (!show && RestData?.details?.template?.mobile?.components[name]?.show)) ? (<><i class="fal fa-smile" style={{ color: "green", fontSize: 20 }}></i></>) : (<><i class="fal fa-frown-open" style={{ color: "red", fontSize: 20 }}></i></>)}</td>
          <td>
            <button onClick={() => updateMobile(name, { h: valueHebrew, e: valueEnglish }, BoolState, link, null)} type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" >Update</button>
          </td>
        </tr>
      </>);
  };
  const Switch = ({
    data: { title, color, apiName, icon, trueByDefault, options },
  }) => {
    const [IsLoading, setIsLoading] = useState(false);
    let bool = options;
    if (bool !== false && bool !== true && trueByDefault) {
      bool = true;
    }
    const [BoolState, setBoolState] = useState(Boolean(bool));
    const sendAction = useCallback(async () => {
      setIsLoading(true);
      const dataObj = {};
      dataObj[apiName] = !BoolState;
      console.log({ dataObj });
      try {
        await axios.put("/api/settings", {
          rest: parseInt(rest),
          data: dataObj,
        });
        setIsLoading(false);
        setBoolState((e) => !e);
        refresh();
      } catch (err) { }
    }, []);
    return (
      <div
        className={`cursor-pointer p-5 rounded-lg text-white bg-${color}-500`}>
        {IsLoading ? (
          <div className={"h-[100px] flex justify-center items-center"}>
            <Spinner small />
          </div>
        ) : (
          <div
            className='count flex justify-start mt-2'
            onClick={(e) => e.stopPropagation()}>
            <div className='students px-2'>
              <i className={`ml-2 text-5xl ` + icon}></i>
              <p className='my-1'>{title}</p>
            </div>
            <input
              type='checkbox'
              className={
                "toggle " + (BoolState ? "bg-green-500" : "bg-red-500 ")
              }
              defaultChecked={BoolState}
              onChange={() => sendAction()}
            />
            <span className='text-base font-bold mx-1'>
              {BoolState ? translatedWeb?.preferences?.on[clientlanguage] : translatedWeb?.preferences?.off[clientlanguage]}
            </span>
          </div>
        )}
      </div>
    );
  };

  const homeComponentsMobile = [
    {
      name: "BirkatHamazon",
      title: "Birkat Hamazon",
      icon: "fal fa-book-open", show: false

    },
    {
      name: "LangBtn",
      title: "Language button",
      icon: "ri-global-line",
      show: false
    },
    {
      name: "Btn",
      title: "WhatsApp",
      icon: "ri-window-line",
      show: true
    },
    {
      name: "Btn2",
      title: "TikTok",
      icon: "ri-window-line",
      show: true
    },
    {
      name: "Btn3",
      title: "Instagram",
      icon: "fal fa-stars",
      show: true
    }, {
      name: "Btn4",
      title: "FaceBook",
      icon: "ri-window-line",
      show: true
    },

    {
      name: "Btn5",
      title: "Events",
      icon: "fad fa-gift-card",
      show: true
    },
    {
      name: "Btn6",
      title: "Costumers club",
      icon: "fal fa-file-certificate",
      show: true
    },
    {
      name: "Btn7",
      title: "Gift Card",
      icon: "fal fa-file-certificate",
      show: true
    },
    {
      name: "Btn8",
      title: "Reviews",
      icon: "ri-window-line",
      show: true
    },
    {
      name: "Video",
      title: "Reels",
      icon: "fal fa-file-certificate",
      show: false
    },
    {
      name: "Banner",
      title: "Banner advertiser",
      icon: "fal fa-file-certificate", show: false
    },
    {
      name: "cat1",
      title: "category 1",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    },
    {
      name: "cat2",
      title: "category 2",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    },
    {
      name: "cat3",
      title: "category 3",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    },
    {
      name: "cat4",
      title: "category 4",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    },
    {
      name: "autoSelect",
      title: "Auto Select Category",
      icon: "fal fa-magic", show: false
    },
    {
      name: "autoSelectMeal",
      title: "Auto Select Meal",
      icon: "fal fa-magic", show: false
    }, {
      name: "reels",
      title: "Reels Video",
      icon: "fal fa-file-certificate", isTitle: true
    }, {
      name: "reels1",
      title: "reels video 1",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    }, {
      name: "reels2",
      title: "reels video 2",
      icon: "fal fa-file-certificate", show: true, disableUrl: true

    },
    {
      name: "reels3",
      title: "reels video 3",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    },
    {
      name: "reels4",
      title: "reels video 4",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    },
    {
      name: "reels5",
      title: "reels video 5",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    },
    {
      name: "reels6",
      title: "reels video 6",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    },
    {
      name: "reels7",
      title: "reels video 7",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    },
    {
      name: "reels8",
      title: "reels video 8",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    },
    {
      name: "reels9",
      title: "reels video 9",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    },
    {
      name: "reels10",
      title: "reels video 10",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    },
    {
      name: "reels11",
      title: "reels video 11",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    },
    {
      name: "reels12",
      title: "reels video 12",
      icon: "fal fa-file-certificate", show: true, disableUrl: true
    },


  ];
  const colorsMobile = [
    {
      name: "GoBackCategory",
      title: "Go Back Category",
      // desc: "The color of the back button in the category",
      desc: "צבע לכפתור חזור בקטגוריה",
    }, {
      name: "GoBackCategorySVG",
      title: "Go Back Category text color",
      // desc: "The color of the back button in the category",
      desc: "צבע SVG לכפתור חזור בקטגוריה",
    }, {
      name: "menuColor",
      title: "Menu BTN backgroung Color",
      desc: "The background color of the menu ",
    }, {
      name: "menuColorTXT",
      title: "Menu BTN text Color",
      desc: "The text color of the menu ",
    }, {
      name: "color",
      title: "Main Color background",
      desc: "The color of the home screen 8 buttons",

    }, {
      name: "colorTXT",
      title: "Main Color text",
      desc: "The text color of the home screen 8 buttons",
    }, {
      name: "mainColorCat",
      title: "רקע לכפתור קטגוריה ראשי",
      desc: "The color of the main categories screen",

    }, {
      name: "mainColorCatTXT",
      title: "כפתור קטגוריה ראשי צבע טקסט",
      desc: "The text color of the main categories screen",


    }, {
      name: "backgroundImageTextColor",
      title: "צבע רקע קוביה על תמונת רקע",
      desc: "The color of the background image text",

    }
  ];
  const updatePopup = async ({ type }) => {
    const dataObj = {};
    let apiName = "";
    switch (type) {
      case "business":
        apiName = "businessHours";
        try {
          dataObj[apiName] = { startTime: time, endTime: endTime };
          await axios.put("/api/settings", {
            rest: parseInt(rest),
            data: dataObj,
          });

        } catch (err) { }
        break;
      case "alert":
      case "popup":
        const count = document.getElementById("adsCount").value;
        apiName = "template.components.home.Popup.randomAds";
        const apiName1 = "template.components.home.Popup.show";
        if (count < 8 && count > 1) {
          try {
            dataObj[apiName] = parseInt(count);
            await axios.put("/api/settings", {
              rest: parseInt(rest),
              data: dataObj,
            });
            dataObj[apiName1] = true;

          } catch (err) { }
        } else {
          setPopupLengthError(true);
        } break;
      case "alert":
        apiName = "template.components.categories";
        dataObj[apiName] = { alertCatText: { h: document.getElementById("alertCatText")?.value, e: document.getElementById("alertCatTextEN")?.value } };
        // console.log({
        //   dataObj, ShowAlertAtCat, alertCatText: document.getElementById("alertCatText")?.value
        // });
        console.log("alert");
      default:
        break;
    }
    dataObj != null && await axios.put("/api/settings", {
      rest: parseInt(rest),
      data: dataObj,
    });
    refresh();


  };
  const updateMobile = async (name, translate, boolState, link, img, color, type) => {
    const apiName = "template.mobile.components." + [name];
    const dataObj = {};

    const Objperson = { link, show: boolState, title: translate };

    try {
      dataObj[apiName] = Objperson;
      if (name == "color") {
        dataObj[apiName] = color;
      }
      if (type == "color") {
        dataObj[apiName] = color;
      }

      console.log({ dataObj, Objperson, color });
      await axios.put("/api/settings", {
        rest: parseInt(rest),
        data: dataObj,
      });

      refresh();
    } catch (err) { }

  };
  const Carousel = () => {
    const { rest, isRtl } = useContext(UserContext);

    const [IsOptions, setIsOptions] = useState(0);
    const [popupLength, setpopupLength] = useState(
      RestData?.details?.template?.components?.home?.Popup?.randomAds !== false
        ? RestData?.details?.template?.components?.home?.Popup?.randomAds
        : 0
    );
    const [imageArray, setImageArray] = useState([]);

    useEffect(() => {
      const addToArry = () => {
        const arr = [];
        if (popupLength >= 2) {
          for (let index = 1; index <= popupLength; index++) {
            arr.push(`/api/img?url=RestImages/${rest}/ad${index}.png`);
          }
        } else {
          arr.push(`/api/img?url=RestImages/${rest}/ad.png`);
        }
        setImageArray(arr);
      };

      addToArry();
    }, []);
    return (
      <>

        <div>
          <h3
            className={`flex justify-center w-full p-2 gap-2 text-2xl font-extrabold dark:text-white ${RestData?.details?.template?.components?.home?.Popup?.show
              ? "text-black"
              : "text-red-500"
              }`}>
            {RestData?.details?.template?.components?.home?.Popup?.show
              ? imageArray.length == 1 &&
                RestData?.details?.template?.components?.home?.Popup
                  ?.randomAds == false
                ? translatedWeb?.preferences?.signalpopup[clientlanguage]
                : translatedWeb?.preferences?.multiPopup[clientlanguage]
              : translatedWeb?.preferences?.noPopup[clientlanguage]}
          </h3>
          {RestData?.details?.template?.components?.home?.Popup?.show ? (
            <div>
              <div className='carousel w-full p-5 flex justify-center'>
                <div
                  key={imageArray[IsOptions]}
                  className='carousel-item w-full'>
                  <img
                    src={imageArray[IsOptions]}
                    className='flex justify-center'
                    style={{ margin: "auto", height: "20vw", width: "30vw" }}
                    alt={
                      "Image " +
                      IsOptions +
                      " Not found" +
                      `  \n/ad${IsOptions}.png`
                    }
                    onError={({ currentTarget, target }) => {
                      target.src = "/api/img?url=Images/noPopup.png";
                    }}
                  />
                </div>
              </div>
              <h5
                className={`flex justify-center w-full p-2 gap-2 text-sm font-extrabold dark:text-white ${RestData?.details?.template?.components?.home?.Popup?.show
                  ? "text-black"
                  : "text-red-500"
                  }`}>
                {RestData?.details?.template?.components?.home?.Popup?.show &&
                  imageArray.length == 1 &&
                  RestData?.details?.template?.components?.home?.Popup
                    ?.randomAds == false
                  ? "ad.png"
                  : `ad${IsOptions + 1}.png`}
              </h5>
              <div className='flex justify-center w-full py-2 gap-2'>
                {Array.isArray(imageArray)
                  ? imageArray.map((item, index) => (
                    <div
                      onClick={() => setIsOptions(index)}
                      className='btn btn-xs'>
                      {index + 1}
                    </div>
                  ))
                  : null}
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  };
  return (
    <>
      <GlobalStyle />
      <div className='manage_student mt-3'>
        <div className='add_student flex justify-between'>
          <h2 className='text-2xl font-bold pr-5 mt-2 md:mt-3'>
            {translatedWeb?.preferences?.title[clientlanguage]}
          </h2>
        </div>
        <span>

          <div className='grid gap-6 mb-3 md:grid-cols-2 px-6'>
            <div>
              <h3 className='text-2xl font-bold px-5 mt-2 md:mt-3'> תרגום מסעדה</h3>
              <small className=" px-5 mt-2 md:mt-3">הפעולה תקח כמה דקות ועובדת רק על השפות שאין למסעדה,לא לשכוח   להפעיל את השפות </small>
              <div class="md:flex md:items-center mb-6">
                {Object.keys(languages).filter(lang => !RestData?.details?.langs.includes(lang)).map((lang) =>
                  // console.log({ lang, languages: translatedWeb?.language?.[lang]["en"] }
                  <label
                    onClick={() => { translateRestItems({ langCode: lang, langVal: translatedWeb?.language?.[lang]["en"] }) }}
                    className={`inline-block mr-2 mt-2 px-5 py-2 rounded-lg cursor-pointer bg-teal-100 text-gray-700 hover:bg-teal-500 hover:text-white`}>
                    {`${translatedWeb?.preferences?.sendTranslation?.[clientlanguage]} ${translatedWeb?.language?.[lang][clientlanguage]}`}
                  </label>

                )}
                {/* <div class="md:w-1/3">
                  <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                    {translatedWeb?.preferences?.setTimerForBusiness[clientlanguage]}
                  </label>
                </div>

                <div class="md:w-2/3">
                  חלחחדכלכ
                </div> */}
              </div>



            </div>
          </div>
          <div className='grid gap-6 mb-3 md:grid-cols-2 px-6'>
            <div>
              <h3 className='text-2xl font-bold pr-5 mt-2 md:mt-3'> {translatedWeb?.preferences?.setTimerForBusiness[clientlanguage]}</h3>
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3">
                  <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                    {translatedWeb?.preferences?.setTimerForBusiness[clientlanguage]}
                  </label>
                </div>

                <div class="md:w-2/3">
                  {hasBusinessDescription(RestData) ? (<>
                    <TimePicker title={translatedWeb?.preferences?.startTime} setTime={setTime} time={time} />
                    <TimePicker title={translatedWeb?.preferences?.endTime} setTime={setEndTime} time={endTime} startTime={time} /></>
                  ) : (<>
                    <div className='font-bold py-2 px-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 inline-block' role='alert'>
                      <div>אין עסקיות פעילות למסעדה זו, נא להוסיף</div>
                    </div>
                  </>)}
                </div>
              </div>


              {hasBusinessDescription(RestData) ? (
                <button
                  onClick={() => updatePopup({ type: "business" })}
                  type='submit'
                  className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                // style={{ height: "3vw", width: "10vw", marginTop: "1.5vw" }}
                >
                  {translatedWeb?.preferences?.save[clientlanguage]}
                </button>
              ) : null}
            </div>
          </div>



        </span >
        <h3 className='text-2xl font-bold pr-5 mt-2 md:mt-3'> {translatedWeb?.preferences?.showSmallAlert[clientlanguage]}</h3>
        <p>   <small>{translatedWeb?.preferences?.workAt[clientlanguage]}</small> </p>


        <span>
          <div className='grid gap-6 mb-3 md:grid-cols-2 px-6'>
            <div>
              {/* {ShowAlertAtCat.length == 0 && <div
                class='font-bold	 py-2 px-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400'
                role='alert'>
                <div> Please note: If there are no categories- the option not active for a menu</div>
              </div>} */}

              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3">
                  <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                    {/* טקסט התראה בקטגוריות: עברית */}
                    {translatedWeb?.preferences?.textAlertHe[clientlanguage]}
                  </label>
                </div>

                <div class="md:w-2/3">

                  <textarea
                    rows="4"
                    class="outline-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:border-gray-300"
                    id="alertCatText"
                    type="text"
                    name="alertCatText"
                    defaultValue={RestData?.details?.template?.components?.categories?.alertCatText?.h}
                    placeholder="מוצרינו עלולים להכיל, וחלקם אף מכילים, אלרגנים ממינים שונים וחלבון מן הצומח. המוצרים אינם מיוצרים בסביבה נטולת חומרים אלרגניים ולכן. האזהרה מתייחסת לכלל מוצרינו. לבירור על האלרגנים במנות השונות אנא פנו לצוות המסעדה"
                  // required
                  // onChange={e => SetIsPromotion(e.target.value)}
                  // value={e => SetIsPromotion(e.target.value)}
                  />
                </div>
              </div>
              <div class="md:flex md:items-center mb-6">
                <div class="md:w-1/3">
                  <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                    {/* טקסט התראה בקטגוריות: אנגלית */}
                    {translatedWeb?.preferences?.textAlertEn[clientlanguage]}
                  </label>
                </div>

                <div class="md:w-2/3">

                  <textarea
                    rows="4"
                    class="outline-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:border-gray-300"
                    id="alertCatTextEN"
                    type="text"
                    name="alertCatText"
                    defaultValue={RestData?.details?.template?.components?.categories?.alertCatText?.e}
                    placeholder="מוצרינו עלולים להכיל, וחלקם אף מכילים, אלרגנים ממינים שונים וחלבון מן הצומח. המוצרים אינם מיוצרים בסביבה נטולת חומרים אלרגניים ולכן. האזהרה מתייחסת לכלל מוצרינו. לבירור על האלרגנים במנות השונות אנא פנו לצוות המסעדה"
                  // required
                  // onChange={e => SetIsPromotion(e.target.value)}
                  // value={e => SetIsPromotion(e.target.value)}
                  />
                </div>
              </div>


              <button
                onClick={() => updatePopup({ type: "alert" })}
                type='submit'
                className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
              // style={{ height: "3vw", width: "10vw", marginTop: "1.5vw" }}
              >
                {translatedWeb?.preferences?.updateAlert[clientlanguage]}
              </button></div>
          </div>



        </span >
        {RestData?.menuItems.length == 0 && RestData?.categories.length == 0 && <div className="md:flex md:items-center mb-6">
          <div className="md:w-1/3">
            <label className={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
              משיכת תפריט חדש מ TABIT:
            </label>
          </div>
          {/* <div className="md:w-2/3"> */}
          <div onClick={handleTabitReq} class="mx-2 px-12 py-2 bg-sky-500 hover:bg-sky-600 rounded-lg text-white">משיכת תפריט:</div>
          {/* </div> */}
        </div>
        }
        <h3 className='text-2xl font-bold pr-5 mt-2 md:mt-3'> {translatedWeb?.preferences?.popupManagement[clientlanguage]}</h3>
        <span>
          <div className='grid gap-6 mb-3 md:grid-cols-3 px-6'>
            <div>
              <label
                htmlFor='adsCount'
                className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                {translatedWeb?.preferences?.amount[clientlanguage]}
              </label>
              <input
                type='number'
                id='adsCount'
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                placeholder='5 Popups'
                required
                min='2'
                max='7'
              />
              {popupLengthError ? (
                <div
                  class='py-2 px-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                  role='alert'>
                  <div> Please enter the number of popups between 2 and 7</div>
                  <div> To one popup - please turn off multi popup</div>
                </div>
              ) : null}
            </div>
            <button
              onClick={() => updatePopup({ type: "popup" })}
              type='submit'
              className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
            // style={{ height: "3vw", width: "10vw", marginTop: "1.5vw" }}
            >
              {translatedWeb?.preferences?.update[clientlanguage]}
            </button>
          </div>

          <div className='mt-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 px-6'>
            <Switch
              data={{
                options:
                  RestData?.details?.template?.components?.home?.Popup?.show,
                trueByDefault: false,
                icon: "fal fa-ad",
                title: translatedWeb?.preferences?.popup[clientlanguage],
                apiName: "template.components.home.Popup.show",
                color: "blue",
              }}
            />
            <Switch
              data={{
                options:
                  RestData?.details?.template?.components?.home?.Popup
                    ?.randomAds,
                trueByDefault: false,
                icon: "fas fa-photo-video",
                title: translatedWeb?.preferences?.multiPopup[clientlanguage],
                apiName: "template.components.home.Popup.randomAds",
                color: "pink",
              }}
            />
            <Switch
              data={{
                options:
                  RestData?.details?.template?.components?.home?.Popup?.isSmall,
                trueByDefault: false,
                icon: "fas fa-photo-video",
                title: translatedWeb?.preferences?.smallPopup[clientlanguage],
                apiName: "template.components.home.Popup.isSmall",
                color: "yellow",
              }}
            />
          </div>


        </span>
      </div >




      <Carousel />
      {/* <div className='manage_student mt-3'>

        <h3 className='text-2xl font-bold pr-5 mt-2 md:mt-3'>ניהול פופאפ</h3>
        <span>


          <div className='mt-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 px-6'>
            <Switch
              data={{
                options:
                  RestData?.details?.template?.components?.home?.Popup?.show,
                trueByDefault: false,
                icon: "fal fa-ad",
                title: "Popup",
                apiName: "template.components.home.Popup.show",
                color: "blue",
              }}
            />
            <Switch
              data={{
                options:
                  RestData?.details?.template?.components?.home?.Popup
                    ?.randomAds,
                trueByDefault: false,
                icon: "fas fa-photo-video",
                title: "Multi Popup",
                apiName: "template.components.home.Popup.randomAds",
                color: "pink",
              }}
            />
            <Switch
              data={{
                options:
                  RestData?.details?.template?.components?.home?.Popup?.isSmall,
                trueByDefault: false,
                icon: "fas fa-photo-video",
                title: "Small Popup",
                apiName: "template.components.home.Popup.isSmall",
                color: "yellow",
              }}
            />
          </div>

        </span>
      </div> */}
      <h3 className='text-2xl font-bold pr-5 mt-2 pb-2 md:mt-3 '> {translatedWeb?.preferences?.mobileManagement[clientlanguage]}</h3>        <div className="text-sm  pr-5 mt-1 pb-6 ">
        {translatedWeb?.preferences?.mobileInfo[clientlanguage]}
      </div>

      <div className='mt-1  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 px-6'>
        <Switch
          data={{
            options:
              RestData?.details?.template?.mobile?.components?.showVideo,
            trueByDefault: false,
            icon: "fab fa-youtube",
            title: translatedWeb?.preferences?.showVideo[clientlanguage],
            apiName: "template.mobile.components.showVideo",
            color: "green",
          }}
        />
        <Switch
          data={{
            options:
              RestData?.details?.template?.mobile?.components?.backgroundImage,
            trueByDefault: false,
            icon: "fab fa-youtube",
            title: translatedWeb?.preferences?.backgroundImage[clientlanguage],
            apiName: "template.mobile.components.backgroundImage",
            color: "orange",
          }}
        />

      </div>
      <span>
        <div className="py-2">
          <h3 className='text-2xl font-bold pr-5 mt-2 md:mt-3'> {translatedWeb?.preferences?.mainColor[clientlanguage]}</h3>

          <table
            className={`table  text-sm ${isRtl ? "text-left" : "text-right"} text-gray-500 dark:text-gray-400`}
            style={{
              tableLayout: "auto",
              borderCollapse: "collapse",
              width: "min-content",
            }}
          >
            <thead className="opacity-70">
              <tr>

                <th scope="col" class="rounded-none px-6 py-4"> שם צבע </th>
                <th scope="col" class="rounded-none px-6 py-4"> תאור </th>
                <th scope="col" class="rounded-none px-6 py-4"> קוד צבע </th>
                <th scope="col" class="rounded-none px-6 py-4"> צבע</th>
                <th scope="col" class="rounded-none px-6 py-4" />

              </tr>
            </thead>
            <tbody>
              {colorsMobile.map(
                ({ name, title, desc }, i) => {
                  // const options = template?.mobile?.components?.[name];
                  // const apiName = "template.mobile.components." + name;
                  return (
                    <Toggle data={{ name, title, type: "color", desc }} />
                  );
                }
              )}
            </tbody>
          </table>

        </div>
        <div className="pt-5">
          <h3 className='text-2xl font-bold pr-5 mt-2 md:mt-3'> {translatedWeb?.preferences?.manageCom[clientlanguage]}</h3>

          <table
            className={`table  text-sm ${isRtl ? "text-left" : "text-right"} text-gray-500 dark:text-gray-400`}
            style={{
              tableLayout: "auto",
              borderCollapse: "collapse",
              width: "min-content",
            }}
          >
            <thead className="opacity-70">
              <tr>

                <th scope="col" class="rounded-none px-6 py-4"> {translatedWeb?.preferences?.pictureName[clientlanguage]} </th>
                <th scope="col" class="rounded-none px-20 py-4"> {translatedWeb?.preferences?.translation[clientlanguage]} </th>
                <th scope="col" class="rounded-none px-6 py-4"> {translatedWeb?.preferences?.enableItem[clientlanguage]} </th>
                <th scope="col" class="rounded-none px-12 mx-5 py-4"> {translatedWeb?.preferences?.link[clientlanguage]} </th>
                <th scope="col" class="rounded-none px-6 py-4"> {translatedWeb?.preferences?.customizeIcon[clientlanguage]} </th>
                <th scope="col" class="rounded-none px-6 py-4">{translatedWeb?.preferences?.willDispaly[clientlanguage]}  </th>
                <th className="rounded-none" >
                </th>
              </tr>
            </thead>
            <tbody>
              {homeComponentsMobile.map(
                ({ name, title, color, icon, show, disableUrl, isTitle }, i) => {
                  // const options = template?.mobile?.components?.[name];
                  // const apiName = "template.mobile.components." + name;
                  if (isTitle) {
                    return (
                      <td colspan="12" className="text-black	text-center py-2 text-3xl md:text-4xl font-bold mb-2">
                        {title}
                      </td>
                    );
                  }
                  return (
                    <Toggle data={{ options: RestData?.details?.template?.mobile?.components[name], i, name, show, title, disableUrl, type: "buttonMobile" }} />
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      </span>
    </>
  );
}

export default Preferences;
