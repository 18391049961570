import React, { useState, useRef } from 'react';
import { useContext } from 'react';
import { UserContext, languages } from '../../global';
import axios from 'axios';
import { useEffect } from 'react';
import Spinner from '../Shared/Spinner';
import Swal from 'sweetalert2';

function TagRow({ tag, onDelete, onValueChange }) {

    return (
        <tr>
            <td>{tag.id}</td>
            {Object.entries(tag).filter(([key]) => key !== 'id').map(([language, value]) => (
                <td key={language}>
                    <input
                        type="text"
                        value={value}
                        onChange={(e) => onValueChange(tag.id, language, e.target.value)}
                        className="border-2 border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    />
                </td>
            ))}
            <td>
                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline" onClick={() => onDelete(tag.id)}>מחק</button>
            </td>
        </tr>
    );
}

function TagsManager() {
    const { RestData, rest } = useContext(UserContext);
    const [tags, setTags] = useState(RestData?.details?.tags || []);
    const [IsLoading, setIsLoading] = useState(false);

    // const [availableLanguages, setAvailableLanguages] = useState(RestData?.details?.langs);
    const availableLanguages = RestData?.details?.langs || [];
    const nextId = useRef(1);

    useEffect(() => {
        const highestId = tags.reduce((max, tag) => Math.max(max, tag.id), 0);
        nextId.current = highestId + 1;
    }, [tags]);
    const addTag = () => {
        const newTag = { id: nextId.current++ };
        availableLanguages.forEach(language => newTag[language] = '');
        setTags([...tags, newTag]);
    };

    const deleteTag = (id) => {
        setTags(tags.filter(tag => tag.id !== id));
    };

    const handleValueChange = (id, language, value) => {
        const updatedTags = tags.map(tag => {
            if (tag.id === id) {
                return { ...tag, [language]: value };
            }
            return tag;
        });
        setTags(updatedTags);
    };
    const saveTags = async () => {
        // return console.log('Saving tags:', { tags, id: RestData.details.id, rest });

        setIsLoading(true);

        const response = await axios.put(`/api/updateTag`, {
            tags, rest
        });
        setIsLoading(false);
        if (response.status === 200) {
            Swal.fire({
                title: "הצליח",
                text: `הפעולה בוצעה בהצלחה \n עודכנו ${response.data.updateResult} תגיות`,
                icon: "success",
                confirmButtonColor: "#3085d6",
                showClass: { popup: `animate__animated animate__fadeInUp animate__faster` },
                hideClass: { popup: `animate__animated animate__fadeOutDown animate__faster` }
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });
        } else {
            Swal.fire({
                title: "נכשל",
                text: "הפעולה נכשלה, אנא נסה שנית",
                icon: "error",
                confirmButtonColor: "#d33",
                showClass: {
                    popup: `
          animate__animated
          animate__fadeInDown
          animate__faster
        `
                },
                hideClass: {
                    popup: `
          animate__animated
          animate__fadeOutUp
          animate__faster
        `
                }
            });
        }
    };
    return (
        <>
            {
                IsLoading ?
                    <div className={"h-[100px] flex justify-center items-center"} >
                        <Spinner small />
                    </div>
                    :
                    <div className="w-full mx-auto" style={{ width: '80%' }}>
                        <table className="table-fixed w-full mt-4 rounded-s-lg">
                            <thead className='bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                                <tr>
                                    <th style={{ width: '5%' }}>ID</th>
                                    {availableLanguages.map(language => (
                                        <th key={language} style={{ padding: '0 20px' }}>{languages[language]}</th>
                                    ))}
                                    <th style={{ width: '10%' }}>פעולות</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tags.map(tag => (
                                    <TagRow key={tag.id} tag={tag} onDelete={deleteTag} onValueChange={handleValueChange} />
                                ))}
                            </tbody>
                        </table>
                        <div className='p-4'>
                            <button className="mx-2 px-12 py-2 bg-sky-500 hover:bg-sky-600 rounded-lg text-white" onClick={saveTags}>שמור תגיות</button>
                            <button className="mx-2 px-12 py-2 bg-teal-500 hover:bg-teal-600 rounded-lg text-white" onClick={addTag}>הוסף תגית</button>

                        </div>
                        {/* <button className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline  m-4" onClick={() => addLanguage(prompt("הזן שם שפה חדשה:"))}>הוסף שפה</button> */}


                        {/* {RestData?.details?.langs.map((lang) => (
                <button key={lang} className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline m-4" onClick={() => addLanguage(lang)}>הוסף שפה {lang}</button>
            ))} */}
                    </div>}
        </>

    );
}

export default TagsManager;