import React, { useState, useContext } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { auth, languages, translatedWeb, UserContext, templateData } from "../../global";
// import { PiBroomBold } from "react-icons/pi";
// import UpdatePortrait from "../Shared/UpdatePortrait";
// import {  ,  } from "../../global";
// import Swal from 'sweetalert2'
// import Spinner from "../Shared/Spinner";
import { useRef } from "react";
// import { useContext } from "react";
import Select from 'react-select';
const User = ({
    itemData,
    handleDelete,
    handleUpdate,
    update,
    setUpdate,
    Lang,
    options
}) => {
    const { rest: restPass, id, name } = itemData;
    const { rest, RestData, setRestData, DevicesList, clientlanguage, RestList } = useContext(UserContext);
    const [IsDeleted, setIsDeleted] = useState(false);
    const [UserData, setUserData] = useState(false);
    const [Edit, setEdit] = useState(false);
    const none = <span style={{ color: "red" }}>X</span>;
    // const devicesCount = DevicesList.filter(
    //     (device) => device.rest == id && !device.isDev
    // ).length;
    const deleteUser = async (id) => {
        const res = await axios.put(`/api/users`, { auth, type: "delete", id });
        if (res.data && res.data.length > 0 && res.status === 200) Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        // setUsers(res.data);
    };
    function getRestNameById(id) {
        let found = RestList.find(rest => typeof rest.id === 'number' && rest.id == id);
        if (typeof id === 'string' && id.startsWith('group ') && !found) {
            const numberStr = id.replace("group", "").trim();
            const number = parseInt(numberStr, 10);
            const restname = RestData?.groups?.find(rest => rest?.id === number);
            found = `Group ${restname?.name} ID: ${number}`;
        } else if (id == "all" && !found) {
            found = `Uppsale Admin User`;
        }
        return found ? found.name || found : null;
    }
    // console.log({ itemData, rrrr: "r" });
    return (
        <>
            {/* {Edit && <EditModal setEdit={setEdit} UserData={UserData} setUserData={setUserData} options={options} />} */}
            <tr
                className='border-b-2'
            >
                <label
                    className='contents cursor-pointer'
                    htmlFor='EditModal'
                    onClick={() => handleUpdate(itemData)}>
                    <td>{getRestNameById(restPass)}</td> {/* //Rest Access */}

                    <td>{name} </td>{/* //Rest Name */}
                    <td>{restPass} </td> {/* //Rest Password */}
                    {/* <td>{date_upd ? new Date(date_upd).toLocaleString("he-IL") : none}</td> */}
                </label>
                <td>
                    {restPass !== "all" ? <span style={{ display: "flex", justifyContent: "center" }}>
                        <label
                            onClick={() => { handleUpdate(itemData) }}
                            className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full hover:text-white px-2 py-1 flex justify-center items-center'>
                            <i
                                className='ri-edit-2-line'
                                style={{ color: "green" }}></i>
                        </label>
                        <label
                            onClick={() => {
                                Swal.fire({ title: 'Are you sure?', text: "You won't be able to revert this!", icon: 'warning', showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'Yes, delete it!' }).then((result) => {
                                    if (result.isConfirmed) {
                                        deleteUser(id); window.location.reload();
                                    }
                                })
                            }}
                            className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full hover:text-white px-2 py-1 flex justify-center items-center'>
                            <i
                                className='ri-delete-bin-line'
                                style={{ color: "red" }}></i>
                        </label>
                    </span> : <>
                        <div>
                            <h3>Admin User</h3>
                        </div></>}
                </td>
            </tr></>

    );
};
// function EditModal({ UserData, setEdit, setUserData, options }) {
//     const passwordRef = useRef();
//     const [BoolState, setBoolState] = useState(UserData?.rest?.startsWith('group ') ? true : false);
//     // const [options, setOptions] = useState(false);
//     const [OptionValue, setOptionValue] = useState(UserData?.rest?.startsWith('group ') ? UserData?.rest?.split("group ")?.[1] : null);
//     const [OptionValueLang, setOptionValueLang] = useState(UserData?.language ? UserData?.language : null);

//     const handleClose = () => {
//         setEdit(false);
//         console.log({ UserData });
//         setUserData(null);
//         // getUsers();
//     };

//     const setNewuserPass = () => {
//         let newPass = generatePassword();
//         if (!UserData?.rest?.startsWith('group ')) {
//             newPass += UserData?.rest ? UserData?.rest : "";
//         }
//         passwordRef.current.value = newPass;

//     }
//     const generatePassword = () => {
//         const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
//         // const words = ["dad", "mom", "bob", "web", "uppsale"];
//         let password = '';
//         for (let i = 0; i < 6; i++) {
//             password += characters.charAt(Math.floor(Math.random() * characters.length));
//         }
//         // const randomWord = words[Math.floor(Math.random() * words.length)];
//         // return password + randomWord;
//         return password;
//     }
//     const updateUser = async ({ id, name, rest, type }) => {
//         console.log({
//             OptionValueLang, id, name, rest, type, OptionValue, ResetV: OptionValue ? `group ${OptionValue}` : rest
//         });
//         const res = await axios.put(`/api/users`, { auth, type, language: OptionValueLang || "en", id, name, rest: OptionValue ? `group ${OptionValue}` : rest });
//         // console.log({ data: res.data });
//         // setUsers(res.data);
//         Swal.fire('Saved!', 'Your file has been saved.', 'success');

//     }

//     const handleSubmit = (e) => {
//         const name = e.target.name.value;
//         const reset = e.target.reset.value;
//         const id = e.target.id.value;
//         e.preventDefault();
//         updateUser({ id, name, rest: reset, type: UserData?.type });
//         handleClose();

//         window.location.reload();

//     };
//     const handleChange = (selectedOption) => {
//         setOptionValue(selectedOption?.value);
//     };
//     const handleChangeLang = (selectedOptionLang) => {
//         setOptionValueLang(selectedOptionLang?.value);
//     };
//     return (
//         <>
//             <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
//                 <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//                     <div className="fixed inset-0 transition-opacity" aria-hidden="true">
//                         <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
//                     </div>
//                     <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
//                     <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
//                         <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
//                             <h1 className="text-3xl font-bold text-gray-900">{UserData?.type != "edit" ? "Add user" : `Edit user for ${UserData?.rest}`}</h1>
//                             <form className="flex flex-col items-start" onSubmit={handleSubmit}>
//                                 <label className="block text-gray-700 text-sm font-bold mb-2 px-2 py-3 w-full">
//                                     ID:
//                                     <input disabled type="text" name="id" value={!UserData?.rest ? null : UserData?.id} placeholder={!UserData?.rest && "Will automatically create"} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
//                                 </label>
//                                 <div style={{ width: "100%", }}>
//                                     <div
//                                         className='px-2 count flex justify-start mt-2'
//                                         onClick={(e) => e.stopPropagation()}>
//                                         <input
//                                             type='checkbox'
//                                             className={
//                                                 "toggle " + (BoolState ? "bg-green-500" : "bg-red-500")
//                                             }
//                                             defaultChecked={BoolState}
//                                             onChange={() => { setBoolState(e => !e); setOptionValue(null) }}
//                                         />
//                                         <span className='px-3 text-base font-bold mx-1'>
//                                             {BoolState ? "Group Management" : "Handling a single restaurant"}
//                                         </span>
//                                     </div>
//                                 </div>
//                                 {BoolState ? <label className="block text-gray-700 text-sm font-bold mb-2 px-2 py-3 w-full">
//                                     Group:
//                                     <div className="w-64">
//                                         <Select
//                                             // className="basic-single"
//                                             classNamePrefix="select"
//                                             // defaultValue={colourOptions[0]}
//                                             // isDisabled={isDisabled}
//                                             // isLoading={isLoading}
//                                             // isClearable={isClearable}
//                                             // isRtl={isRtl}
//                                             defaultValue={options?.find(option => option.value == UserData?.rest?.split("group ")?.[1])}
//                                             isSearchable={true}
//                                             name="color"
//                                             onChange={handleChange}
//                                             options={options}
//                                             className="basic-single text-sm border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
//                                             menuPlacement="auto"
//                                             styles={{
//                                                 menuList: (provided, state) => ({
//                                                     ...provided,
//                                                     maxHeight: '10rem',
//                                                     overflowY: 'scroll',
//                                                     '::-webkit-scrollbar': {
//                                                         display: 'none', // Hide scrollbar
//                                                     },
//                                                 }),
//                                             }}
//                                         />
//                                     </div>
//                                 </label>
//                                     : <label className="block text-gray-700 text-sm font-bold mb-2 px-2 py-3 w-full">
//                                         Reset:
//                                         <input type="number" name="reset" defaultValue={UserData?.rest} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
//                                     </label>}

//                                 <label className="block text-gray-700 text-sm font-bold mb-2 px-2 py-3 w-full">
//                                     Password key:
//                                     <div className="flex justify-between items-center">
//                                         <input type="text" ref={passwordRef} name="name" defaultValue={UserData?.rest ? generatePassword() : UserData?.name} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2" />

//                                         <button type="button" onClick={() => setNewuserPass()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs">Generate Password</button>
//                                     </div>
//                                 </label>

//                                 <label className="block text-gray-700 text-sm font-bold mb-2 px-2 py-3 w-full">
//                                     Language:
//                                     <Select
//                                         // className="basic-single"
//                                         classNamePrefix="select"
//                                         // defaultValue={colourOptions[0]}
//                                         // isDisabled={isDisabled}
//                                         // isLoading={isLoading}
//                                         // isClearable={isClearable}
//                                         // isRtl={isRtl}
//                                         // defaultValue={options?.find(option => option.value == UserData?.language)}
//                                         isSearchable={true}
//                                         name="language"
//                                         onChange={handleChangeLang}
//                                         options={[{ value: "en", label: "English" }, { value: "he", label: "Hebrew" }]}
//                                         className="basic-single text-sm border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
//                                         menuPlacement="auto"
//                                         styles={{
//                                             menuList: (provided, state) => ({
//                                                 ...provided,
//                                                 maxHeight: '10rem',
//                                                 overflowY: 'scroll',
//                                                 '::-webkit-scrollbar': {
//                                                     display: 'none', // Hide scrollbar
//                                                 },
//                                             }),
//                                         }}
//                                     />
//                                 </label>
//                                 <span className="w-full flex justify-between pt-5">
//                                     <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Save</button>
//                                     <button onClick={handleClose} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Close</button>
//                                 </span>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }
export default User;
