import React, { useState, useContext, useEffect } from "react";
import { signOut } from "firebase/auth";
import "remixicon/fonts/remixicon.css";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Dashboard.css";
// import logo from '../../assets/images/profile1.png'
// import { useAuthState } from 'react-firebase-hooks/auth'
// import auth from '../../firebase.init'
import Swal from "sweetalert2";
import { UserContext, auth, permission, translatedWeb } from "../../global";

const Dashboard = () => {
  const navigate = useNavigate();
  const { rest, setRest, branch, setBranch, RestData, RestList, setRestList, clientlanguage, isRtl } =
    useContext(UserContext);
  const { name, logo, branches } = RestData.details;
  const [SearchList, setSearchList] = useState();
  const checkPermission = permission === "all" || permission.startsWith("group ");
  // const [user, loading, error] = useAuthState(auth)
  const rightSide = isRtl ? "right" : "left";
  const leftSide = isRtl ? "left" : "right";
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    // window.localStorage.removeItem("defaultRest");
    // For see rest
    if (permission && permission !== "all" && !permission.startsWith("group ")) {
      setRest(permission);
      navigate("/items");
    }

    // For see all rest at group
    if (permission.startsWith("group ")) {
      const group = parseInt(permission.split(" ")[1]);
      console.log({ group });
      const groupRests = RestData?.groups?.filter((rest) => rest?.id == group)?.[0];
      console.log({ groupRests });
      searchRest({ group: true, groupVal: groupRests?.name });
      !rest && setRest(group);
      navigate("/items");
    }
  }, [permission]);

  // const handleRefresh = async (serial) => {
  //   Swal.fire({
  //     title: "לעדכן את כל המכשירים במסעדה?",
  //     icon: "question",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "כן",
  //     cancelButtonText: "לא",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       await axios.get(`/api/refresh-socket/` + rest + "/all").then(
  //         (res) =>
  //           res.status === 200 &&
  //           Swal.fire({
  //             title: "כל המכשירים במסעדה התעדכנו",
  //             icon: "success",
  //             confirmButtonText: "בסדר",
  //           })
  //       );
  //     }
  //   });
  // };
  // handle logout user
  const handleLogOut = () => {
    Swal.fire({
      title: "User Replacement",
      html: `<input type="password" id="password" className="swal2-input" placeholder="password">`,
      confirmButtonText: "Approval",
      focusConfirm: false,
      preConfirm: () => {
        const password = Swal.getPopup().querySelector("#password").value;
        if (!password) {
          Swal.showValidationMessage(`Please enter a password `);
        }
        return { password: password };
      },
    }).then((result) => {
      window.localStorage.setItem("password", result.value.password);
      document.location.href = "/";
    });
    // Swal.fire({
    //   title: 'Are you sure want to Logout?',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Yes',
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     // signOut(auth)
    //   }
    // })
  };

  const [open, setOpen] = useState(window.innerWidth > 767);
  const Menus = [
    {
      title: translatedWeb?.navbar?.main[clientlanguage],
      src: "ri-home-8-line",
      path: "/settings",
    },
    {
      title: translatedWeb?.navbar?.dishes[clientlanguage],
      src: "ri-restaurant-line",
      path: "/items",
    },
    {
      title: translatedWeb?.navbar?.categories[clientlanguage],
      src: "ri-file-list-line",
      path: "/categories",
    },
    {
      title: translatedWeb?.navbar?.tags[clientlanguage],
      src: "ri-price-tag-3-line",
      path: "/tags",
    },
    {
      title: translatedWeb?.navbar?.devices[clientlanguage],
      src: "ri-tablet-line",
      path: "/devices",
    },
    {
      title: translatedWeb?.navbar?.preferences[clientlanguage],
      src: "ri-settings-2-line",
      path: "/preferences",
    },
  ];
  const AdminMenus = [
    {
      title: translatedWeb?.navbar?.allDevices[clientlanguage],
      src: "ri-tablet-line",
      path: "/allDevices",
    },
    {
      title: "Socket.io",
      src: "ri-code-line",
      path: "/socket",
    },
    {
      title: translatedWeb?.navbar?.map[clientlanguage],
      src: "ri-map-line",
      path: "/map",
    },
    {
      title: translatedWeb?.navbar?.restaurants[clientlanguage],
      src: "ri-restaurant-line",
      path: "/restaurants",
    },
    {
      title: translatedWeb?.navbar?.users[clientlanguage],
      src: "ri-user-add-line",
      path: "/users",
    },
    {
      title: translatedWeb?.navbar?.utilities[clientlanguage],
      src: "ri-tools-line",
      path: "/tools",
    },
    // {
    //   title: "העדפות",
    //   src: "ri-settings-2-line",
    //   path: "/preferences",
    // },
  ];
  const searchRest = (event) => {
    let value;
    if (event.group) {
      value = event.groupVal;
    } else {
      value = event.target.value;
    }
    console.log("searchValue:" + value);
    if (value === "") {
      setSearchList(
        RestList
        // .filter((rest) => {
        //   const groupsFilter = RestData.groups.filter((group) =>
        //     rest.name.includes(group.name)
        //   );
        //   return !groupsFilter.length;
        // })
      );
      return;
    }
    setSearchList(() =>
      RestList.filter(
        (i) =>
          i.id.toString().includes(value.toLowerCase()) ||
          i.name.toLowerCase().includes(value.toLowerCase())
      ).reverse()
    );
  };
  return (
    <div className="relative pb-4 pt-[74px] md:pt-0" >
      <input id="" type="checkbox" className="drawer-toggle" />
      <div
        className={`transition-all ease-in-out duration-300 ${isRtl ? open ? "md:mr-[330px]" : "md:mr-25" : open ? "md:ml-[330px]" : "md:ml-25"
          }`}
      >
        {/* outlet  */}
        {/* <div className="flex justify-between items-center my-2 mx-5"> */}
        {/* <div className="${rightSide}_arrow"> */}
        {/* <i className='ri-arrow-${rightSide}-s-fill text-2xl text-gray-400 rotate-180 rounded-full border cursor-pointer'></i> */}
        {/* </div> */}
        {/* <div className="search flex justify-center items-center">
            <div className="font-sans text-black bg-white flex items-center justify-center">
              <div className="border rounded-lg overflow-hidden flex">
                <input
                  type="text"
                  className="px-4 py-2 outline-0"
                  placeholder="חיפוש..."
                />
                <button className="flex items-center justify-center px-4">
                  <svg
                    className="h-4 w-4 text-grey-dark"
                    fill="currentColor"
                    xmrns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                  </svg>
                </button>
              </div>
            </div>
            <i className="ri-notification-3-line ml-2 text-2xl cursor-pointer text-gray-400"></i>
          </div> */}
        {/* </div> */}
        <Outlet />
      </div>

      <div
        style={{ backgroundColor: "#F2EAE1" }}
        className={`fixed top-0 h-screen shadow md:${rightSide}-0 z-50 ${open ? `-${rightSide}-0` : `-${rightSide}-28`
          }`}
      >
        {/* DESKTOP MENU ICON */}
        <i
          className={`z-10 bg-white text-black hidden lg:flex justify-center items-center ${open ? `ri-arrow-${leftSide}-s-line` : `ri-arrow-${leftSide}-s-line`
            } absolute cursor-pointer -${leftSide}-4 top-[50vh] -mt-[20px] w-[40px] h-[40px]
           border-2 rounded-full text-2xl ${!open && `rotate-180 -${leftSide}-[54px] md:-${leftSide}-4`
            }`}
          onClick={() => setOpen(!open)}
        ></i>
        {/* MOBILE MENU ICON */}
        <i
          className={`z-10 bg-white text-black flex lg:hidden justify-center items-center ${open ? `ri-arrow-${isRtl ? rightSide : leftSide}-s-line` : "ri-menu-line"
            } absolute cursor-pointer -${!isRtl ? rightSide : leftSide}-6 top-[10px] w-[50px] h-[50px]
           border-2 rounded-full text-2xl ${!open && `rotate-180 -${!isRtl ? rightSide : leftSide}-[74px]`
            }`}
          onClick={() => setOpen(!open)}
        ></i>
        <div
          className={`${open ? "w-[320px]" : "w-24"
            } h-screen pt-3 relative duration-300 mobile-full  ${open} scrollbar-hidden ${!open ? isMobile ? "hidden" : "  " : ""
            }`}
          style={{
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          <div className="profile">
            {open && (
              <h2 className={`relative text-${leftSide} text-sm font-bold mb-3  mr-5`}>
                {/* <span className="bg-yellow-400 w-1 h-6 top-1/2 bottom-1/2 my-auto inline-block absolute"></span> */}
                {/* <span className="ml-3">שלום, מנהל</span> */}
              </h2>
            )}
            <div className="flex items-center justify-center rounded-full">
              <img
                src={logo || "/images/logos/uppsale.png"}
                alt=""
                className={`cursor-pointer duration-500 rounded-full p-3 max-h-[120px] ${open && "rotate-[360deg]"
                  }`}
              />
            </div>
            {open && (
              <>
                <div className="user_info">
                  <label onClick={() => {
                    const language = localStorage.getItem('language') ? localStorage.getItem('language') : null;
                    const defaultRest = localStorage.getItem('defaultRest') ? localStorage.getItem('defaultRest') : null;
                    localStorage.clear();
                    sessionStorage.clear();
                    language && localStorage.setItem('language', language);
                    defaultRest && localStorage.setItem('defaultRest', defaultRest);
                    // window.location.reload();
                    window.location.reload(true);
                  }
                  }
                    tabIndex={0}
                    className="cursor-pointer block mx-3 my-1 px-5 py-3 bg-red-500 hover:bg-red-600 rounded-lg text-white"
                  >
                    {translatedWeb?.navbar?.logout[clientlanguage]}

                  </label>
                  <div className="block dropdown dropdown-bottom dropdown-end my-2 mx-2">

                    <label
                      tabIndex={0}
                      className="cursor-pointer block mx-1 my-0 px-5 py-3 bg-purple-500 hover:bg-purple-600 rounded-lg text-white"
                    >
                      {RestData.group
                        ? RestData.group.name +
                        ` ( ${translatedWeb?.navbar?.group[clientlanguage]} ` +
                        RestData.group.id +
                        ")"
                        : `  ${translatedWeb?.navbar?.groups[clientlanguage]} `}
                    </label>
                    {/* {console.log({ group: RestData?.groups })} */}
                    {permission === "all" && (
                      <ul
                        tabIndex={0}
                        className="flex-row dropdown-content content-start overflow-y-scroll overflow-x-clip h-[450px] menu p-2 mt-3 shadow bg-base-100 w-[300px]"
                      >
                        <li className="w-full" key={-1}>
                          <a
                            className={"active:bg-slate-500"}
                            onClick={(e) => {
                              searchRest({ group: true, groupVal: "" });
                              e.nativeEvent.target.parentElement.parentElement.blur();
                              document.getElementById("restsList").focus();
                            }}
                          >
                            {translatedWeb?.navbar?.clear[clientlanguage]}
                          </a>
                        </li>
                        {RestData?.groups?.map(({ id, name }, i) => (
                          <li className="w-full" key={i}>
                            <a
                              href
                              className={"active:bg-slate-500"}
                              onClick={(e) => {
                                searchRest({ group: true, groupVal: name });
                                e.nativeEvent.target.parentElement.parentElement.blur();
                                document.getElementById("restsList").focus();
                              }}
                            >
                              {id} | {name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="user_info">
                  <div className="block dropdown dropdown-bottom dropdown-end my-2 mx-2">
                    <label
                      id="restsList"
                      tabIndex={1}
                      className="cursor-pointer block mx-1 my-0 px-5 py-3 bg-blue-500 hover:bg-blue-600 rounded-lg text-white"
                    // onBlur={() => searchRest({ group: true, groupVal: "" })}
                    >
                      {name} ({rest})
                    </label>
                    {/* {console.log({ checkPermission, permi: permission === "all" || permission.startsWith("group "), a: permission === "all", b: permission.startsWith("group ") })} */}
                    {checkPermission && (
                      <ul
                        tabIndex={1}
                        className="rounded flex-row dropdown-content content-start overflow-y-scroll overflow-x-clip h-[450px] menu p-2 mt-3 shadow bg-base-100 w-[300px]"
                      >
                        <li className="w-full mb-1">
                          <input
                            id="searchRest"
                            className="border active:bg-slate-100 focus:bg-slate-100 text-black"
                            placeholder={translatedWeb?.navbar?.search[clientlanguage]}
                            onChange={searchRest}
                          ></input>
                        </li>
                        {/* <li className="w-full mb-1 groups">
                          <span
                            onClick={(e) => {
                              // target.setAttribute("value", "River");
                              searchRest({ group: true, groupVal: "River" });
                            }}
                          >
                            River
                          </span>
                          <span
                            onClick={(e) => {
                              // target.setAttribute("value", "ג'פניקה");
                              searchRest({ group: true, groupVal: "ג'פניקה" });
                            }}
                          >
                            ג'פניקה
                          </span>
                        </li> */}
                        {/* <li className="w-full">
                      <a className={"active:bg-slate-500"} onClick={() => null}>
                        הוסף מסעדה חדשה
                      </a>
                    </li> */}
                        {/* RestList
                          .filter((rest) => {
                            const groupsFilter = RestData.groups.filter(
                              (group) => rest.name.includes(group.name)
                            );
                            return !groupsFilter.length;
                          }) */}
                        {(SearchList || RestList).map(({ name, id }, i) => (
                          <li className="w-full" key={i}>
                            <a
                              className={"active:bg-slate-500"}
                              onClick={() => setRest(id)}
                            >
                              {id} | {name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {/* <h2 className="text-center font-bold">{name}</h2> */}
                  {/* <p className="text-center text-red-500">{rest}</p> */}
                </div>
              </>
            )}
          </div>
          {/* menu start  */}
          {permission !== "all" ? (
            <ul className="pt-0 mx-3">

              {Menus.filter((item) =>
                [translatedWeb?.navbar?.dishes[clientlanguage], translatedWeb?.navbar?.categories[clientlanguage], translatedWeb?.navbar?.tags[clientlanguage]].includes(item.title)
              ).map((Menu, index) => (
                <li
                  key={index}
                  className={`flex justify-center items-center rounded-md cursor-pointer text-sm gap-x-4`}
                >
                  {/* for mobile device */}
                  <NavLink
                    // onClick={() => setOpen(false)}
                    className={({ isActive }) =>
                      isActive
                        ? `active-link flex items-center ${open
                          ? "py-1 my-0.5 px-5"
                          : "my-0.5 flex justify-center items-center"
                        }`
                        : `linkk flex items-center ${open
                          ? "py-1 my-0.5 px-5"
                          : "my-0.5 flex justify-center items-center"
                        }`
                    }
                    to={`${Menu.path}`}
                  >
                    {open ? (
                      <i className={`${isRtl ? "ml" : "mr"}-2 text-2xl ${Menu.src}`}></i>
                    ) : (
                      <i
                        className={`p-2 text-2xl ${Menu.src}`}
                        data-tip={Menu.tooltip}
                      ></i>
                    )}

                    <span
                      className={`${!open && "hidden"
                        } origin-${rightSide} duration-200`}
                    >
                      {/* menu  */}
                      <NavLink className="block" to={`${Menu.path}`}>
                        {Menu.title}
                      </NavLink>
                    </span>
                  </NavLink>
                </li>
              ))}

            </ul>
          ) : (
            <>
              <ul className="pt-0 mx-3">

                {Menus.map((Menu, index) => (
                  <li
                    key={index}
                    className={`flex justify-center items-center rounded-md cursor-pointer text-sm gap-x-4`}
                  >
                    {/* for mobile device */}
                    <NavLink
                      // onClick={() => setOpen(false)}
                      className={({ isActive }) =>
                        isActive
                          ? `active-link flex items-center ${open
                            ? "py-1 my-0.5 px-5"
                            : "my-0.5 flex justify-center items-center"
                          }`
                          : `linkk flex items-center ${open
                            ? "py-1 my-0.5 px-5"
                            : "my-0.5 flex justify-center items-center"
                          }`
                      }
                      to={`${Menu.path}`}
                    >
                      {open ? (
                        <i className={`${isRtl ? "ml" : "mr"}-2 text-2xl ${Menu.src}`}></i>
                      ) : (
                        <i
                          className={`p-2 text-2xl ${Menu.src}`}
                          data-tip={Menu.tooltip}
                        ></i>
                      )}

                      <span
                        className={`${!open && "hidden"
                          } origin-${rightSide} duration-200`}
                      >
                        {/* menu  */}
                        <NavLink className="block" to={`${Menu.path}`}>
                          {Menu.title}
                        </NavLink>
                      </span>
                    </NavLink>
                  </li>

                ))}
              </ul>
              <h2 className={`relative text-${rightSide} text-sm font-bold mt-8 ${isRtl ? "mr-5" : "ml-5"}`}>
                <span className="bg-red-400 w-1 h-6 top-1/2 bottom-1/2 my-auto inline-block absolute"></span>
                <span className={isRtl ? "mr-3" : "ml-3"}>{translatedWeb?.navbar?.Management[clientlanguage]}</span>
              </h2>
              <ul className="pt-2 mx-3">
                {AdminMenus.map((Menu, index) => (
                  <li
                    key={index}
                    className={`flex justify-center items-center rounded-md cursor-pointer text-sm gap-x-4`}
                  >
                    {/* for mobile device */}
                    <NavLink
                      // onClick={() => setOpen(false)}
                      className={({ isActive }) =>
                        isActive
                          ? `active-link flex items-center ${open
                            ? "py-1 my-0.5 px-5"
                            : "my-0.5 flex justify-center items-center"
                          }`
                          : `linkk flex items-center ${open
                            ? "py-1 my-0.5 px-5"
                            : "my-0.5 flex justify-center items-center"
                          }`
                      }
                      to={`${Menu.path}`}
                    >
                      {open ? (
                        <i className={`${isRtl ? "ml" : "mr"}-2 text-2xl ${Menu.src}`}></i>
                      ) : (
                        <i
                          className={`p-2 text-2xl ${Menu.src}`}
                          data-tip={Menu.tooltip}
                        ></i>
                      )}

                      <span
                        className={`${!open && "hidden"
                          } origin-${rightSide} duration-200`}
                      >
                        {/* menu  */}
                        <NavLink className="block" to={`${Menu.path}`}>
                          {Menu.title}
                        </NavLink>
                      </span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </>
          )}
          {/* {open ? (
            <button
              onClick={handleRefresh}
              className="absolute bottom-2 p-2 w-1/2 ${rightSide}-0 ${leftSide}-0 mx-auto border border-gray-400"
            >
              <i className="ri-refresh-line"></i>
              <span style={{ display: "block" }}>רענן מכשירים</span>
            </button>
          ) : (
            <button
              onClick={handleRefresh}
              className="absolute bottom-2 py-2 w-1/2 ${rightSide}-0 ${leftSide}-0 mx-auto border border-gray-400"
            >
              <i className="ri-refresh-line"></i>
            </button>
          )} */}
          {/* {open ? (
            <button
              onClick={handleLogOut}
              className="absolute bottom-2 p-2 w-1/2 ${rightSide}-0 ${leftSide}-0 mx-auto border border-gray-400"
            >
              <i className="ri-lock-password-line"></i>
              <span style={{ display: "block" }}> החלף משתמש</span>
            </button>
          ) : (
            <button
              onClick={handleLogOut}
              className="absolute bottom-2 py-2 w-1/2 ${rightSide}-0 ${leftSide}-0 mx-auto border border-gray-400"
            >
              <i className="ri-lock-password-line"></i>
            </button>
          )} */}
        </div>
      </div>
    </div >
  );
};

export default Dashboard;
