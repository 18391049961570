import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import Swal from "sweetalert2";
import UploadFiles from "../Shared/UploadFiles";
import Modal from "./Modal";

const UploadModal = ({
  data: {
    Type,
    Title,
    setModal,
    setUpload,
    rest,
    path,
    setIsLoading,
    isImg,
    isMultiple,
    onComplete,
    customFileName,
    numericName,
  },
}) => {
  const onCompleteDefault = () =>
    Swal.fire({
      title: "The pictures have been successfully uploaded",
      icon: "success",
      confirmButtonText: "Thanks",
    });
  return (
    <Modal setModal={setModal}>
      <h1 className="text-xl font-bold mb-5">{Title}</h1>
      <UploadFiles
        data={{
          isImg,
          isMultiple,
          path,
          onComplete: onComplete || onCompleteDefault,
          customFileName,
          numericName,
        }}
      />
    </Modal>
  );
};

export default UploadModal;
