import React, { useState } from "react";
import { useContext } from "react";
import { FiClock, FiChevronUp, FiChevronDown } from "react-icons/fi";
import { UserContext } from "../../global";

const TimePicker = ({ title, time, setTime, startTime }) => {
  const { rest, RestData, clientlanguage, isRtl } = useContext(UserContext);

  // const [time, setTime] = useState({ hours: 0, minutes: 0 });
  const [showMiniClock, setShowMiniClock] = useState(false);

  const updateHours = (increase) => {
    setTime(({ hours, minutes }) => ({
      hours: (hours + (increase ? 1 : -1) + 24) % 24,
      minutes,
    }));
  };

  const updateMinutes = (increase) => {
    setTime(({ hours, minutes }) => ({
      hours,
      minutes: (minutes + (increase ? 1 : -1) + 60) % 60,
    }));
  };

  const formatTime = ({ hours, minutes }) =>
    `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

  return (
    <div className='relative flex flex-col items-start'>
      <div
        className='relative w-48 my-4'
        onClick={() => setShowMiniClock(!showMiniClock)}>
        <input
          type='text'
          value={formatTime(time)}
          className='block w-full px-4 py-2 text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer'
          placeholder=' '
          readOnly
        />
        <label
          htmlFor='floatingInput'
          className='absolute text-lg text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 right-0 origin-[0] peer-focus:right-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
          {title ? title[clientlanguage] : "שעת התחלה"}
        </label>
        <FiClock
          className={`absolute ${isRtl ? "left-2" : "right-2"
            } top-1/2 transform -translate-y-1/2 text-xl text-gray-500 cursor-pointer`}
        />
      </div>

      {showMiniClock && (
        <div className='absolute top-full mt-2 w-48 p-4 bg-gray-100 text-black rounded-lg shadow-lg flex flex-col items-center z-10 transition-all duration-300 ease-in-out'>
          <div className='flex items-center justify-between w-full border-b border-gray-200 pb-2'>
            <FiChevronDown
              className='cursor-pointer text-blue-500 hover:text-blue-700 transition-colors duration-300'
              onMouseDown={(e) => e.preventDefault()}
              onMouseUp={(e) => e.preventDefault()}
              onClick={(e) => {
                e.preventDefault();
                updateHours(false);
              }}
            />
            <div className='text-lg font-semibold'>{formatTime(time)}</div>
            <FiChevronUp
              className='cursor-pointer text-blue-500 hover:text-blue-700 transition-colors duration-300'
              onMouseDown={(e) => e.preventDefault()}
              onMouseUp={(e) => e.preventDefault()}
              onClick={(e) => {
                e.preventDefault();
                updateHours(true);
              }}
            />
          </div>
          {startTime && formatTime(startTime) >= formatTime(time) && (
            <div className='relative top-full mt-2 w-full p-4 bg-red-100 text-red-800 border border-red-300 rounded-lg shadow-md flex items-center z-10 transition-all duration-300 ease-in-out'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-16 w-16  text-red-600'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M18.364 5.636a9 9 0 11-12.728 0 9 9 0 0112.728 0zM12 8v4m0 4h.01'
                />
              </svg>
              <span>השעה לא יכולה להיות לפני השעה {formatTime(startTime)}</span>
            </div>
          )}
          <button
            disabled={startTime && formatTime(startTime) >= formatTime(time)}
            className={`mt-4 px-4 py-2 rounded-full shadow-md transition-shadow duration-300 ${startTime && formatTime(startTime) >= formatTime(time)
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-blue-500 text-white hover:shadow-lg"
              }`}
            onClick={() => setShowMiniClock(false)}>
            אישור
          </button>
        </div>
      )}
    </div>
  );
};

export default TimePicker;
