import { Route, Routes, Navigate } from "react-router-dom";
import Tools from "./components/pages/Tools";
import Map from "./components/pages/Map";
import Socket from "./components/pages/Socket";
import Dashboard from "./components/pages/Dashboard";
// import Home from "./components/pages/Home";
// import Devices from "./components/pages/Devices";
import Settings from "./components/pages/Settings";
import Items from "./components/pages/Items";
import Login from "./components/Login/Login";
// import RequireAuth from './components/Login/RequireAuth'
// import SignUp from './components/Login/SignUp'
import NotFound from "./components/NotFound/NotFound";
// import app from './firebase.init'
// import {getAuth} from 'firebase/auth'
// const auth = getAuth(app)
import { UserContext, permission } from "./global";
import Preferences from "./components/pages/Preferences";
import Users from "./components/pages/Users";
import { useContext } from "react";
import TagsManager from "./components/pages/TagsManager";

function App() {
  const { isRtl } = useContext(UserContext);
  return (
    // <GlobalContext
    //   subPages={
    <div dir={isRtl ? "rtl" : "ltr"}>
      <Routes>
        {permission ? (
          <Route
            path='/'
            element={<Dashboard />}>
            {/* <Route
          path='/'
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        > */}
            <Route
              path='/'
              element={<Navigate to='/settings' />}></Route>
            <>
              {/* <Route path="/Home" element={<Home />}></Route> */}
              {/* <Route path="/Devices2" element={<Devices />}></Route> */}
              <Route
                path='/Items'
                element={
                  <Items
                    key='menuItems'
                    type='menuItems'
                  />
                }></Route>
              <Route
                path='/Categories'
                element={
                  <Items
                    key='categories'
                    type='categories'
                  />
                }></Route>
              <Route
                path='/Devices'
                element={
                  <Items
                    key='devices'
                    type='devices'
                  />
                }></Route>
              <Route
                path='/allDevices'
                element={
                  <Items
                    key='allDevices'
                    type='allDevices'
                  />
                }></Route>
              {/* <Route
                path='/users'
                element={
                  <Items
                    key='users'
                    type='users'
                  />
                }></Route> */}
              {/* <Route
                path='/tags'
                element={
                  <Items
                    key='tags'
                    type='tags'
                  />
                }></Route> */}
              <Route
                path='/tags'
                element={<TagsManager />}></Route>
              <Route
                path='/Tools'
                element={<Tools />}></Route>
              <Route
                path='/preferences'
                element={<Preferences />}></Route>
              <Route
                path='/users'
                element={<Users />}></Route>
              <Route
                path='/Map'
                element={<Map />}></Route>
              <Route
                path='/Socket'
                element={<Socket />}></Route>
              <Route
                path='/Settings'
                element={<Settings />}></Route>

              <Route
                path='/Restaurants'
                element={
                  <Items
                    key='restaurants'
                    type='restaurants'
                  />
                }></Route>

              <Route
                path='*'
                element={<NotFound />}></Route>
            </>
          </Route>
        ) : (
          <Route
            path='/login'
            element={<Login />}></Route>
        )}
        {/* <Route path='/login' element={<Login />}></Route>
        <Route path='/signUp' element={<SignUp />}></Route> */}

        <Route
          path='*'
          element={<Navigate to='/login' />}></Route>
      </Routes>
    </div>
    //   }
    // />
  );
}

export default App;
