import axios from "axios";
import React, { useEffect, useState, useContext, useRef } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import { languages, translatedWeb, UserContext } from "../../global";
import Modal from "./Modal";
import { ReactSortable } from "react-sortablejs";

export default function HomeCategories({
  refresh,
  setIsLoading,
  rest,
  Lang,
  setModal,
}) {
  const { RestData, clientlanguage, isRtl } = useContext(UserContext);

  let categoriesOrderOptions = [];
  const [CategoriesOrderState, setCategoriesOrderState] = useState([]);

  useEffect(() => {
    setCategoriesOrderState(RestData.details?.template?.homeCategories || []);
  }, [RestData.details?.template?.homeCategories]);

  const categoriesToNames = (id) => {
    let string = "";
    // console.log({ RestData });
    RestData.categories.every((category) => {
      if (category.catOrder?.includes(id)) {
        string += category.name[Lang];
        return false;
      }
      return true;
    });
    return string;
  };

  RestData.categories?.map((item) =>
    categoriesOrderOptions.push({
      value: item.id,
      label: item.name.h + " (" + (categoriesToNames(item.id) || "ראשי") + ")",
    })
  );

  const handleAddReq = async () => {
    // e.preventDefault();
    setIsLoading(true);
    await axios.put(`/api/settings`, {
      rest: parseInt(rest),
      data: { "template.homeCategories": CategoriesOrderState },
    });
    refresh();
    // close modal after successfully added student
    setModal(false);
  };

  return (
    <Modal setModal={setModal}>
      <h1 className="text-xl font-bold mb-5">{translatedWeb?.homeCategories?.mainCat[clientlanguage]}</h1>
      <div class="w-full" id="editModalForm">
        <div class="md:flex md:items-center mb-6">
          <div class="md:w-2/3">
            <Select
              onChange={(e) => {
                console.log({ e });
                setCategoriesOrderState([...CategoriesOrderState, e.value]);
              }}
              name="categories"
              options={categoriesOrderOptions}
              defaultValue={[]}
              placeholder={translatedWeb?.homeCategories?.chooseFromList[clientlanguage]}

              // isMulti
              isRtl
              noOptionsMessage={() => translatedWeb?.homeCategories?.noOptions[clientlanguage]}

            />
            <ReactSortable
              list={CategoriesOrderState}
              setList={setCategoriesOrderState}
              animation={200}
            >
              {CategoriesOrderState.map((item, i) => (
                <div
                  key={i}
                  style={{
                    cursor: "row-resize",
                    padding: "10px",
                    margin: "5px 0",
                    border: "1px solid",
                    borderRadius: "5px",
                  }}
                >
                  <span>
                    {
                      categoriesOrderOptions.filter((e) => e.value == item)[0]
                        ?.label
                    }
                  </span>
                  <span
                    style={{
                      color: "red",
                      cursor: "pointer",
                      float: "left",
                    }}
                    onClick={() =>
                      setCategoriesOrderState((e) =>
                        e.filter((elem, index) => index !== i)
                      )
                    }
                  >
                    X
                  </span>
                </div>
              ))}
            </ReactSortable>
          </div>
        </div>

        <div
          style={{ float: "right" }}
          className="md:flex md:items-center pb-6"
        >
          <div className="modal-action mt-2">
            <button
              onClick={handleAddReq}
              className="ml-2 px-12 py-2 bg-sky-500 hover:bg-sky-600 rounded-lg text-white"
            >
              {translatedWeb?.homeCategories?.update[clientlanguage]}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
