import React, { useEffect, useState, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PiBroomBold } from "react-icons/pi";
import { FaDev } from "react-icons/fa";
import { UserContext, translatedWeb, versions } from "../../global";
import axios from "axios";
import Swal from "sweetalert2";

export default function Device({
  itemData,
  handleDeleteStudentData,
  handleMDM,
  handleRefresh,
  update,
  setUpdate,
  isOnline,
  type,
  mdmDevices,
}) {
  const { serial, rest, date_upd, apk, gsf, isDev, comment } = itemData;
  const { RestList, clientlanguage } = useContext(UserContext);
  const navigate = useNavigate();
  const [Policy, setPolicy] = useState();
  const lowerSerial = ("" + gsf).toLowerCase();
  const mdmData = useMemo(
    () => mdmDevices.find((device) => device.name.endsWith(lowerSerial)),
    []
  );

  // get user
  let user = localStorage.getItem("password");

  // const batteryEvents = mdmData?.powerManagementEvents;
  const batteryEvents = mdmData?.powerManagementEvents?.filter(
    (event) => event.eventType === "BATTERY_LEVEL_COLLECTED"
  );
  if (batteryEvents) {
    batteryEvents.reverse();
  }
  const battery = batteryEvents?.[0]?.batteryLevel;

  const restObj = {};

  const pairToRest = (serialClient) => {
    Swal.fire({
      title: `${translatedWeb?.device?.pairSnToresturant[clientlanguage]} ${serialClient}  `,
      input: "number",
      inputLabel: translatedWeb?.device?.enterRestNum[clientlanguage],
      inputPlaceholder: translatedWeb?.device?.choseAnNum[clientlanguage],
      showCancelButton: true,
      confirmButtonText: translatedWeb?.device?.update[clientlanguage],
      cancelButtonText: translatedWeb?.device?.cancle[clientlanguage],
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      allowOutsideClick: () => !Swal.isLoading(),
      inputValidator: (value) => {
        return new Promise((resolve) => {
          console.log({ value });
          resolve();
        });
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          console.log({ result });
          const response = await axios.put(`/api/editDevice/${serialClient}`, {
            rest: result.value,
          });
          console.log({ response });
          if (!response.status == 200) {
            return Swal.fire(translatedWeb?.device?.errorToPair[clientlanguage], "", "error");
          }
          return Swal.fire(
            `${translatedWeb?.device?.successfullyPaired[clientlanguage]} ${result.value}`,
            "",
            "success"
          );
        } catch (error) {
          Swal.fire(translatedWeb?.device?.errorToPair[clientlanguage], "", "error");
        }
      }
    });
  };

  RestList.forEach((element) => {
    // console.log({ element });
    restObj[element?.id] = [element?.name];
  });
  // console.log({ restObj });

  // {
  //   "eventType": "BATTERY_LEVEL_COLLECTED",
  //   "createTime": "2023-06-21T11:52:23.771Z",
  //   "batteryLevel": 91
  // },
  useEffect(() => {
    const policyFullName = mdmData?.appliedPolicyName;
    const policyName = policyFullName?.substring(
      policyFullName.lastIndexOf("/") + 1,
      policyFullName.length
    );
    setPolicy(policyName);
  }, []);
  return (
    <tr className='border-b-2'>
      <td
        style={{ textAlign: "start" }}
        onClick={async () => {
          if (type === "allDevices") {
            pairToRest(serial);
          }
        }}>
        {serial.slice(0, -4)}
        <b>{serial.slice(-4)}</b>
        {isDev && (
          <i
            className={"ri-code-line"}
            style={{ verticalAlign: "bottom", margin: "0 0 0 5px" }}
          />
        )}
        {/* {console.log({ Policy })} */}
        {/* {mdmData &&
          (!(Policy == "default" || Policy == "test") ? (
            <i
              className='ri-lock-unlock-line'
              style={{
                color: "green",
                verticalAlign: "bottom",
                margin: "0 0 0 5px",
              }}></i>
          ) : (
            <i
              className='ri-lock-line'
              style={{
                color: !Policy == "test" ? "red" : "rgb(92, 70, 222)",
                verticalAlign: "bottom",
                margin: "0 0 0 5px",
              }}></i>
          ))} */}
      </td>
      {type === "allDevices" && (
        <>
          <td>{rest}</td>
          {/* <td
            style={{
              maxWidth: "100px",
              whiteSpace: "initial",
              color: battery <= 20 ? "red" : "green",
            }}>
            <b>{battery ? battery + "%" : ""}</b>
          </td> */}
        </>
      )}
      {/* <td>{branch}</td> */}
      <td
        style={{
          color: apk >= 22 ? "green" : "red",
        }}>
        {apk
          ? apk + (versions[apk] ? " (v" + versions[apk] + ")" : "")
          : translatedWeb?.device?.noInfo[clientlanguage]}
      </td>
      <td>{gsf?.toString()}</td>
      <td>{comment || ""}</td>
      <td>{new Date(date_upd)?.toLocaleString("he-IL") || "Unknown"}</td>
      <td
        className='flex justify-center items-center'
        dir='ltr'
        style={{ float: "left" }}>
        {/* {mdmData ? ( */}
        {apk >= 22 ? (
          <>
            {/* {Policy == "policy0" || Policy == "menu5portrait" || Policy == "test" ? ( */}
            {type === "allDevices" && (window.location.href.includes(":3001") || window.location.hostname === "localhost") && (
              <label
                onClick={() =>
                  handleMDM({
                    action: `changeDevicePolicy`,
                    body: { serial: gsf, policyName: "dev" },
                  }).then(() => setPolicy("default"))
                }
                className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full hover:text-white px-2 py-1 flex justify-center items-center'>
                <FaDev />

              </label>
            )}
            <label
              onClick={() =>
                handleMDM({
                  action: `changeDevicePolicy`,
                  body: { serial: gsf, policyName: "default" },
                }).then(() => setPolicy("default"))
              }
              className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full hover:text-white px-2 py-1 flex justify-center items-center'>
              <i
                className='ri-lock-unlock-line'
                style={{ color: "green" }}></i>
            </label>
            {/* <div className="px-1">
              <label
                onClick={() =>
                  handleMDM({
                    action: `changeDevicePolicy`,
                    body: { serial: gsf, policyName: "menu5portrait" },
                  }).then(() => setPolicy("menu5portrait"))
                }
                className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full hover:text-white px-2 py-1 flex justify-center items-center'>
                <i
                  className='fal fa-tablet-android'
                  style={{ color: "red" }}></i>
              </label>

            </div> */}
            <label
              onClick={() =>
                handleMDM({
                  action: `changeDevicePolicy`,
                  body: { serial: gsf, policyName: "test" },
                }).then(() => setPolicy("test"))
              }
              className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full hover:text-white px-2 py-1 flex justify-center items-center'>
              <i
                className='ri-lock-line'
                style={{
                  color: "red"
                }}></i>
            </label>
            <label
              onClick={() =>
                handleMDM({
                  action: `reboot`,
                  body: { serial: gsf },
                })
              }
              className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full hover:text-white px-2 py-1 flex justify-center items-center'>
              <i
                className='ri-shut-down-line'
                style={{ color: "black" }}></i>
            </label>
            <label
              onClick={() =>
                handleMDM({
                  action: `clearCache`,
                  body: { serial: gsf },
                })
              }
              className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full px-2 py-1 flex justify-center items-center'>
              <PiBroomBold />
              {/* <i className="ri-refresh-line" style={{ color: "navy" }}></i> */}
            </label>
            {/* <label
              onClick={async () => {
                try {
                  await axios.post("/api/addTask", {
                    task: "refresh",
                    serial,
                  });
                  Swal.fire({
                    title: translatedWeb?.device?.refreshSended[clientlanguage],
                    icon: "success",
                    confirmButtonText: "OK",
                  });
                } catch (err) {
                  Swal.fire({
                    title: translatedWeb?.device?.error[clientlanguage]
                      + "  " + err,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                }
                // console.log(await getClosestRest());
              }}
              className='mr-0 text-xl text-sky-500 cursor-pointer hover:bg-slate-300 rounded-full hover:text-white px-2 py-1 flex justify-center items-center'>
              <i
                className='ri-refresh-line'
                style={{ color: "navy" }}></i>
            </label> */}
          </>
        ) : apk >= 22 ? (
          <div
            dir='ltr'
            style={{ width: "100%", textAlign: "center", color: "orange" }}>
            {translatedWeb?.device?.oldMdm[clientlanguage]}
          </div>
        ) : (
          <div style={{ width: "100%", textAlign: "center", color: "red" }}>
            {translatedWeb?.device?.apkNotSupported[clientlanguage]}
          </div>
        )}
        {/* <label
          onClick={() => navigate("/tools")}
          htmlFor="EditModal"
          className="mr-0 text-xl text-sky-500 cursor-pointer hover:bg-sky-600 rounded-full hover:text-white px-2 py-1 flex justify-center items-center"
        >
          <i className="ri-pencil-line"></i>
        </label> */}
      </td>
    </tr>
  );
}
