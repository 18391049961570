import React, { useState, useContext, useEffect, useRef } from "react";
import Spinner from "../Shared/Spinner";
import * as XLSX from "xlsx";
import { UserContext } from "../../global";
import { MapContainer, TileLayer, CircleMarker, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useNavigate } from "react-router-dom";
const Devices = () => {
  const navigate = useNavigate();
  const { rest, setRest, RestData, RestList, setRestList, DevicesList } =
    useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  const componentRef = useRef({});
  const { current: my } = componentRef;

  const getClosestRest = async (position) => {
    try {
      const deg2rad = (deg) => {
        return deg * (Math.PI / 180);
      };

      const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
        const R = 6371;
        const dLat = deg2rad(lat2 - lat1);
        const dLon = deg2rad(lon2 - lon1);
        const a =
          Math.sin(dLat / 2) ** 2 +
          Math.cos(deg2rad(lat1)) ** 2 * Math.sin(dLon / 2) ** 2;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        return d;
      };
      // const locations = [
      //   // ["4", 29.562569103387823, 34.95921073959103],
      //   ['198', 32.2777972, 34.8571326], // Netanya
      //   ['197', 32.069815680675184, 34.79390195500536],
      //   // ["3", 29.554142477927055, 34.94542021200524],
      // ];

      const [closestRest] = my.locations.sort((c1, c2) => {
        const [, lat1, lon1] = c1;
        const [, lat2, lon2] = c2;
        return (
          getDistanceFromLatLonInKm(position[0], position[1], lat1, lon1) -
          getDistanceFromLatLonInKm(position[0], position[1], lat2, lon2)
        );
      });
      console.log({ closestRest });
      console.log({ position });

      return closestRest ? { id: closestRest[0], name: closestRest[3] } : null;
    } catch { }
  };
  useEffect(() => {
    (async () => {
      try {
        const locationsFetch = await fetch("/locations.json", {
          method: "GET",
        });
        my.locations = await locationsFetch.json();
        setIsLoading(false);
      } catch { }
      // console.log(await getClosestRest());
    })();
  }, []);
  return !isLoading ? (
    // <div className="w-full h-screen mt-2" dir="ltr">
    <MapContainer
      center={[31.490647, 34.946592]}
      zoom={8}
      scrollWheelZoom={true}
      style={{ height: "100%", width: "100%", zIndex: 0, direction: "ltr" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {DevicesList?.filter((device) => device.location)?.map((device) => (
        <CircleMarker
          center={device.location}
          pathOptions={{ color: "red" }}
          radius={3}
          eventHandlers={{
            click: async () => {
              alert(
                // "קרוב למסעדה: " + (await getClosestRest(device.location)?.name)
                "Pair to restaurant" + device.rest
              );
              // navigate("/allDevices?search=" + device.serial);
            },
          }}
        >
          <Tooltip>{device.serial}</Tooltip>
        </CircleMarker>
      ))}
      {/* {my.locations.map((location) => {
        const rest = {
          id: location[0],
          name: location[3],
          location: [location[1], location[2]],
        };
        return (
          <CircleMarker
            center={rest.location}
            pathOptions={{ color: "navy" }}
            radius={2}
            eventHandlers={{
              click: async () => {
                // navigate("/allDevices?search=" + device.serial);
              },
            }}
          >
            <Tooltip>{rest.id + ": " + rest.name}</Tooltip>
          </CircleMarker>
        );
      })} */}
    </MapContainer>
  ) : null;
};

export default Devices;
