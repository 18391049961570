import React from "react";
import { useContext } from "react";
import { UserContext } from "../../global";

export default function Modal({ children, setModal, setItemData }) {
  const { clientlanguage, isRtl } = useContext(UserContext);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(0,0,0,0.6)",
        zIndex: 99,
      }}
      onClick={() => {
        setModal(false);
        setItemData({});
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{
          position: "relative",
          background: "#fff",
          boxShadow: "0 0 10px 0",
          borderRadius: "20px",
          padding: "20px",
          width: "40%",
          minWidth: "320px",
        }}
      >
        <a
          href
          className={`btn btn-sm btn-circle absolute ${isRtl ? "left-2" : "right-2"} top-2`}
          onClick={() => {
            setModal(false);
            setItemData({});
          }}
        >
          ✕
        </a>
        {children}
      </div>
    </div>
  );
}
