import React, { useState, useContext, useMemo } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { languages, UserContext } from "../../global";
import { MdImage, MdImageNotSupported } from "react-icons/md";
const Item = ({
  itemData,
  handleDelete,
  handleUpdate,
  update,
  setUpdate,
  Lang,
  Checklist,
  setChecklist
}) => {
  const { name, desc, price, categories, date_upd, id, isActive, img, changeBy, members } =
    itemData;
  const { rest, RestData, setRestData } = useContext(UserContext);
  const [IsDeleted, setIsDeleted] = useState(false);
  const [descState, setDescState] = useState(false);
  // const categories = RestData.menuItemsCat?.filter(
  //   (e) => e.menu_item_id === id
  // );
  const shorten = (overview, length = 5) => {
    if (!overview?.length) return "";
    let short = overview.split(" ");
    short = short.slice(0, length);
    short = short.join(" ");
    if (short !== overview) {
      if (short.slice(-1) === "," || short.slice(-1) === ".")
        short = short.slice(0, -1);
      short += "...";
    }
    return short;
  };
  const shortDesc = useMemo(() => shorten(desc[Lang]), [desc, Lang]);
  const none = <span style={{ color: "red" }}>X</span>;
  const yes = <span style={{ color: "green" }}>פעיל</span>;
  const no = <span style={{ color: "red" }}>לא פעיל</span>;
  const nothing = <span style={{ color: "red" }}>אין</span>;

  // const isActive = () => {
  //   let res = no;
  //   if (categories.length) {
  //     RestData.categories.every((e) => {
  //       if (e.isActive) {
  //         res = yes;
  //         return false;
  //       }
  //     });
  //   }
  //   return res;
  // };

  // const handleUpdate = async (item) => {
  //   setEdit((e) => !e);
  // };

  let toUpdate = Object.assign({}, itemData);
  toUpdate.name = name[Lang];
  toUpdate.desc = desc[Lang];

  // const categoriesToNames = (array) => {
  //   let string = "";
  //   // console.log({ RestData });
  //   array.map((categoryId) => {
  //     string +=
  //       RestData.categories.filter((item) => item.id == categoryId)[0].name[
  //         Lang
  //       ] + "\n";
  //   });
  //   return string;
  // };
  const categoriesToNames = (lang) => {
    let string = "";
    // console.log({ RestData });
    RestData.categories.map((category) => {
      if (category.order) {
        category.order.map((item) => {
          if (item.id == id) {
            if (string == "") string += category.name[lang]
            else
              string += "  " + category.name[lang];
            // string += " , " + category.name[lang];
            string += "\n";
          }
        });
      }
    });
    return string;
  };
  return (
    <tr className={"border-b-2 border-r-2 border-r-blue-500"} dir="rtl">
      <label
        className="contents cursor-pointer"
        htmlFor="EditModal"
        onClick={(e) => {
          // console.log(e.target.nodeName);
          // if (e.target.nodeName !== "TD") {
          //   e.preventDefault();
          //   return;
          // }
          handleUpdate(toUpdate);
        }}
      >
        <td>
          <input
            onClick={(e) => e.stopPropagation()}
            type="checkbox"
            checked={Checklist.includes(id)}
            onChange={() => {
              // if (!Checklist.includes(id)) {
              // console.log({ Checklist });
              setChecklist((e) => {
                if (!e.includes(id)) return [...e, id];
                else {
                  let itemIndex = e.indexOf(id);
                  let newArr = [...e];
                  newArr.splice(itemIndex, 1);
                  return newArr;
                }
              });
            }}
          />
        </td>
        <td>{id}</td>
        {/* <td>
          {name[Lang] || none} {Lang !== "h" && "(" + name["h"] + ")"}
        </td> */}
        <td>
          {/* <span style={{ color: "green", fontSize: "20px", padding: "-5px 3px", position: "absolute" }}>
            {members?.length > 0 && <i class="far fa-plus-octagon" style={{ color: " #63E6BE", }}></i>}
          </span> */}
          <span style={{ margin: "0 0vw" }} >
            {name[Lang] || none}
            <span style={{ marginTop: "0vw" }}>{Lang !== "h" && "(" + name["h"] + ")"}</span>
          </span>

        </td>
        <td
          onMouseEnter={() => shortDesc.includes("...") && setDescState(true)}
          onMouseLeave={() => setDescState(false)}
        >
          {shortDesc || none}
          {descState && <div className="hoverInTd">{desc[Lang]}</div>}
        </td>
        <td>{price || none}</td>
        <td style={{ color: img ? "green" : "red", fontSize: "20px" }}>
          {img ? <MdImage /> : <MdImageNotSupported />}
        </td>
        <td>
          <div style={{ margin: "0 0vw" }} >

            {(categoriesToNames(Lang) || nothing)}
            <div style={{ marginTop: "0vw" }}>{(Lang !== "h") && "(" + categoriesToNames("h") + ")"}</div>
          </div>

        </td>
        <td>{isActive ? yes : no}{(!isActive && changeBy) ? <small style={{ color: "rgb(153, 50, 204)" }}> {changeBy}</small> : null}</td>
        <td>{date_upd ? new Date(date_upd).toLocaleString("he-IL") : ""}</td>
        {/* <td className="hidden lg:flex px-0">
          <label
            onClick={() =>
              handleUpdate({ id, name: name[Lang], desc: desc[Lang], price })
            }
            htmlFor="EditModal"
            className="mr-0 text-xl text-sky-500 cursor-pointer hover:bg-sky-600 rounded-full hover:text-white px-2 py-1 flex justify-center items-center"
          >
            <i className={"ri-pencil-line"}></i>
          </label>

          <i
            onClick={() => handleDelete(name[Lang])}
            className="ri-delete-bin-7-line text-xl text-sky-500 cursor-pointer hover:bg-sky-600 rounded-full hover:text-white px-2 py-1"
          ></i>
        </td> */}
      </label>
    </tr>
  );
};

export default Item;
