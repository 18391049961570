import React, { useState, useContext } from "react";
import Spinner from "../Shared/Spinner";
import * as XLSX from "xlsx";
import { UserContext, languages, translatedWeb } from "../../global";

const Devices = () => {
  const { rest, setRest, RestData, RestList, setRestList, clientlanguage, isRtl } =
    useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);
  const [frame, setFrame] = useState("pos");

  const downloadExcel = (data, lang, addPrice = false) => {
    // return console.log({ data, lang });
    const worksheet = XLSX.utils.json_to_sheet(data);

    worksheet["!cols"] = [
      { wch: 6 },
      { wch: 40 },
      { wch: 40 },
      { wch: 80 },
      { wch: 80 },
    ]; if (addPrice) {
      worksheet["!cols"] = [
        { wch: 6 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 80 },
        { wch: 80 },
      ];
    }
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `${lang}${RestData.details.id}Uppsale.xlsx`);
  };

  const getLanguageData = (lang, showActiveItemOnly, addPrice = false) => {
    // let drinksCats = RestData.categories.filter((item) =>
    //   [136].includes(item.id)
    // )?.[0]?.catOrder;
    // let drinks = RestData.categories.filter((item) =>
    //   drinksCats.includes(item.id)
    // );
    // console.log({ drinks });

    // let origin = RestData.menuItems.filter(
    //   (item) =>
    //     drinks.filter((drinkSubCat) => drinkSubCat.order?.includes(item.id))
    //       ?.length
    // );
    let origin = RestData.menuItems;
    console.log({ showActiveItemOnly, origin });
    let data = [];
    let count = 0;
    origin.filter(item => { if (showActiveItemOnly) { return item?.isActive } return true }).map((item, i) => {
      count =
        count + item?.name?.[lang]?.split(" ").filter((e) => e !== "").length;
      count =
        count + item?.desc?.[lang]?.split(" ").filter((e) => e !== "").length;
      data[i] = {};
      data[i].id = item.id;
      data[i]["Name"] = item.name?.[lang];
      if (!addPrice) data[i]["Translated Name"] = "";
      data[i]["Description"] = item.desc?.[lang];
      if (!addPrice) data[i]["Translated Description"] = "";
      if (addPrice) data[i].price = item.price;
      data[i].type = "menuItems";
    });
    data.sort(function (a, b) {
      return a.id - b.id;
    });
    let origin2 = RestData.categories;
    let data2 = [];
    console.log({ origin2 });
    origin2.map((item, i) => {
      count =
        count + item?.name?.[lang]?.split(" ").filter((e) => e !== "").length;
      count =
        count + item?.desc?.[lang]?.split(" ").filter((e) => e !== "").length;
      data2[i] = {};
      data2[i].id = item.id;
      data2[i]["Name"] = item.name?.[lang];
      data2[i]["Translated Name"] = "";
      data2[i]["Description"] = item.desc?.[lang];
      data2[i]["Translated Description"] = "";
      data2[i].type = "categories";
    });
    data2.sort(function (a, b) {
      return a.id - b.id;
    });
    console.log({ count });
    return data.concat(data2);
  };
  return (
    <div className='w-full h-screen mt-2 '>
      <div className='mb-7 text '>
        <h1 class='m-4 px-3 text-3xl font-extrabold text-gray-900 dark:text-white  '>
          <span class='text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400'>
            Exports of menu files
          </span>
        </h1>

        <div className="" style={{ width: "65rem" }}>
          {Object.entries(languages)?.map(([key, value]) => {
            return (<label
              onClick={() => {
                downloadExcel(getLanguageData(key, true, true), key?.toUpperCase(), true);
              }}
              className={`inline-block mr-2 mt-2 px-5 py-2 rounded-lg cursor-pointer bg-sky-100 text-gray-700"
        } hover:bg-sky-500 hover:text-white`}>
              {translatedWeb?.tools?.exportMenuFile[clientlanguage]} {value}
            </label>)
          })}</div>

      </div>
      <label
        onClick={() => setFrame("pos")}
        htmlFor='addUpdateModal'
        className={`inline-block mr-2 px-5 py-2 rounded-lg ${frame === "pos"
          ? "bg-sky-500 text-white"
          : "cursor-pointer bg-sky-100 text-gray-700"
          } hover:bg-sky-500 hover:text-white`}>
        Pair POS
      </label>
      <label
        onClick={() => setFrame("menu")}
        htmlFor='addUpdateModal'
        className={`inline-block mr-2 px-5 py-2 rounded-lg ${frame === "menu"
          ? "bg-sky-500 text-white"
          : "cursor-pointer bg-sky-100 text-gray-700"
          } hover:bg-sky-500 hover:text-white`}>
        Pair menu 4
      </label>
      <label
        onClick={() => {
          downloadExcel(getLanguageData("h", false), "He");
        }}
        className={`inline-block mr-2 px-5 py-2 rounded-lg cursor-pointer bg-sky-100 text-gray-700"
        } hover:bg-sky-500 hover:text-white`}>
        {translatedWeb?.tools?.exportTranslationHeEn[clientlanguage]}
      </label>
      <label
        onClick={() => {
          downloadExcel(getLanguageData("e", false), "En");
        }}
        className={`inline-block mr-2 px-5 py-2 rounded-lg cursor-pointer bg-sky-100 text-gray-700"
        } hover:bg-sky-500 hover:text-white`}>

        {translatedWeb?.tools?.exportTranslationEnAll[clientlanguage]}
      </label>

      {isLoading && <Spinner />}
      {frame && (
        <iframe
          onLoad={() => setIsLoading(false)}
          className='mt-5 md:w-1/2 w-full h-full'
          src={`https://uppsale.herokuapp.com/tablet-bind-${frame}.html`}></iframe>
      )}
    </div>

  );
};

export default Devices;
