import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import arrow from "../../assets/images/arrow.png";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import EditModal from "../modals/EditModal";
import axios from "axios";
import Spinner from "../Shared/Spinner";
import Item from "./Item";
import Cat from "./Cat";
import Device from "./Device";
import Rest from "./Rest";
import Swal from "sweetalert2";
import { templateData, languages, UserContext, auth, translatedWeb } from "../../global";
import { useSearchParams } from "react-router-dom";
import { permission } from "../../global";
import User from "./User";
const Items = ({ type }) => {
  const { rest, RestData, getRestData, RestList, DevicesList, clientlanguage, isRtl, UsersList } =
    useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParam = searchParams.get("search");
  const [Edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [studentsData, setStudentsData] = useState([]); //all rest data
  const [update, setUpdate] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [options, setOptions] = useState(RestData?.groups?.map(item => {
    return { value: item.id, label: item.name }
  }));
  const [Checklist, setChecklist] = useState([]);
  const [ItemData, setItemData] = useState({});

  const [SearchValue, setSearchValue] = useState("");
  const [mdmDevices, setMdmDevices] = useState([]);
  const [filter, setFilter] = useState(null);
  const [OnlineDevices, setOnlineDevices] = useState([]);
  const [page, setPage] = useState(0);
  const [pageLength, setPageLength] = useState(50);
  const [Lang, setLang] = useState("h");
  const setPageBtn = (length) => {
    setPageLength(length);
    setPage(0);
  };

  const [TabitData, setTabitData] = useState(null);


  // let studentsData;
  // studentsData = studentsData.filter((e) =>
  //   JSON.stringify(e).toLowerCase().includes(SearchValue)
  // );
  // getting student data from server
  const getSource = () => {
    switch (type) {
      case "restaurants":
        return RestList;
      case "allDevices":
        return DevicesList;
      case "users":
        return UsersList;
      default:
        return RestData[type];
    }
  };

  const renderData = () => {
    setStudentsData(getSource());
    // switch (type) {
    //   case "restaurants":
    //     setStudentsData(RestList);
    //     break;
    //   case "allDevices":
    //     setStudentsData(DevicesList);
    //     break;
    //   default:
    //     setStudentsData(RestData[type]);
    //     break;
    // }
  };

  useEffect(() => {
    console.log("useEffect RestData");
    renderData();
  }, [RestData, DevicesList, RestList]);
  useEffect(() => {
    switch (type) {
      // type == "allDevices" || type === "restaurants" || type === "devices"
      case "allDevices":
        setShowInfo(true);
        break;
      case "restaurants":
        setShowInfo(true);
        break;
      case "devices":
        setShowInfo(true);
        break;
      case "users":
        setShowInfo(true);
        break;

      default:
        setShowInfo(false);
        break;
    }
  }, [type]);
  useEffect(() => {
    (async function () {
      setPage(0);
      setChecklist([]);
      setSearchValue(searchParam || "");
      if (type == "allDevices" || type == "devices") {
        await axios.get(`/mdm/getDevices`).then((res) => {
          // console.log({ res });
          setMdmDevices(res.data);
        });
      }
      renderData();
      setIsLoading(false);
    })();
  }, []);
  useEffect(() => {
    const renderTabit = async () => {
      if (type == "categories" || type == "menuItems") {
        let result = await axios.get(`/api/tabit/${rest}`);
        setTabitData(result?.data || null);
      }
    }
    renderTabit();
  }, [type, rest, RestData, RestList]);

  const categoriesToNames = (itemId) => {
    let string = "";
    // console.log({ RestData });
    RestData.categories.map((category) => {
      if (category.order) {
        category.order.map((item) => {
          if (item.id == itemId) {
            string += category.name[Lang] + "\n";
          }
        });
      }
    });
    // console.log({ string });
    return string;
  };

  useEffect(() => {
    console.log({ SearchValue });
    setFilter(null);
    // setIsLoading(false);
    let items = Array.from(getSource());
    let searchArr = [];
    for (let item of items) {
      searchArr.push({ ...item, categoriesName: categoriesToNames(item?.id) });
    }
    // console.log({ items });
    // const data = items.filter((b) =>  //search without categories
    const data = searchArr.filter((b) =>
      JSON.stringify(b).toLowerCase().includes(SearchValue.toLowerCase())
    );

    if (SearchValue.length) {
      setStudentsData(data);
      console.log({ data });
    } else {
      renderData();
    }
  }, [SearchValue, getSource()]);

  // useEffect(() => {
  //   // setStudentsData([]);
  //   console.log("type changed");
  // }, [type]);

  const refresh = () => {
    getRestData(setIsLoading, type);
    setChecklist([]);
  };

  useEffect(() => {
    // if (filter == null) {
    if (filter) {
      renderData();
    }
    //   return;
    // }
    switch (filter) {
      case "isDev":
        setStudentsData((e) => e.filter((e) => e.isDev == true));
        break;
      case "OldMdm":
        setStudentsData((e) => e.filter((e) => e?.apk != 23));
        break;
      case "gsf":
        setStudentsData((e) => e.filter((e) => e.gsf));
        break;
      case "mdm":
        setStudentsData((e) =>
          e.filter((e) => {
            const lowerSerial = ("" + e.gsf).toLowerCase();
            const mdmData = mdmDevices.find((device) =>
              device.name.endsWith(lowerSerial)
            );
            return mdmData;
          })
        );
        break;
      default:
        break;
    }
  }, [filter]);

  // handle delete students data

  const handleDelete = async (e) => {
    console.log(e.target);
    Swal.fire({
      title: `${translatedWeb?.items?.deleteItem[clientlanguage]}  ${ItemData.name}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: translatedWeb?.items?.yes[clientlanguage],
      cancelButtonText: translatedWeb?.items?.no[clientlanguage],
      direction: isRtl ? "rtl" : "ltr",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // await axios.put("/api/delete" + templateData[type].action.name, {
        //   id: ItemData[templateData[type].action.id],
        // });
        await axios.put("/api/delete", {
          auth,
          id: ItemData.id,
          type: templateData[type].action.name,
        });
        refresh();
      }
    });
  };


  const handleTranslate = async (e) => {
    Swal.fire({
      title: `לתרגם ${Checklist.length} פריטים?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: translatedWeb?.items?.yes[clientlanguage],
      cancelButtonText: translatedWeb?.items?.no[clientlanguage],
      direction: isRtl ? "rtl" : "ltr",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Step 1: Define your languages list
        const translateToLangB = RestData.details?.langs.filter(lang => lang !== Lang)
          .map(lang => ({
            displayName: translatedWeb?.language?.[lang][clientlanguage],
            targetLanguage: translatedWeb?.language?.[lang]["en"],
            newLangCode: lang,
          })); console.log({ translateToLangB });
        // Step 2: Use Swal.fire to show the languages list with checkboxes
        Swal.fire({
          title: translatedWeb?.items?.selectLanguages[clientlanguage],
          html: `
          <div id="languages-checkboxes" dir="${isRtl ? "rtl" : "ltr"}" style="text-align: ${isRtl ? "right" : "left"};">
          ${translateToLangB.map(lang => `
            <div>
            <input type="checkbox" id="${lang.newLangCode}" name="language" value="${lang.newLangCode}">
            <label for="${lang.newLangCode}">${lang.displayName}</label>
            </div>
            `).join('')}
            </div>
            `, preConfirm: () => {
            // Collect IDs of selected languages
            const selectedLangCodes = Array.from(document.querySelectorAll('#languages-checkboxes input[name="language"]:checked')).map(input => input.value);
            // Filter translateToLangB for selected languages
            const selectedLanguages = translateToLangB.filter(lang => selectedLangCodes.includes(lang.newLangCode)).map(lang => ({
              newLangCode: lang.newLangCode,
              targetLanguage: lang.targetLanguage
            }));
            return selectedLanguages;
          },
          confirmButtonText: 'Ok',
        }).then(async (result) => {
          if (result.isConfirmed && result.value) {
            // Update the LANG variable with selected languages
            const LANGB = result.value;
            // console.log({ LANGB }); // Now LANG contains the selected languages with newLangCode and targetLanguage
            setIsLoading(true);
            const auth = window.localStorage.getItem("password");
            const translateToLang = RestData.details?.langs.filter(lang => lang !== Lang)
              .map(lang => ({
                targetLanguage: translatedWeb?.language?.[lang]["en"],
                newLangCode: lang,
              }));
            const newValue = {
              auth,
              type,
              rest,
              Checklist,
              lang: Lang,
              translateToLang: LANGB,
            };
            await axios.post(`/api/translateItems`,
              newValue

            ).then((res) => {
              console.log({ restranslateItems: res });
              refresh();
              // setIsLoading(false);
              // renderData();
              // res.status === 200 && window.location.reload();
            }).catch((err) => {
              Swal.fire({
                title: "Error: " + err,
                icon: "error",
                confirmButtonText: "OK",

              });
            });
          }
        });

        // return;

        // refresh();
        // if (type === "menuItems") {
        // } else {
        // }
      }
    });
  };
  // handleUpdate
  const handleUpdate = async (item) => {
    setItemData(item);
    setEdit("update");
  };

  const handleMDM = async (data) => {
    let res = "The action was successfully sent";
    try {
      await axios.post(`/mdm/${data.action}`, data.body);
      if (data.body?.policyName) {
        if (data.body?.policyName == "policy0" || data.body?.policyName == "test" || data.body?.policyName == "menu5portrait") {
          res = `<i class="fad fa-lock"></i> ${translatedWeb?.items?.lockedOnPolicy[clientlanguage]} `;
        } else {
          res = ` <i class="fad fa-lock-open-alt"></i> ${translatedWeb?.items?.deviceOpen[clientlanguage]}`;
        }
      } else if (data?.action == "clearCache")
        res = translatedWeb?.items?.clearCechSucces[clientlanguage];
      else if (data?.action == "reboot") res = translatedWeb?.items?.restartsended[clientlanguage];

      Swal.fire({
        title: res,
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (err) {
      Swal.fire({
        title: "Error: " + err,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const langsFiltered = useMemo(() => {
    return Object.keys(languages).filter(
      (e) => (RestData?.details?.langs?.includes(e) || e === "h") && Lang !== e
    );
  }, [RestData, Lang]);
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <section className='manage_student mt-3'>
      <div className='add_student flex justify-between w-3/4'>

        <div className='ml-3 add flex justify-center items-center'>
          <span className='ml-5'>{/* <img src={arrow} alt="/arrow" /> */}</span>
          {/* //88888 Display all languge */}
          {/* {templateData[type].buttons.lang && ( */}
          <div className='dropdown dropdown-bottom dropdown-end'>
            <label
              tabIndex={0}
              className='flex md:inline cursor-pointer ml-1 my-0 px-5 pt-[12px] pb-[13.5px] bg-purple-500 hover:bg-purple-600 rounded-lg text-white'>
              {translatedWeb?.items?.Language[clientlanguage]}: {languages[Lang]}
            </label>

            {/* {langsFiltered.length > 0 && ( */}
            {/* {langsFiltered.length > 0 && ( */}
            <ul
              tabIndex={0}
              className='dropdown-content menu p-2 mt-4 shadow bg-base-100 rounded-box w-32'>
              {Object.keys(languages).map((e) => {
                return (
                  <li>
                    <a
                      className={"active:bg-slate-500"}
                      onClick={() => setLang(e)}>
                      {languages?.[e]}
                    </a>
                  </li>
                );
              })}
            </ul>
            {/* )} */}
          </div>

          {/* )} */}
          {templateData[type].buttons.display && (
            <div className='dropdown dropdown-bottom dropdown-end hidden md:table-cell'>
              <label
                tabIndex={0}
                className='h-[70px] inline cursor-pointer ml-1 my-0 px-5 pt-[12px] pb-[13.5px] bg-sky-500 hover:bg-sky-600 rounded-lg text-white'>
                {translatedWeb?.items?.show[clientlanguage]}: {pageLength}
              </label>
              <ul
                tabIndex={0}
                className='dropdown-content menu p-2 mt-4 shadow bg-base-100 rounded-box w-32'>
                <li>
                  <a
                    className={"active:bg-slate-500"}
                    onClick={() => setPageBtn(10)}>
                    10
                  </a>
                </li>
                <li>
                  <a
                    className={"active:bg-slate-500"}
                    onClick={() => setPageBtn(20)}>
                    20
                  </a>
                </li>
                <li>
                  <a
                    className={"active:bg-slate-500"}
                    onClick={() => setPageBtn(50)}>
                    50
                  </a>
                </li>
                <li>
                  <a
                    className={"active:bg-slate-500"}
                    onClick={() => setPageBtn(100)}>
                    100
                  </a>
                </li>
                <li>
                  <a
                    className={"active:bg-slate-500"}
                    onClick={() => setPageBtn(300)}>
                    300
                  </a>
                </li>
              </ul>
            </div>
          )}
          {templateData[type].buttons.add && (
            <div
              className={"tooltip tooltip-right"}
              data-tip={`${translatedWeb?.items?.adding[clientlanguage]} ${templateData[type].singular}`}>
              <label
                onClick={() => setEdit("add")}
                htmlFor='EditModal'
                className='text-xl table-caption md:inline ml-1 px-4 pt-[10.5px] pb-[8px] cursor-pointer bg-orange-500 hover:bg-orange-600 rounded-lg text-white'>
                {/* הוספת {templateData[type].singular} */}

                <i className={"ri-menu-add-line"} />
              </label>
            </div>
          )}
          {/* {type == "allDevices" && templateData[type].buttons.filter && (
            <>
              <div
                className={"hidden md:table-cell tooltip tooltip-right"}
                data-tip="Old devices">
                <label
                  onClick={() => setFilter("OldMdm")}
                  className='text-xl table-caption md:inline ml-1 px-4 pt-[10.5px] pb-[8px] cursor-pointer bg-slate-500 hover:bg-slate-600 rounded-lg text-white'>
                  <i class="far fa-exclamation-triangle" />

                </label>
              </div>
              <div
                className={"hidden md:table-cell tooltip tooltip-right"}
                data-tip='Developers'>
                <label
                  onClick={() => setFilter("isDev")}
                  className='text-xl table-caption md:inline ml-1 px-4 pt-[10.5px] pb-[8px] cursor-pointer bg-slate-500 hover:bg-slate-600 rounded-lg text-white'>
                  <i className={"ri-code-line"} />
                </label>
              </div>
              <div
                className={"hidden md:table-cell tooltip tooltip-right"}
                data-tip='GSF'>
                <label
                  onClick={() => setFilter("gsf")}
                  className='text-xl table-caption md:inline ml-1 px-4 pt-[10.5px] pb-[10px] cursor-pointer bg-slate-500 hover:bg-slate-600 rounded-lg text-white'>
                  GSF
                </label>
              </div>
              <div
                className={"hidden md:table-cell tooltip tooltip-right"}
                data-tip='MDM'>
                <label
                  onClick={() => setFilter("mdm")}
                  className='text-xl table-caption md:inline ml-1 px-4 pt-[10.5px] pb-[10px] cursor-pointer bg-slate-500 hover:bg-slate-600 rounded-lg text-white'>
                  MDM
                </label>
              </div>
            </>
          )} */}
          {templateData[type].buttons.refresh && (
            <div
              className={"hidden md:table-cell tooltip tooltip-right m-1"}
              data-tip={translatedWeb?.items?.refresh[clientlanguage]}>
              <label
                onClick={() => refresh()}
                className='text-xl px-4 pt-[10px] pb-[8px] cursor-pointer bg-red-500 hover:bg-red-600 rounded-lg text-white'>
                <i className={"ri-refresh-line"} />
              </label>
            </div>
          )}
        </div>
        {/* Only For Admin can copy item */}
        {Checklist.length > 0 && permission == "all" && (
          <div className={`ml-3 add flex justify-center items-center fixed bottom-[20px] ${isRtl ? "left-0" : "right-0"} z-50 bg-black`}>
            <div className='text-xs table-caption md:block p-4 cursor-pointer rounded-lg text-white'>
              {Checklist.length > 1
                ? Checklist.length + translatedWeb?.items?.itemsSelected[clientlanguage]
                : translatedWeb?.items?.oneSelected[clientlanguage]}
            </div>
            {type == "categories" && (
              <>

                <div
                  onClick={async () => {
                    let restToCopy = prompt("Copy to restaurant number:");
                    // let listOfItems = [];
                    // const { value: copyName } = await Swal.fire({
                    //   inputValue: "(העתק)",
                    //   input: "text",
                    //   inputLabel: "האם אם ברצונך להוסיף שם להעתקה? (לא חובה)",
                    //   title: "אם תשאיר/י ריק- לא יתווסף שום שם למנות/קטגוריות שנבחרו להעתקה \n חל רק על הפריטים החדשים",
                    //   showClass: {
                    //     popup: `      
                    //     animate__animated
                    //     animate__fadeInUp
                    //     animate__faster`
                    //   },
                    //   hideClass: {
                    //     popup: `
                    //     animate__animated
                    //     animate__fadeOutDown
                    //     animate__faster`
                    //   }
                    // });
                    if (restToCopy === null || restToCopy < 0) return;
                    Swal.fire({
                      title: `copy ${Checklist.length} Items to a restaurant ${restToCopy}?`,
                      icon: "question",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: translatedWeb?.items?.yes[clientlanguage],
                      cancelButtonText: translatedWeb?.items?.no[clientlanguage],
                      direction: isRtl ? "rtl" : "ltr",
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        setIsLoading(true);
                        // let itemsToCopy = studentsData.filter((item) =>
                        //   Checklist.includes(item.id)
                        // );
                        // console.log({ itemsToCopy });
                        axios.post(`/api/copy`, {
                          auth,
                          rest: restToCopy,
                          categoriesToCopy: Checklist,
                          // copyName,
                        }).then((res) => {
                          // console.log({ res });
                          res.status === 200 && window.location.reload();
                        }).catch((err) => {
                          Swal.fire({
                            title: "Error: " + err,
                            icon: "error",
                            confirmButtonText: "OK",

                          });
                        });
                        // refresh();
                        // if (type === "menuItems") {
                        // } else {
                        // }
                      }
                    });
                  }}
                  className='text-l table-caption md:block p-4 cursor-pointer rounded-lg text-white'>
                  {translatedWeb?.items?.copy[clientlanguage]}
                </div>
                <div
                  onClick={async (e) => { handleTranslate(e) }}
                  className='text-l table-caption md:block p-4 cursor-pointer rounded-lg text-white'>
                  תרגום
                </div></>
            )}
            {type == "menuItems" && (
              <>

                <div
                  onClick={async () => {
                    let restToCopy = prompt("Restaurant number:");
                    // let listOfItems = [];
                    if (restToCopy === null || restToCopy < 0) return;

                    const { value: copyName } = await Swal.fire({
                      inputValue: "(copy)",
                      input: "text",
                      inputLabel: "Do you want to add a copy there? (Optional)",
                      title: "If you leave empty-no name will be added to dishes/categories selected for copying \n only applies to the new items",
                      showClass: {
                        popup: `      
                      animate__animated
                      animate__fadeInUp
                      animate__faster`
                      },
                      hideClass: {
                        popup: `
                      animate__animated
                      animate__fadeOutDown
                      animate__faster`
                      }
                    });
                    // const { value: addTags } = await Swal.fire({
                    //   title: "Do you want to copy tags there?",
                    //   text: "Optional",
                    //   icon: "question",
                    //   showCancelButton: true,
                    //   confirmButtonColor: "#3085d6",
                    //   cancelButtonColor: "#d33",
                    //   confirmButtonText: "Yes",
                    //   cancelButtonText: "No",
                    //   showClass: {
                    //     popup: `      
                    //       animate__animated
                    //       animate__fadeInUp
                    //       animate__faster`
                    //   },
                    //   hideClass: {
                    //     popup: `
                    //       animate__animated
                    //       animate__fadeOutDown
                    //       animate__faster`
                    //   }
                    // });

                    console.log({ copyName });
                    Swal.fire({
                      title: `copy ${Checklist.length} dishes to a restaurant ${restToCopy}?`,
                      icon: "question",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: translatedWeb?.items?.yes[clientlanguage],
                      cancelButtonText: translatedWeb?.items?.no[clientlanguage],
                      direction: isRtl ? "rtl" : "ltr",
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        setIsLoading(true);
                        let items = studentsData.filter((item) =>
                          Checklist.includes(item.id)
                        );
                        if (type === "menuItems") {
                          for (const item of items) {
                            let itemToCopy = { ...item };
                            delete itemToCopy._id;
                            // delete itemToCopy.id;
                            delete itemToCopy.date_upd;
                            itemToCopy.rest = parseInt(restToCopy);
                            itemToCopy.lang = Lang;
                            itemToCopy.type = "Item";
                            itemToCopy.isCopy = item.id;
                            itemToCopy.auth = auth;
                            itemToCopy.copyName = copyName;
                            // if (item?.tags) itemToCopy.addTags = addTags;
                            await axios
                              .post(`/api/add`, itemToCopy)
                              .then((res) => {
                                // console.log({ res });
                                res.status === 200 && window.location.reload();
                              }).catch((err) => {
                                Swal.fire({
                                  title: "Error: " + err,
                                  icon: "error",
                                  confirmButtonText: "OK",

                                });
                              });
                          }
                          // await Promise.all(
                          //   studentsData
                          //     .filter((item) => Checklist.includes(item.id))
                          //     .map(async (item) => {
                          //     })
                          // );
                        }
                        refresh();
                      }
                    });
                  }}
                  className='text-l table-caption md:block p-4 cursor-pointer rounded-lg text-white'>
                  {translatedWeb?.items?.copy[clientlanguage]}
                </div><div
                  onClick={async (e) => { handleTranslate(e) }}
                  className='text-l table-caption md:block p-4 cursor-pointer rounded-lg text-white'>
                  תרגום
                </div></>
            )}
            <div
              onClick={async () => {
                Swal.fire({
                  title: `Remove ${Checklist.length} Items?`,
                  icon: "question",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: translatedWeb?.items?.yes[clientlanguage],
                  cancelButtonText: translatedWeb?.items?.no[clientlanguage],
                  direction: isRtl ? "rtl" : "ltr",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    setIsLoading(true);
                    if (type === "menuItems") {
                      await Promise.all(
                        studentsData
                          .filter((item) => Checklist.includes(item.id))
                          .map(async (item) => {
                            await axios.put("/api/delete", {
                              auth,
                              id: item.id,
                              type: "Item",
                            });
                          })
                      );
                    } else {
                      await Promise.all(
                        studentsData
                          .filter((cat) => Checklist.includes(cat.id))
                          .map(async (category) => {
                            await axios.put("/api/delete", {
                              auth,
                              id: category.id,
                              type: "Cat",
                            });
                          })
                      );
                    }
                    refresh();
                  }
                });
              }}
              className='text-l table-caption md:block p-4 cursor-pointer rounded-lg text-white'>
              {translatedWeb?.items?.remove[clientlanguage]}
            </div>
          </div>
        )}
        <div className='justify-between items-center mx-5 mb-1 flex absolute md:relative top-2 left-0'>
          {/* <div className="left_arrow"> */}
          {/* <i className='ri-arrow-left-s-fill text-2xl text-gray-400 rotate-180 rounded-full border cursor-pointer'></i> */}
          {/* </div> */}
          <div className='search flex justify-center items-center'>
            <div className='font-sans text-black bg-white flex items-center justify-center'>
              <div className='border rounded-lg overflow-hidden flex' style={{ flexDirection: isRtl ? "row-reverse" : "row" }}>
                <input
                  onChange={(e) => {
                    setPage(0);
                    setSearchValue(e.target.value);
                  }}
                  value={SearchValue}
                  type='text'
                  className='px-4 py-2 outline-0'
                  placeholder={translatedWeb?.items?.SearchPoints[clientlanguage]}
                />
                {SearchValue.length > 0 ? (
                  <button
                    className='flex items-center justify-center px-4'
                    onClick={() => {
                      setSearchValue('');
                      setPage(0);
                    }}
                  >
                    <svg
                      className='h-4 w-4 text-grey-dark'
                      fill='currentColor'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'>
                      <path d='M10 9.586l4.293-4.293a1 1 0 1 1 1.414 1.414L11.414 11l4.293 4.293a1 1 0 0 1-1.414 1.414L10 12.414l-4.293 4.293a1 1 0 1 1-1.414-1.414L8.586 11 4.293 6.707a1 1 0 0 1 1.414-1.414L10 9.586z' />
                    </svg>
                  </button>
                ) : (
                  <div className='flex items-center justify-center px-4'>
                    {/* Search Icon */}
                    <svg
                      className='h-4 w-4 text-grey-dark'
                      fill='none'
                      stroke='currentColor'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <h2 className='text-2xl font-bold pr-5 mt-2 md:mt-3'>
          {SearchValue &&
            !studentsData.length &&
            translatedWeb?.items?.notFound[clientlanguage] + templateData[type].name}
          {SearchValue &&
            studentsData.length === 1 &&
            translatedWeb?.items?.found[clientlanguage] + templateData[type].singular + translatedWeb?.items?.one[clientlanguage]}
          {studentsData.length > 1 &&
            studentsData.length +
            " " +
            templateData[type].name +
            (SearchValue && translatedWeb?.items?.founds[clientlanguage])}
        </h2>
      </div>
      {/* students table  */}
      {/* <Table>
        <Thead className='border'>
          <Tr className='text-left'>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Phone</Th>
            <Th>Enroll Number</Th>
            <Th>Date of admission</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr className='border'>
            <Td>Nazmul Hassan</Td>
            <Td>developer.nazmul@gmail.com</Td>
            <Td>+8801790-868092</Td>
            <Td>24641564</Td>
            <Td>9 April 2019</Td>
            <Td>
                <span>DE</span>
                <span>Up</span>
            </Td>
          </Tr>
        </Tbody>
      </Table> */}
      <div className='scrollingContainer overflow-x-auto mt-5'>
        {/* {console.log({ ABC: type == "allDevices" || type === "restaurants", type })} */}
        {/* {showInfo &&
          <div className="px-5 py-2"><div>
            explanation:           <small>
              Standing condition
              <bold style={{ fontWeight: "bold" }}> As Restaurant 111 </bold></small><i

                className='fal fa-tablet-android '
                style={{ color: "red" }}></i>
            <div className="px-2"> </div>
            <small>regular mode<bold style={{ fontWeight: "bold" }}> Like Japanika </bold></small><i
              className='ri-lock-line'
              style={{
                color: "red"
              }}></i></div></div>} */}

        <table
          style={{
            tableLayout: "auto",
            borderCollapse: "collapse",
            width: "min-content",
            direction: isRtl ? "rtl" : "ltr",
          }}
          dir={isRtl ? 'rtl' : "ltr"}
          className='table table-compact w-full text-right'
        // style={{ whitespace-nowrap}}
        >
          <thead className='opacity-70'>
            <tr>
              {(type === "menuItems" || type === "categories") && (
                <th
                  className='rounded-none'
                  style={{ textAlign: "center" }}>
                  <input
                    type='checkbox'
                    checked={Checklist.length === studentsData.length}
                    onChange={() => {
                      // return;
                      let checkAllArr = [];
                      studentsData.map((item) => checkAllArr.push(item.id));
                      setChecklist((e) =>
                        e.length === studentsData.length ? [] : checkAllArr
                      );
                    }}
                  />
                </th>
              )}
              {templateData[type].titles.map((title) => (
                <th className='rounded-none'>{title}</th>
              ))}
              {/* <th className="rounded-none">{templateData[type].titles[0]}</th>
              <th className="rounded-none">{templateData[type].titles[1]}</th>
              <th className="rounded-none">{templateData[type].titles[2]}</th>

              {templateData[type].titles.length > 3 && (
                <th className={"rounded-none hidden lg:table-cell"}>
                  {templateData[type].titles[3]}
                </th>
              )}
              {templateData[type].titles.length > 4 && (
                <th className={"rounded-none hidden lg:table-cell"}>
                  {templateData[type].titles[4]}
                </th>
              )}
              {templateData[type].titles.length > 5 && (
                <th className={"rounded-none hidden lg:table-cell"}>
                  {templateData[type].titles[5]}
                </th>
              )}
              {templateData[type].titles.length > 6 && (
                <th className={"rounded-none hidden lg:table-cell"}>
                  {templateData[type].titles[6]}
                </th>
              )}
              {templateData[type].titles.length > 7 && (
                <th className={"rounded-none hidden lg:table-cell"}>
                  {templateData[type].titles[7]}
                </th>
              )}
              {templateData[type].titles.length > 8 && (
                <th className={"rounded-none hidden lg:table-cell"}>
                  {templateData[type].titles[8]}
                </th>
              )} */}
            </tr>
          </thead>
          <tbody>
            {studentsData
              .slice(page * pageLength, page * pageLength + pageLength)
              .map((itemData) => {
                return type === "menuItems" ? (
                  <Item
                    Lang={Lang}
                    itemData={itemData}
                    key={itemData.id}
                    handleDelete={handleDelete}
                    handleUpdate={handleUpdate}
                    update={update}
                    setUpdate={setUpdate}
                    Checklist={Checklist}
                    setChecklist={setChecklist}

                  />
                ) : type === "categories" ? (
                  <Cat
                    Lang={Lang}
                    itemData={itemData}
                    key={itemData.id}
                    handleDelete={handleDelete}
                    handleUpdate={handleUpdate}
                    update={update}
                    setUpdate={setUpdate}
                    Checklist={Checklist}
                    setChecklist={setChecklist}
                  />
                ) : type === "devices" ? (
                  <Device
                    mdmDevices={mdmDevices}
                    type={type}
                    Lang={Lang}
                    itemData={itemData}
                    key={itemData.serial}
                    handleDelete={handleDelete}
                    handleUpdate={handleUpdate}
                    handleMDM={handleMDM}
                    update={update}
                    setUpdate={setUpdate}
                    isOnline={JSON.stringify(OnlineDevices).includes(
                      itemData.serial
                    )}
                  />
                ) : type === "allDevices" ? (
                  <Device
                    mdmDevices={mdmDevices}
                    type={type}
                    Lang={Lang}
                    itemData={itemData}
                    key={itemData.serial}
                    handleDelete={handleDelete}
                    handleUpdate={handleUpdate}
                    handleMDM={handleMDM}
                    update={update}
                    setUpdate={setUpdate}
                    isOnline={JSON.stringify(OnlineDevices).includes(
                      itemData.serial
                    )}
                  />
                ) : type === "users" ? (
                  <User
                    Lang={Lang}
                    itemData={itemData}
                    key={itemData.row_id}
                    handleDelete={handleDelete}
                    handleUpdate={handleUpdate}
                    update={update}
                    setUpdate={setUpdate}
                    options={options}
                  />
                ) : type === "restaurants" ? (
                  <Rest
                    Lang={Lang}
                    itemData={itemData}
                    key={itemData.row_id}
                    handleDelete={handleDelete}
                    handleUpdate={handleUpdate}
                    update={update}
                    setUpdate={setUpdate}
                  />
                ) : null;
              })}
          </tbody>
        </table>
      </div>
      {
        page > 0 && (
          <label
            onClick={() => setPage((e) => e > 0 && e - 1)}
            className='select-none inline-block my-5 mx-2 px-5 py-2 cursor-pointer bg-sky-500 hover:bg-sky-600 rounded-lg text-white'>
            {translatedWeb?.items?.prevPage[clientlanguage]}
          </label>
        )
      }
      <span className={"inline-block p-5"}>{translatedWeb?.items?.page[clientlanguage]} {page + 1}</span>
      {
        studentsData.length > pageLength && (
          <label
            onClick={() =>
              setPage(
                (e) => e < Math.floor(studentsData.length / pageLength) && e + 1
              )
            }
            className='select-none inline-block my-5 mx-2 px-5 py-2 cursor-pointer bg-sky-500 hover:bg-sky-600 rounded-lg text-white'>
            {translatedWeb?.items?.nextPage[clientlanguage]}
          </label>
        )
      }
      {
        Edit && (
          <EditModal
            Lang={Lang}
            rest={rest}
            setIsLoading={setIsLoading}
            type={type}
            templateData={templateData[type]}
            refresh={refresh}
            ItemData={ItemData}
            setItemData={setItemData}
            key={new Date().getTime()}
            Edit={Edit}
            setEdit={setEdit}
            studentsData={studentsData}
            handleDelete={handleDelete}
            TabitData={type === "categories" ? TabitData?.categories : type === "menuItems" ? TabitData?.items : null}
          />
        )
      }
    </section >
  );
};

export default Items;
