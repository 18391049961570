import React, { useEffect, useState, useContext, useRef } from "react";
import Select from "react-select";
import { ReactSortable } from "react-sortablejs";

const EditArray = ({ type, OrderState, setOrderState, OrderOptions, limetToOne, suggestedName, filterOptions }) => {
  const [suggestedLabel, setsuggestedLabel] = useState(null);
  // const [OrderState, setOrderState] = useState(orderStateDefault || []);
  // const [OrderOptions, setOrderOptions] = useState(orderOptionsDefault || []);
  // useEffect(() => {
  //   console.log({ OrderState, OrderOptions });
  //   // setOrderState(orderStateDefault);
  // }, [OrderState, OrderOptions]);

  const getSuggestedLabel = (suggestedName, OrderOptions) => {
    if (!suggestedName || !OrderOptions.length) return null;

    const closestMatch = OrderOptions?.reduce((prev, curr) => {
      const prevDistance = Math.abs(suggestedName?.length - prev?.label?.length);
      const currDistance = Math.abs(suggestedName?.length - curr?.label?.length);
      return prevDistance < currDistance ? prev : curr;
    });

    return closestMatch || null;
  };

  useEffect(() => {
    setsuggestedLabel(suggestedName ? getSuggestedLabel(suggestedName, OrderOptions) : null);
  }, [OrderOptions]);
  return (
    <>
      {!(limetToOne && OrderState.length >= 1) &&
        <Select
          onChange={(e) => {
            setOrderState([...OrderState, e.value]);
          }}
          options={OrderOptions.filter((option) => {
            return !OrderState.includes(option.value);
          })}
          placeholder="בחרו מהרשימה"
          // isMulti
          value={suggestedLabel ?? null}
          defaultValue={suggestedLabel ?? null}
          isRtl
          noOptionsMessage={() => "אין אפשרויות"}
          isDisabled={limetToOne && OrderState.length >= 1}
        />}
      <ReactSortable
        list={OrderState}
        setList={setOrderState}
        animation={200}
        handle=".my-handle"
      >
        {OrderState?.map((item, i) => (
          <div
            key={i}
            style={{
              cursor: "row-resize",
              padding: "10px",
              margin: "5px 0",
              border: "1px solid",
              borderRadius: "5px",
              position: "relative",
            }}
          >
            {limetToOne !== true && <span
              className="my-handle"
              style={{
                fontSize: "24px",
                position: "absolute",
                padding: "0 10px",
                top: 0,
                right: 0,
              }}
            >
              ↕
            </span>}
            <span className="my-handle" style={{ padding: "0 20px 0 0" }}>
              {type === "items"
                ? OrderOptions.filter(
                  (e) => e.value === item.id || e.value?.id === item.id
                )[0]?.label
                : OrderOptions.filter((e) => e.value == item)[0]?.label}
            </span>
            <span
              style={{
                color: "red",
                cursor: "pointer",
                float: "left",
                width: "24px",
                textAlign: "center",
              }}
              onClick={() =>
                setOrderState((e) => e.filter((elem, index) => index !== i))
              }
              onTouchEnd={() =>
                setOrderState((e) => e.filter((elem, index) => index !== i))
              }
            >
              X
            </span>
            {type === "items" ? (
              <>
                <span
                  style={{
                    color: item.isActive === false ? "black" : "green",
                    cursor: "pointer",
                    float: "left",
                    position: "relative",
                    margin: "0 0 0 10px",
                  }}
                  onClick={() => {
                    let result = [...OrderState];
                    result[i].isActive = Boolean(item.isActive === false);
                    setOrderState(result);
                  }}
                >
                  {item.isActive === false ? "לא פעיל" : "פעיל"}
                </span>
                <span
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    float: "left",
                    padding: "0 10px",
                  }}
                  onClick={(e) => {
                    let price = prompt("מחיר:");
                    // let result = OrderState.map((el) =>
                    //   el.id == item.id ? { ...el, price } : el
                    // );
                    let result = [...OrderState];
                    result[i].price = price;
                    setOrderState(result);
                  }}
                >
                  {item.price || "₪"}
                </span>
              </>
            ) : null}
          </div>
        ))}
      </ReactSortable>
    </>
  );
};

export default EditArray;
