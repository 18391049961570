import React, { useEffect, useState, useContext, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../Shared/Spinner";
import UploadModal from "../modals/UploadModal";
import Swal from "sweetalert2";
import HomeCategories from "../modals/HomeCategories";
import { templateData, languages, UserContext, permission, translatedWeb } from "../../global";

const colors = ["blue", "green", "red", "yellow", "purple", "pink", "orange"];

const Settings = () => {
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(false);
  // const [Upload, setUpload] = useState(false);
  const [Modal, setModal] = useState();
  const [SettingsData, setSettingsData] = useState({});
  const { rest, RestData, clientlanguage } =
    useContext(UserContext);

  const { version, template } = RestData["details"];
  // const [Screensaver, setScreensaver] = useState(template?.screensaver);
  // const [Popup, setPopup] = useState(template?.popup);
  // const [PopupVideo, setPopupVideo] = useState(template?.VideoPopup);
  // const [LangsBtn, setLangsBtn] = useState(template?.langs);
  const checkIfModular = () => {
    // alert("hhhh")
  };
  const homeComponents = [
    {
      name: "Popup",
      title: translatedWeb?.mainHome?.mainScreenComp?.popup[clientlanguage],
      icon: "fal fa-ad",
    },
    {
      name: "PopupVideo",
      title: translatedWeb?.mainHome?.mainScreenComp?.popupVideo[clientlanguage],
      icon: "fal fa-film",
    },
    {
      name: "BirkatHamazon",
      title: translatedWeb?.mainHome?.mainScreenComp?.birkatHamazon[clientlanguage],
      icon: "fal fa-book-open",
    },
    {
      name: "Video",
      title: translatedWeb?.mainHome?.mainScreenComp?.video[clientlanguage],
      icon: "fal fa-video",
    },
    {
      name: "HomeLogo",
      title: translatedWeb?.mainHome?.mainScreenComp?.homeLogo[clientlanguage],
      icon: "fal fa-image",
    },
    {
      name: "LangBtn",
      title: translatedWeb?.mainHome?.mainScreenComp?.langBtn[clientlanguage],
      icon: "ri-global-line",
    },
    {
      name: "Btn",
      title: translatedWeb?.mainHome?.mainScreenComp?.btn[clientlanguage],
      icon: "ri-window-line",
    },
    {
      name: "Btn2",
      title: translatedWeb?.mainHome?.mainScreenComp?.btn2[clientlanguage],
      icon: "ri-window-line",
    },
    {
      name: "Btn3",
      title: translatedWeb?.mainHome?.mainScreenComp?.btn3[clientlanguage],
      icon: "fal fa-stars",
    },
    {
      name: "Btn4",
      title: translatedWeb?.mainHome?.mainScreenComp?.btn4[clientlanguage],
      icon: "ri-window-line",
    },
    {
      name: "Btn5",
      title: translatedWeb?.mainHome?.mainScreenComp?.btn5[clientlanguage],
      icon: "fad fa-gift-card",
    },
    {
      name: "Btn6",
      title: translatedWeb?.mainHome?.mainScreenComp?.btn6[clientlanguage],
      icon: "fal fa-file-certificate",
    },
    {
      name: "SwipeComponent",
      title: translatedWeb?.mainHome?.mainScreenComp?.swipeComponent[clientlanguage],
      // smallTitle: " קטגוריה אחת  בראשי",
      icon: "fad fa-arrow-alt-circle-down",
    },
    {
      title: translatedWeb?.mainHome?.mainScreenComp?.bigAd[clientlanguage],
      name: "bigAd",
      icon: "fad fa-border-left",
      restActive: [106, 1, 35]
    },
    {
      title: translatedWeb?.mainHome?.mainScreenComp?.disableBisBtn[clientlanguage],
      name: "disableBisBtn",
      icon: "fad fa-border-left",
      restActive: [35]
    }, {
      name: "onlyOneBigPic",
      title: translatedWeb?.mainHome?.mainScreenComp?.onlyOneBigPic[clientlanguage],
      icon: "fad fa-images",

    }, {
      title: translatedWeb?.mainHome?.mainScreenComp?.AccessibleMode[clientlanguage],
      name: "AccessibleMode",
      icon: "fas fa-universal-access",
    }, {
      title: translatedWeb?.mainHome?.mainScreenComp?.restNameDiv[clientlanguage],
      name: "restNameDiv",
      icon: "fad fa-code",
    },
    // {
    //   name: "setBisBtn",
    //   title: translatedWeb?.mainHome?.mainScreenComp?.setBis[clientlanguage],
    //   icon: "fas fa-burger-soda",
    // },
  ];

  const homeComponentsMobile = [

    {
      name: "BirkatHamazon",
      title: "birkat hamazon",
      icon: "fal fa-book-open",
    },
    {
      name: "LangBtn",
      title: "Language button",
      icon: "ri-global-line",
    },
    {
      name: "Btn",
      title: "Customer Club / Photo open",
      icon: "ri-window-line",
    },
    {
      name: "Btn2",
      title: "Job Openings - Btn2",
      icon: "ri-window-line",
    },
    {
      name: "Btn3",
      title: "Btn3 Reviews",
      icon: "fal fa-stars",
    },
    {
      name: "Btn4",
      title: "Btn4 Events",
      icon: "ri-window-line",
    },
    {
      name: "Btn5",
      title: "Btn5 Gift Card",
      icon: "fad fa-gift-card",
    },
    {
      name: "Btn6",
      title: "Btn6 Kosher certificate",
      icon: "fal fa-file-certificate",
    },

  ];
  const categoriesComponents = [
    {
      name: "Video",
      title: translatedWeb?.mainHome?.categoryScreen?.video[clientlanguage],
      icon: "fal fa-video",
    },
    {
      name: "MenuMode",
      title: translatedWeb?.mainHome?.categoryScreen?.menuMode[clientlanguage],
      icon: "fal fa-video",
    },
  ];
  const itemsComponents = [
    {
      name: "defaultImage",
      title: translatedWeb?.mainHome?.dishesScreen?.defaultImage[clientlanguage],
      icon: "fal fa-images",
    },
    {
      name: "ShowShekel",
      title: translatedWeb?.mainHome?.dishesScreen?.showShekel[clientlanguage],
      icon: "fad fa-shekel-sign",
    },
    {
      name: "LangBtn",
      title: translatedWeb?.mainHome?.dishesScreen?.langBtn[clientlanguage],
      icon: "ri-global-line",
    },
  ];
  const mealComponents = [
    {
      name: "MealIconAllergen",
      title: translatedWeb?.mainHome?.mealScreen?.mealIconAllergen[clientlanguage],
      icon: "fal fa-allergies",
    },
    {
      name: "ShowShekel",
      title: translatedWeb?.mainHome?.mealScreen?.showShekel[clientlanguage],
      icon: "fad fa-shekel-sign",
    },
    {
      name: "Details",
      title: translatedWeb?.mainHome?.mealScreen?.details[clientlanguage],
      icon: "fal fa-info",
      trueByDefault: true,
    },
    // {
    //   name: "DisableDetailsBtn",
    //   title: "הסתרת כפתור פרטי מנה",
    //   icon: "fas fa-eye-slash",
    // },
    {
      name: "LangBtn",
      title: translatedWeb?.mainHome?.mealScreen?.langBtn[clientlanguage],
      icon: "ri-global-line",
    },
    {
      name: "companyLogo",
      title: translatedWeb?.mainHome?.mealScreen?.companyLogo[clientlanguage],
      icon: "fab fa-pied-piper-square",
    },
  ];
  // getting student data from server
  useEffect(() => {
    // getRestData(setIsLoading);
    // (async function () {
    //   await axios
    //     .get("/restData.json")
    //     .then((res) => setSettingsData(res.data));
    //   setIsLoading(false);
    // })();
  }, []);
  // const refresh = () => {
  //   getRestData(setIsLoading, "settings");
  // };
  if (IsLoading) {
    return <Spinner />;
  }
  return (
    <section className='welcome_dashboard mx-3 mt-5'>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 mb-4'>
        <div
          className='cursor-pointer student_info p-5 rounded-lg text-white bg-pink-500'
          onClick={() => navigate("/items")}>
          <div className='students'>
            <i className={`ml-2 text-5xl ri-restaurant-line`}></i>
            <p className='opacity-70 my-1'>{translatedWeb?.mainHome?.main?.dishes[clientlanguage]}</p>
          </div>
          <div className='count flex justify-end'>
            <span className='text-xl font-bold'>
              {RestData.menuItems?.length}
            </span>
          </div>
        </div>
        <div
          className='cursor-pointer student_info p-5 rounded-lg text-white bg-yellow-500'
          onClick={() => navigate("/categories")}>
          <div className='students'>
            <i className={`ml-2 text-5xl ri-file-list-line`}></i>
            <p className='opacity-70 my-1'>{translatedWeb?.mainHome?.main?.categories[clientlanguage]}</p>
          </div>
          <div className='count flex justify-end'>
            <span className='text-xl font-bold'>
              {RestData.categories?.length}
            </span>
          </div>
        </div>
        <div
          className='cursor-pointer student_info p-5 rounded-lg text-white bg-purple-500'
          onClick={() => navigate("/devices")}>
          <div className='students'>
            <i className={`ml-2 text-5xl ri-tablet-line`}></i>
            <p className='opacity-70 my-1'>{translatedWeb?.mainHome?.main?.devices[clientlanguage]}</p>
          </div>
          <div className='count flex justify-end'>
            <span className='text-xl font-bold'>
              {RestData.devices?.length}
            </span>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3'>
        <div
          className={
            "cursor-pointer student_info p-5 rounded-lg text-white bg-green-500"
          }
          onClick={() => setModal("homeCat")}>
          <div className='students'>
            <i className={`ml-2 text-5xl ri-tablet-line`}></i>
            <p className='my-1'> {translatedWeb?.mainHome?.main?.mainCat[clientlanguage]}</p>
          </div>
          <div className='count flex justify-end'>
            <span className='text-base font-bold mx-1'>
              {template?.homeCategories?.length || 0}
            </span>
          </div>
        </div>
        <div
          className={
            "cursor-pointer student_info p-5 rounded-lg text-white bg-red-500"
          }
          onClick={() => setModal("restImages")}>
          <div className='students'>
            <i className={`ml-2 text-5xl ri-gallery-line`}></i>
            <p className='my-1'>{translatedWeb?.mainHome?.main?.mainImages[clientlanguage]}</p>
          </div>
          {/* <div className="count flex justify-end">
            <span className="text-base font-bold mx-1">&nbsp;</span>
          </div> */}
        </div>
        <div
          className={
            "cursor-pointer student_info p-5 rounded-lg text-white bg-blue-500"
          }>
          <div
            onClick={async () => {
              Swal.fire({
                title: "Update a version?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await axios
                    .get(`/api/updateDataVersion/${rest}`)
                    .then((res) => {
                      res.status === 200 && window.location.reload();
                      // Swal.fire({
                      //   title: "גרסה עודכנה",
                      //   icon: "success",
                      //   confirmButtonText: "בסדר",
                      // });
                    });
                }
              });
            }}>
            <div className='students'>
              <i className={`ml-2 text-5xl ri-tablet-line`}></i>
              <p className='my-1'>{translatedWeb?.mainHome?.main?.version[clientlanguage]}</p>
            </div>
            <div className='count flex justify-end'>
              <span className='text-base font-bold mx-1'>{version}</span>
            </div>
          </div>
        </div>
      </div>
      <br />
      <h2 className='text-xl my-2'>{translatedWeb?.mainHome?.mainScreenComp?.title[clientlanguage]}</h2>
      <div
        className='mt-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3'
        onClick={() => {
          checkIfModular();
        }}>
        {homeComponents.map(
          ({ name, title, color, icon, trueByDefault, smallTitle, restActive }, i) => {
            const options = template?.components?.home?.[name];
            const bool = options?.show;
            const apiName = "template.components.home." + name;
            const dataObj = {};
            if (restActive) { if (!restActive.includes(parseInt(rest))) return <></>; }
            return (
              <>
                <div>
                  <Switch
                    data={{
                      options,
                      trueByDefault,
                      icon,
                      title,
                      apiName, smallTitle,
                      color: colors[i % colors.length],
                    }}
                  />
                </div>
              </>
            );
          }
        )}
      </div>
      <br />
      <h2 className='text-xl my-2'> {translatedWeb?.mainHome?.categoryScreen?.title[clientlanguage]}</h2>
      <div className='mt-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3'>
        {categoriesComponents.map(
          ({ name, title, color, icon, trueByDefault }, i) => {
            const options = template?.components?.categories?.[name];
            const bool = options?.show;
            const apiName = "template.components.categories." + name;
            const dataObj = {};
            return (
              <Switch
                data={{
                  options,
                  trueByDefault,
                  icon,
                  title,
                  apiName,
                  color: colors[(i + 2) % colors.length],
                }}
              />
            );
          }
        )}
      </div>
      <br />
      <h2 className='text-xl my-2'>  {translatedWeb?.mainHome?.dishesScreen?.title[clientlanguage]}</h2>
      <div className='mt-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3'>
        {itemsComponents.map(
          ({ name, title, color, icon, trueByDefault }, i) => {
            const options = template?.components?.items?.[name];
            const bool = options?.show;
            const apiName = "template.components.items." + name;
            const dataObj = {};
            return (
              <Switch
                data={{
                  options,
                  trueByDefault,
                  icon,
                  title,
                  apiName,
                  color: colors[(i + 4) % colors.length],
                }}
              />
            );
          }
        )}
      </div>
      <br />
      <h2 className='text-xl my-2'> {translatedWeb?.mainHome?.mealScreen?.title[clientlanguage]}</h2>
      <div className='mt-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3'>
        {mealComponents.map(
          ({ name, title, color, icon, trueByDefault }, i) => {
            const options = template?.components?.meal?.[name];
            const apiName = "template.components.meal." + name;
            return (
              <Switch
                data={{
                  options,
                  trueByDefault,
                  icon,
                  title,
                  apiName,
                  color: colors[(i + 6) % colors.length],
                }}
              />
            );
          }
        )}
      </div>
      <br />
      <h2 className='text-xl my-2'> {translatedWeb?.mainHome?.mobileScreen?.title[clientlanguage]}</h2>
      <div className='mt-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3'>
        <p> {translatedWeb?.mainHome?.mobileScreen?.moveToAnotherScreen[clientlanguage]} </p>
        {/* {homeComponentsMobile.map(
          ({ name, title, color, icon, trueByDefault }, i) => {
            const options = template?.mobile?.components?.[name];
            const apiName = "template.mobile.components." + name;
            return (
              <Switch
                data={{
                  options,
                  trueByDefault,
                  icon,
                  title,
                  apiName,
                  color: colors[(i + 6) % colors.length],
                }}
              />
            );
          }
        )} */}
      </div>
      {/* {Modal === "screensaver" && (
        <UploadModal
          data={{
            Title: "שומר מסך למסעדת " + RestData.details.name,
            Type: 1,
            setModal,
            rest,
            path: "Screensaver/" + rest,
            setIsLoading,
            key: new Date().getTime(),
            isMultiple: true,
            numericName: true,
          }}
        />
      )} */}
      {/* {Modal === "PopupVideo" && (
        <UploadModal
          data={{
            Title: "פופאפ ווידאו למסעדה " + RestData.details.name,
            Type: 1,
            setModal,
            rest,
            path: "RestImages/" + rest,
            setIsLoading,
            key: new Date().getTime(),
            isMultiple: true,
            numericName: true,
          }}
        />
      )} */}
      {Modal === "restImages" && (
        <UploadModal
          data={{
            Title: "Main Images to the Restaurant " + RestData.details.name,
            Type: 1,
            setModal,
            rest,
            path: "RestImages/" + rest,
            setIsLoading,
            key: new Date().getTime(),
            isMultiple: true,
          }}
        />
      )}
      {Modal === "homeCat" && (
        <HomeCategories
          Lang={"h"}
          rest={rest}
          setIsLoading={setIsLoading}
          refresh={() => window.location.reload()}
          setModal={setModal}
          key={new Date().getTime()}
        />
      )}
      {Modal === "component" &&
      {
        /* <ComponentOptions
    Lang={"h"}
    rest={rest}
    setIsLoading={setIsLoading}
    type={"Cat"}
    templateData={templateData["categories"]}
    refresh={() => window.location.reload()}
    setModal={setModal}
    key={new Date().getTime()}
  /> */
      }}
    </section>
  );
};

const Switch = ({
  data: { title, color, apiName, icon, trueByDefault, options, smallTitle },
}) => {
  const { clientlanguage } = useContext(UserContext);

  const [IsLoading, setIsLoading] = useState(false);
  const [IsOptions, setIsOptions] = useState(false);
  const [OptionsValue, setOptionsValue] = useState(JSON.stringify(options));
  // useEffect(() => setOptionsValue(JSON.stringify(options)), [options]);

  const { rest } = useContext(UserContext);
  let bool = options?.show;
  if (bool !== false && bool !== true && trueByDefault) {
    bool = true;
  }
  const [BoolState, setBoolState] = useState(Boolean(bool));
  const sendAction = useCallback(async () => {
    setIsLoading(true);
    const dataObj = {};
    dataObj[apiName + ".show"] = !BoolState;
    try {
      await axios.put("/api/settings", {
        rest: parseInt(rest),
        data: dataObj,
      });
      setIsLoading(false);
      setBoolState((e) => !e);
    } catch (err) { }
  }, []);
  return (
    <div className={`cursor-pointer p-5 rounded-lg text-white bg-${color}-500`}>
      {IsLoading ? (
        <div className={"h-[100px] flex justify-center items-center"}>
          <Spinner small />
        </div>
      ) : (
        <div
          onClick={() => {
            // if (clickable) {
            setIsOptions((e) => !e);
            // }
          }}>
          <div className='students'>
            <i className={`ml-2 text-5xl ` + icon}></i>
            <p className='my-1'>{title}</p>
            <small>{smallTitle}</small>
          </div>
          {IsOptions && (
            <div className='options'>
              <textarea
                dir='ltr'
                style={{ color: "#000" }}
                defaultValue={OptionsValue}
                onChange={(evt) => setOptionsValue(evt.currentTarget.value)}
                onClick={(e) => e.stopPropagation()}
              />
              <div
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  const dataObj = {};
                  setIsLoading(true);
                  try {
                    dataObj[apiName] = JSON.parse(OptionsValue);
                    await axios.put("/api/settings", {
                      rest: parseInt(rest),
                      data: dataObj,
                    });
                    setIsLoading(false);
                  } catch (err) { }
                }}>
                Update
              </div>
            </div>
          )}
          <div
            className='count flex justify-end mt-2'
            onClick={(e) => e.stopPropagation()}>
            <input
              type='checkbox'
              className={
                "toggle " + (BoolState ? "bg-green-500" : "bg-red-500")
              }
              defaultChecked={BoolState}
              onChange={() => sendAction()}
            />
            <span className='text-base font-bold mx-1'>
              {BoolState ? translatedWeb?.preferences?.on[clientlanguage] : translatedWeb?.preferences?.off[clientlanguage]}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default Settings;
