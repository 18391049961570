import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../Shared/Spinner";
import axios from "axios";
import Swal from "sweetalert2";
import { useContext } from "react";
import { UserContext, translatedWeb } from "../../global";



const Login = () => {
  const { clientlanguage, isRtl } = useContext(UserContext);

  const [passwordShown, setPasswordShown] = useState(false);
  // const [signInWithEmailAndPassword, user, loading, error] =
  // useSignInWithEmailAndPassword(auth)

  // Redirect location after login successful
  const navigate = useNavigate();
  const location = useLocation();
  let from = location.state?.from?.pathname || "/";
  // if (user) {
  //   navigate(from, { replace: true })
  // }
  // if (loading) {
  //   return <Spinner />
  // }

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  // handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    const password = e.target.password.value;

    try {
      const res = await axios.post("/api/auth", { auth: password });
      console.log({ resbc: res.data });

      if (res.data && res.data.key) {
        const { key, language } = res.data;
        window.localStorage.setItem("permission", key);

        if (key.startsWith('group ')) {
          const rest = key.split(" ")[1];
          window.localStorage.setItem("defaultRest", rest);
        }

        window.localStorage.setItem("language", language);
        window.localStorage.setItem("password", password);

        document.location.href = "/";
      } else {
        throw new Error('Authentication failed');
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: translatedWeb?.login?.error[clientlanguage],
        text: translatedWeb?.login?.invalidData[clientlanguage],
      });
    }
  };

  return (
    <div
      style={{
        backgroundImage:
          "linear-gradient(71.17deg, #FEAF00 19.35%, #F8D442 90.12%)",
      }}
      className="px-8 login h-screen flex justify-center items-center"
    >
      <div className="login_form w-96 mx-auto px-8 py-8 bg-white rounded-lg">
        <h2 className="relative text-center text-2xl font-bold">
          <span className="bg-yellow-400 w-1 h-6 top-1/2 bottom-1/2 my-auto inline-block absolute right-0"></span>{" "}
          {translatedWeb?.login?.title[clientlanguage]}
        </h2>
        {/* input form  */}
        <form onSubmit={handleLogin}>
          {/* <div className="form-control">
            <label className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              type="text"
              placeholder="email"
              className="input input-bordered"
              name="email"
              required
            />
          </div> */}
          <div className="form-control">
            <label className="label">
              {/* <span className="label-text">Password</span> */}
            </label>
            <div className="password_toggler relative" dir="ltr">
              <input
                type={passwordShown ? "text" : "password"}
                placeholder="password"
                className="input input-bordered w-full"
                name="password"
                required
              />
              <span
                onClick={togglePassword}
                className="absolute right-5 top-2 text-2xl"
              >
                {passwordShown ? (
                  <i className="ri-eye-line"></i>
                ) : (
                  <i className="ri-eye-off-line"></i>
                )}
              </span>
            </div>
          </div>
          <p>
            <small></small>
          </p>
          <div className="form-control mt-6">
            <button className="bg-yellow-500 py-2 rounded text-white">
              {translatedWeb?.login?.login[clientlanguage]}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
