import axios from "axios";
import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import Swal from "sweetalert2";
import Select from "react-select";
import { languages, timeZonesWithUTC, translatedWeb, UserContext } from "../../global";
import EditArray from "../Shared/EditArray";
import UploadFiles from "../Shared/UploadFiles";
import UploadModal from "./UploadModal";
import { ReactSortable } from "react-sortablejs";
import Modal from "./Modal";

const EditModal = ({
  Edit,
  setEdit,
  ItemData,
  setItemData,
  studentsData,
  handleUpdateStudent,
  refresh,
  templateData,
  type,
  setIsLoading,
  rest,
  Lang,
  handleDelete,
  TabitData
}) => {
  // const { menu_item_id } = updatedValue;
  const passwordRef = useRef();
  const priceRef = useRef();
  const nameRef = useRef();

  const [ImgSrc, setImgSrc] = useState();
  const [GallerySrc, setGallerySrc] = useState();

  const [IsPromotion, SetIsPromotion] = useState(ItemData?.name);
  const [Page, SetPage] = useState(0);
  const [Upload, setUpload] = useState(false);
  const [ImgVersion, setImgVersion] = useState(new Date().getTime());
  // const [MainImg, setMainImg] = useState();
  const { RestData, clientlanguage, isRtl } = useContext(UserContext);
  const id = ItemData.id;
  const isAdd = Edit === "add";
  // let options = [];
  // let selectedOptions = [];
  // let currentCategories = [];



  const [OrderState, setOrderState] = useState([]);
  const [popupCat, setpopupCat] = useState(ItemData?.popupCat || false);
  const [HeaderImageBtn, setHeaderImageBtn] = useState(ItemData?.HeaderImageBtn?.image || false);
  const [tabitSync, setTabitSync] = useState(ItemData?.tabit?.enableSync ?? false);
  const [CategoriesOrderState, setCategoriesOrderState] = useState([]);
  const [CategoriesNavigationState, setCategoriesNavigationState] = useState([]);
  // const [ShowAlertAtCat, setShowAlertAtCat] = useState([]);
  const [IconsState, setIconsState] = useState([]);
  const [TagState, setTagState] = useState([]);
  const [LangsState, setLangsState] = useState([]);
  const [LangsDefaultState, setLangsDefaultState] = useState([]);
  const [TabitIdState, setTabitIdState] = useState([]);
  const [TZDefaultState, setTZDefaultState] = useState(["Asia/Jerusalem"]);

  const [OrderOptions, setOrderOptions] = useState([]);
  const [CategoriesOrderOptions, setCategoriesOrderOptions] = useState([]);
  const [OrderStateLangsOptions, setOrderStateLangsOptions] = useState([]);
  const [OrderStateTZOptions, setOrderStateTZOptions] = useState(timeZonesWithUTC || []);
  // console.log({ tabitSync, ItemDataTabit: ItemData?.tabit, name: ItemData?.tabit?.sync?.name });
  const [toggles, setToggles] = useState({
    status: ItemData?.tabit?.sync?.status ?? true,
    name: ItemData?.tabit?.sync?.name ?? true,
    desc: ItemData?.tabit?.sync?.desc ?? true,
    price: ItemData?.tabit?.sync?.price ?? true,
    photo: ItemData?.tabit?.sync?.photo ?? true,
    item: ItemData?.tabit?.sync?.item ?? true,
    topping: ItemData?.tabit?.sync?.topping ?? true,
    translate: ItemData?.tabit?.sync?.translate ?? true,
  });
  const [showItemIfNotAvailable, setShowItemIfNotAvailable] = useState(ItemData?.tabit?.showItemIfNotAvailable ?? false);

  const [IconsOptions, setIconsOptions] = useState([
    { value: "peanuts", label: translatedWeb?.editModal?.contains_peanuts[clientlanguage] },
    { value: "nuts", label: translatedWeb?.editModal?.contains_nuts[clientlanguage] },
    { value: "sesame", label: translatedWeb?.editModal?.contains_sesame[clientlanguage] },
    { value: "fish", label: translatedWeb?.editModal?.contains_fish[clientlanguage] },
    { value: "milk", label: translatedWeb?.editModal?.contains_milk[clientlanguage] },
    { value: "egg", label: translatedWeb?.editModal?.contains_eggs[clientlanguage] },
    { value: "soy", label: translatedWeb?.editModal?.contains_soy[clientlanguage] },
    { value: "sea", label: translatedWeb?.editModal?.contains_seefood[clientlanguage] },
    { value: "gluten", label: translatedWeb?.editModal?.contains_gluten[clientlanguage] },
    { value: "spicy", label: translatedWeb?.editModal?.spicy[clientlanguage] },
    { value: "piquant", label: translatedWeb?.editModal?.piquant[clientlanguage] },
    { value: "vegetarian", label: translatedWeb?.editModal?.vegetarian[clientlanguage] },
    { value: "vegan", label: translatedWeb?.editModal?.vegan[clientlanguage] },
    { value: "nogluten", label: translatedWeb?.editModal?.gluten_Free[clientlanguage] },
    { value: "mustard", label: translatedWeb?.editModal?.contains_mustard[clientlanguage] },
  ]);

  const [TagsOptions, setTagsOptions] = useState([]);
  const [RestUserLang, setRestUserLang] = useState(["he"]);
  const [RestUserLangOtion, setRestUserLangOtion] = useState([{ value: "en", label: "English" }, { value: "he", label: "Hebrew" }]);
  const languagesArr = useMemo(() => {
    let res = [];
    Object.keys(languages).map((lang) =>
      res.push({ value: lang, label: languages[lang] })
    );
    return res;
  }, [languages]);
  const [LangsOptions, setLangsOptions] = useState(languagesArr);

  // let branchOptions = [];
  // let branchSelectedOptions = [];


  const [BoolState, setBoolState] = useState(false);
  // const [options, setOptions] = useState(false);
  const [OptionValue, setOptionValue] = useState(null);

  const [ToppingSelected, setToppingSelected] = useState([]);

  const generatePassword = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
    // const words = ["dad", "mom", "bob", "web", "uppsale"];
    let password = '';
    for (let i = 0; i < 6; i++) {
      password += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    // const randomWord = words[Math.floor(Math.random() * words.length)];
    // return password + randomWord;
    return password;
  }
  const setNewuserPass = () => {
    let newPass = generatePassword();
    // if (!UserData?.rest?.startsWith('group ')) {
    // newPass += UserData?.rest ? UserData?.rest : "";
    // }
    passwordRef.current.value = newPass;

  }
  useEffect(() => {
    setImgSrc(ItemData.img);
    setGallerySrc(ItemData.gallery);
    if (type === "categories") {
      // setMainImg(ItemData.main_img || "");
      let order = [];
      ItemData.order?.map((item) => {
        if (typeof item === "object") {
          order.push({
            id: item.id,
            price: item.price || "",
            isActive: item.isActive,
          });
        } else {
          order.push({
            id: item,
            price: "",
            isActive: true,
          });
        }
      });
      setOrderState(order);
      setCategoriesOrderState(ItemData.catOrder || []);
      // let CatAlertItem = [];
      // CatAlertItem = ItemData.alertCat
      // setShowAlertAtCat(CatAlertItem != undefined ? CatAlertItem : []);
      let categoriesOrderOptions = [];
      RestData.categories?.map((item) =>
        categoriesOrderOptions.push({
          value: item.id,
          label:
            item.name.h + " (" + (categoriesToNames(item.id) || "ראשי") + ")",
        })
      );
      setCategoriesOrderOptions(categoriesOrderOptions);
      let orderOptions = [];
      RestData.menuItems?.map((item) =>
        orderOptions.push({
          value: { id: item.id, price: "" },
          label: item.name.h,
        })
      );
      setOrderOptions(orderOptions);

      const tags = RestData.details?.tags;
      const modifiedTagsOptions = tags?.map(tag => ({
        value: tag.id,
        label: tag?.[Lang],
      }));
      setTagsOptions(modifiedTagsOptions | []);
    }
    if (type === "menuItems") {
      const tags = RestData.details.tags;
      const modifiedTagsOptions = tags?.map(tag => ({
        value: tag.id,
        label: tag?.[Lang],
      }));
      setTagsOptions(modifiedTagsOptions || []);

      let orderOptions = [];
      RestData.menuItems?.map((item) =>
        orderOptions.push({
          value: { id: item.id, price: "" },
          label: item.name.h,
        })
      );
      setTagState(ItemData.tags || []);
      setOrderOptions(orderOptions);
      setIconsState(ItemData.icons || []);
      let CategoriesNavigat = [];
      CategoriesNavigat[0] = ItemData.navigation
      setCategoriesNavigationState(CategoriesNavigat[0] != undefined ? CategoriesNavigat : []);
      let categoriesOrderOptions = [];
      RestData.categories?.map((item) =>
        categoriesOrderOptions.push({
          value: item.id,
          label:
            item.name.h + " (" + (categoriesToNames(item.id) || translatedWeb?.editModal?.main[clientlanguage]) + ")",
        })
      );
      setCategoriesOrderOptions(categoriesOrderOptions);

    }
    if (type === "restaurants") {
      setLangsState(ItemData.langs || []);
      setLangsDefaultState(ItemData.defaultLangs || []);
      setTZDefaultState(ItemData.defaultTimeZone || ["Asia/Jerusalem"] || []);

    } else if (type === "menuItems" || type === "categories") {
      let selectedTabitCategoryIds = TabitData?.filter(item => parseInt(item.uppsaleID) == parseInt(ItemData.id))
        .map(item => item.tabitId);
      setTabitIdState(selectedTabitCategoryIds || []);
      // if (toggles?.topping) {
      setToppingSelected(ItemData?.members || []);

      // }
    }
    if (type === "users") {
      setRestUserLang(ItemData?.language ? [ItemData?.language] : ["he"]);
      setBoolState(ItemData?.rest?.startsWith('group ') ? true : false);
      setOptionValue(ItemData?.rest?.startsWith('group ') ? ItemData?.rest?.split("group ")?.[1] : null);
    }
    console.log({ type });
  }, [ItemData]);

  useEffect(() => {
    setOrderStateLangsOptions(LangsOptions.filter(item => LangsState.includes(item.value)))
  }, [LangsOptions, LangsState]);

  // const scrollToBottom = () => {
  //   orderRef.current?.scrollIntoView({ behavior: "smooth" });
  // };

  // useEffect(() => {
  //   if (OrderState.length === 0) return;
  //   scrollToBottom();
  // }, [OrderState]);

  // if (RestData.details.branches && templateData.action.name === "Item") {
  //   Object.entries(RestData.details.branches).map((item, i) =>
  //     branchOptions.push({
  //       value: parseInt(item[0]),
  //       label: item[1],
  //     })
  //   );
  //   ItemData.branches?.map((id) =>
  //     branchSelectedOptions.push({
  //       value: id,
  //       label: branchOptions.filter((e) => e.value === id)[0]?.label,
  //     })
  //   );
  // }
  if (
    templateData.action.name === "Item" ||
    templateData.action.name === "Cat"
  ) {
    // RestData.categories?.map((item) =>
    //   options.push({
    //     value: item.id,
    //     label:
    //       item.name.h +
    //       (item.categories && item.categories.length
    //         ? " (" +
    //           RestData.categories.filter(
    //             (category) => item.categories[0] == category.id
    //           )[0].name.h +
    //           ")"
    //         : ""),
    //   })
    // );
    // ItemData.categories?.map((id) =>
    //   selectedOptions.push({
    //     value: id,
    //     label: options.filter((e) => e.value === id)[0]?.label,
    //   })
    // );
  }
  const handleToggleChange = (toggleName) => {
    setToggles((prevToggles) => ({
      ...prevToggles,
      [toggleName]: !prevToggles[toggleName],
    }));
  };

  // const handleCheckboxChange = (id) => {
  //   setToppingSelected((prevToppings) => {
  //     const updatedToppings = prevToppings.map((topping) => {
  //       if (topping.id === id) {
  //         return { ...topping, selected: !topping.selected };
  //       }
  //       return topping;
  //     });
  //     return updatedToppings;
  //   });
  // };

  const categoriesToNames = (id) => {
    let string = "";
    // console.log({ RestData });
    RestData.categories.every((category) => {
      if (category.catOrder?.includes(id)) {
        string += category.name[Lang];
        return false;
      }
      return true;
    });
    return string;
  };
  const CustomLabel = ({ content }) => {
    return (
      <div className="md:w-1/3">
        <label className={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
          {content}
        </label>
      </div>
    );
  };
  if (templateData.action.name === "Cat") {
    // RestData.menuItems?.map((item) =>
    //   orderOptions.push({
    //     value: { id: item.id, price: "" },
    //     label: item.name.h,
    //   })
    // );
    // RestData.categories?.map((item) =>
    //   categoriesOrderOptions.push({
    //     value: item.id,
    //     label:
    //       item.name.h + " (" + (categoriesToNames(item.id) || "ראשי") + ")",
    //   })
    // );
    // ItemData.order?.map((id) =>
    //   orderSelectedOptions.push({
    //     value: id,
    //     label: orderOptions.filter((e) => e.value === id)[0]?.label,
    //   })
    // );
  }
  // const { item_name_h, item_desc_h } = ItemData;
  const handleAddReq = async (e) => {
    // let isCopy = e?.target?.name?.isCopy;
    // e.preventDefault();
    setIsLoading(true);
    const auth = window.localStorage.getItem("password");
    const name = e?.target?.name?.value;
    const desc = e?.target?.desc?.value;
    const price = e?.target?.price?.value;
    // const navigation = CategoriesNavigationState;
    // const alertCat = ShowAlertAtCat;
    // const langs = e?.target?.langs?.value;
    // const icons = e?.target?.icons?.value;
    const preventClick = e?.target?.preventClick?.checked;
    const alertCat = e?.target?.alertCat?.checked;
    const popupCat = e?.target?.popupCat?.checked;
    const img = e?.target?.imgBool?.value;
    const gallery = e?.target?.galleryBool?.value;
    const points = e?.target?.points?.value;
    const newItemIcon = e?.target?.newItem?.checked;
    // let order = e?.target?.order?.value;
    // let istitle = name?.includes("באנר ");
    // let istitle = e?.target?.istitle?.checked;
    // const main_img = e?.target?.main_img?.value;
    // let categories = [];
    // if (!order) {
    //   order = [];
    // }
    // let branches = [];

    // if (selectedOptions.length) {
    //   selectedOptions?.map((e) => categories.push(e.value));
    // }
    // if (orderSelectedOptions.length) {
    //   orderSelectedOptions?.map((e) => order.push(e.value));
    // }
    // if (branchSelectedOptions.length) {
    //   branchSelectedOptions?.map((e) => branches.push(e.value));
    // }
    // else {
    //   currentCategories.map((e) => categories.push(e.value));
    // }
    const newValue = {
      auth,
      // isCopy,
      type: templateData.action.name,
      rest,
      id,
      name,
      desc,
      price,
      newItem: newItemIcon,
      preventClick,
      alertCat,
      popupCat,
      img,
      gallery,
      tags: TagState,
      // order: OrderState,
      // branches,
      // isTitle: istitle,
      // main_img,
      lang: Lang,
      defaultTimeZone: TZDefaultState,
    };
    console.log({ newValue });
    if (CategoriesNavigationState?.length) {
      newValue.navigation = CategoriesNavigationState;
    }
    // if (TagState?.length) {
    //   newValue.tags = TagState;
    // }
    if (LangsState?.length) {
      newValue.langs = LangsState;
    }
    if (HeaderImageBtn) {
      const headerValue = {
        title: {
          h: "תוספות",
          e: "Topping"
        },
        image: id
      }
      newValue.HeaderImageBtn = headerValue;
    } else {
      newValue.HeaderImageBtn = {};
    }
    if (LangsDefaultState?.length) {
      newValue.defaultLangs = LangsDefaultState;
    }
    if (TZDefaultState?.length) {
      newValue.defaultTimeZone = TZDefaultState;
    }
    if (preventClick) {
      newValue.preventClick = preventClick;
    }

    if (OrderState?.length) {
      newValue.order = OrderState;
    }
    if (CategoriesOrderState?.length) {
      newValue.catOrder = CategoriesOrderState;
    }

    if (IconsState?.length) {
      newValue.icons = IconsState;
    }
    if (TabitIdState?.length == 0) {
      newValue.TabitIdState = ["empty"];
    }
    else {
      newValue.TabitIdState = TabitIdState;
    }
    if (points) {
      newValue.points = points;
    }
    if (templateData.action.name === "Cat" || templateData.action.name === "Item") {
      newValue.changeBy = "admin";
      if (toggles?.topping && ToppingSelected) {
        console.log("ToppingSelected IS SEND", ToppingSelected);
        console.log(ToppingSelected);
        newValue.members = ToppingSelected;
      }
    }
    if (templateData.action.name === "Rest") {
      newValue.tabit = {
        enableSync: tabitSync,
        organizationToken: e?.target?.tabitToken?.value || ItemData?.tabit?.organizationToken || null,
        sync: toggles,
        showItemIfNotAvailable
      }
    }
    // if (branches?.length) {
    //   newValue.branches = branches;
    // }
    // if (icons?.length) {
    //   newValue.icons = icons;
    // }
    console.log({ newValue });
    // console.log({ toppings: ToppingSelected });
    await axios.post(`/api/add`, newValue);
    refresh();
    // close modal after successfully added student
    setEdit(false);
    setItemData({});
  };

  const updateCombination = (nameString, priceString, item, price, action) => {
    // פיצול המחרוזות למערכים
    const names = nameString.split('/');
    const prices = priceString.split('/');

    if (action === 'remove') {
      // מציאת האינדקס של הפריט להסרה
      const indexToRemove = names.indexOf(item);

      // אם הפריט נמצא, נסיר אותו ואת המחיר המתאים
      if (indexToRemove !== -1) {
        names.splice(indexToRemove, 1);
        prices.splice(indexToRemove, 1);
      }
    } else if (action === 'add') {
      // הוספת הפריט והמחיר
      names.push(item);
      prices.push(Number(price) + Number(prices[0]));
    }

    // חיבור מחדש של המחרוזות
    const updatedNameString = names.join('/');
    const updatedPriceString = prices.join('/');


    return {
      updatedNameString,
      updatedPriceString
    };
  };


  const handleCheckboxChange = (member) => {
    const { id, name, price } = member;

    setToppingSelected(prevState => {
      // אם prevState הוא null, נאתחל אותו למערך ריק
      if (!prevState) {
        prevState = [];
      }

      // מציאת האינדקס של התוספת במערך
      const foundIndex = prevState.findIndex(members => members.id == id);

      if (foundIndex !== -1) {
        // עדכון הערך של isSelected עבור התוספת שנמצאה
        const isSelected = !prevState[foundIndex].selected;

        // יצירת עותק של המערך עם הערך המעודכן
        const updatedState = [...prevState];
        updatedState[foundIndex] = { ...prevState[foundIndex], selected: isSelected };

        // קריאה לפונקציה לעדכון השם והמחיר
        const action = isSelected ? 'add' : 'remove';
        const { updatedNameString, updatedPriceString } = updateCombination(
          nameRef.current.value,
          priceRef.current.value,
          name,
          price,
          action
        );

        // עדכון השדות עם המחרוזות המעודכנות
        nameRef.current.value = updatedNameString;
        priceRef.current.value = updatedPriceString;
        console.log({ updatedState });
        return updatedState;
      }

      return prevState;
    });
  };
  const handleTranslateReq = async (e) => {
    setIsLoading(true);
    const auth = window.localStorage.getItem("password");
    const translateToLang = RestData.details?.langs.filter(lang => lang !== Lang)
      .map(lang => ({
        targetLanguage: translatedWeb?.language?.[lang]["en"],
        newLangCode: lang,
      }));
    const newValue = {
      auth,
      type: templateData.action.name,
      rest,
      id,
      lang: Lang,
      translateToLang,
    };
    const response = await axios.post(`/api/translate`, newValue);
    console.log({ response });
    setIsLoading(false);
    setEdit(false);
    setItemData({});
    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Item has been translated successfully",
      });
    }
    else {
      Swal.fire({
        icon: "error",

        title: "Error",
        text: "Item has not been translated",
      });
    }
    // refresh();

  };

  // const handleUpdateReq = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   const name = e.target.name.value;
  //   const desc = e.target.desc.value;
  //   const price = e.target.price.value;
  //   const newValue = { rest, id, name, desc, lang: Lang, price };

  //   await axios.put(`/api/edit` + templateData.action.name, newValue);
  //   refresh();
  //   // close modal after successfully added student
  //   setEdit(false);
  // };
  // const langString = () => {
  //   return languages?.[Lang] || "";
  // };
  return (
    <Modal setModal={setEdit} setItemData={setItemData}>
      <h1
        className={
          "mx-8 text-xl font-bold mb-5 text-center " + (ItemData.group ? "text-purple-600" : "")
        }
      >{console.log({ type })}
        {(isAdd ? translatedWeb?.editModal?.add[clientlanguage] : translatedWeb?.editModal?.edit[clientlanguage]) + " " +
          // templateData.singular+
          (type == "menuItems" ? translatedWeb?.editModal?.dose[clientlanguage] : type == "categories" ? translatedWeb?.editModal.category[clientlanguage] : type === "restaurants" ? translatedWeb?.editModal?.rest[clientlanguage] : type === "users" ? translatedWeb?.editModal?.user[clientlanguage] : " ") +
          (ItemData.id ? " " + ItemData.id : "")}
        {type === "menuItems" || type === "categories"
          ? translatedWeb?.editModal?.in[clientlanguage] + (languages?.[Lang] || "")
          : ""}
      </h1>
      {ItemData.group && (
        <h1 className={"text-l font-bold mb-5"}>
          A dose of a group{RestData.group.name}
          <br />
          Appears in restaurants{JSON.stringify(RestData.group.rests)}
        </h1>
      )}
      <form
        // onSubmit={handleAddReq}
        class="w-full pl-3"
        id="editModalForm"
        style={{
          maxHeight: "60vh",
          minHeight: "50%",
          overflow: "auto",
          position: "relative",
        }}
      >
        {templateData.action.name == "User" && (
          <>
            {console.log({ ItemData })}
            <div class="md:flex md:items-center mb-6">
              <CustomLabel content={"ID:"} />
              <div class="md:w-2/3">
                <input disabled
                  type="text"
                  name="id"
                  value={!ItemData?.rest ? null : ItemData?.id}
                  placeholder={!ItemData?.rest && "Will automatically create"}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <CustomLabel content={"Password:"} />
              <div class="md:w-2/3">
                <input type="text"
                  ref={passwordRef}
                  name="restPass"
                  defaultValue={!ItemData?.rest ? generatePassword() : ItemData?.name}
                  // defaultValue={generatePassword()}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                <button type="button" onClick={() => setNewuserPass()} className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs">Generate Password</button>
              </div>

            </div>
            <div class="md:flex md:items-center mb-6">
              <CustomLabel content={"גישה:"} />
              <div class="md:w-2/3">
                <input
                  type='checkbox'
                  className={
                    "toggle " + (BoolState ? "bg-green-500" : "bg-red-500")
                  }
                  defaultChecked={BoolState}
                  onChange={() => { setBoolState(e => !e); setOptionValue(null) }}
                />
                <span class="text-gray-500 mx-2">
                  {BoolState ? "Group Management" : "Single restaurant"}
                </span>

              </div>

            </div>

            <div class="md:flex md:items-center mb-6">
              <CustomLabel content={"Language:"} />
              <div class="md:w-2/3">
                {console.log({ RestUserLang })}
                <EditArray
                  type="langs"
                  OrderState={RestUserLang}
                  setOrderState={setRestUserLang}
                  OrderOptions={RestUserLangOtion}
                  limetToOne={true}
                />
              </div>
            </div>
          </>
        )}
        {templateData.action.name !== "Rest" && templateData.action.name !== "User" && (
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label
                className={`block font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0
                    ${ItemData.isActive ? "text-green-500" : "text-red-500"}`}
              >
                {isAdd ? "" : ItemData.isActive ? translatedWeb?.editModal?.active[clientlanguage] : translatedWeb?.editModal?.inactive[clientlanguage]}
              </label>
            </div>
          </div>
        )}
        {/* name field  */}
        {templateData.action.name !== "User" && (
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                {translatedWeb?.editModal?.name[clientlanguage]}:
              </label>
            </div>
            <div class="md:w-2/3">
              <input
                class="outline-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:border-gray-300"
                id="userName"
                type="text"
                name="name"
                defaultValue={ItemData.name}
                required
                onChange={e => SetIsPromotion(e.target.value)}
                ref={nameRef}
              // value={e => SetIsPromotion(e.target.value)}
              />
            </div>
          </div>)}
        {templateData.action.name === "Rest" && (
          <>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                  {translatedWeb?.editModal?.language[clientlanguage]}:
                </label>
              </div>
              <div class="md:w-2/3">
                <EditArray
                  type="langs"
                  OrderState={LangsState}
                  setOrderState={setLangsState}
                  OrderOptions={LangsOptions}
                />
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                  {translatedWeb?.editModal?.defaultLanguage[clientlanguage]}:
                </label>
              </div>
              <div class="md:w-2/3">
                <EditArray
                  type="langs"
                  OrderState={LangsDefaultState}
                  setOrderState={setLangsDefaultState}
                  OrderOptions={OrderStateLangsOptions}
                  limetToOne={true}
                />
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                  {translatedWeb?.editModal?.timeZone[clientlanguage]}:
                </label>
              </div>
              <div class="md:w-2/3">
                <EditArray
                  type="timeZone"
                  OrderState={TZDefaultState}
                  setOrderState={setTZDefaultState}
                  OrderOptions={OrderStateTZOptions}
                  limetToOne={true}
                />
              </div>
            </div>

            <div>
              <h3 class="mx-8 text-lg font-bold mb-5 text-center ">אינטגרציה לטאביט</h3>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label className={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                  {/* {translatedWeb?.editModal?.toggels?.[toggleName][clientlanguage]}: */}
                  enble tabit
                </label>
              </div>
              <div className="md:w-2/3">
                <div
                  key={"enableSync"}
                  className='count flex justify-start '
                  onClick={(e) => e.stopPropagation()}
                >
                  <input
                    type='checkbox'
                    className={"toggle " + (tabitSync ? "bg-green-500" : "bg-red-500")}
                    checked={tabitSync}
                    onChange={() => setTabitSync(e => !e)}
                  />
                </div>
              </div>
            </div >
            {tabitSync && (<><div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                  tabit Token:
                </label>
              </div>
              <div class="md:w-2/3">
                <input
                  class="outline-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:border-gray-300"
                  id="tabitToken"
                  type="text"
                  name="tabitToken"
                  defaultValue={ItemData?.tabit?.organizationToken}
                  required
                // onChange={e => SetIsPromotion(e.target.value)}
                // value={e => SetIsPromotion(e.target.value)}
                />
              </div>
            </div>
              <div>
                {Object.entries(toggles).map(([toggleName, toggleValue], index) => (
                  <div div key={index} className="md:flex md:items-center mb-6">
                    <div className="md:w-1/3">
                      <label className={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                        {translatedWeb?.editModal?.toggels?.[toggleName][clientlanguage]}:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <div
                        key={toggleName}
                        className='count flex justify-start'
                        onClick={(e) => e.stopPropagation()}
                      >
                        <input
                          type='checkbox'
                          className={"toggle " + (toggleValue ? "bg-green-500" : "bg-red-500")}
                          checked={toggleValue}
                          onChange={() => handleToggleChange(toggleName)}
                        />
                      </div>
                    </div>
                  </div >
                ))}
              </div>
              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                  <label className={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                    {/* {translatedWeb?.editModal?.toggels?.[toggleName][clientlanguage]}: */}
                    במקרה של חוסר במלאי:
                  </label>
                </div>
                {console.log({ showItemIfNotAvailable })}
                <div className="md:w-2/3">
                  <div
                    key={"showItemIfNotAvailable"}
                    className='count flex justify-start '
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      type='checkbox'
                      className={"toggle " + (showItemIfNotAvailable ? "bg-green-500" : "bg-red-500")}
                      checked={showItemIfNotAvailable}
                      onChange={() => setShowItemIfNotAvailable(e => !e)}
                    />
                    <span className="text-gray-500 mx-2">{showItemIfNotAvailable ? "להציג כלא זמין" : "להסיר מהתפריט"}</span>

                  </div>
                </div>
              </div >

            </>)}

          </>

        )}
        {templateData.action.name !== "Rest" && templateData.action.name !== "User" && (
          /* desc field  */
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                {translatedWeb?.editModal?.description[clientlanguage]}:
              </label>
            </div>
            <div class="md:w-2/3">
              <textarea
                rows="4"
                class="outline-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:border-gray-300"
                id="userDesc"
                type="text"
                name="desc"
                defaultValue={ItemData.desc}
              />
            </div>
          </div>
        )}
        {/* Only For Items  */}
        {templateData.action.name === "Item" && (
          /* price field */
          <>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                  {translatedWeb?.editModal?.icons[clientlanguage]}:
                </label>
              </div>
              <div class="md:w-2/3">
                <EditArray
                  type="icons"
                  OrderState={IconsState}
                  setOrderState={setIconsState}
                  OrderOptions={IconsOptions}
                />
              </div>
            </div>

            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                  {translatedWeb?.editModal?.points[clientlanguage]}:
                </label>
              </div>
              <div class="md:w-2/3">
                <input
                  class="outline-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:border-gray-300"
                  id="points"
                  type="text"
                  name="points"
                  defaultValue={ItemData.points}
                />
              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                  {translatedWeb?.editModal?.price[clientlanguage]}:
                </label>
              </div>
              <div class="md:w-2/3">
                <input
                  class="outline-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:border-gray-300"
                  id="price"
                  type="text"
                  name="price"
                  defaultValue={ItemData.price}
                  ref={priceRef}
                />
              </div>
            </div>
            <div class="md:flex mb-6">
              <input
                rows="4"
                id="newItem"
                type="checkbox"
                name="newItem"
                defaultChecked={ItemData.newItem}
              />
              <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                {translatedWeb?.editModal?.newItem[clientlanguage]}
              </label>
            </div>
            {IsPromotion?.startsWith('קידום ') && <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>

                  {translatedWeb?.editModal?.navigation_to_Category[clientlanguage]}:
                </label>
              </div>
              <div class="md:w-2/3">
                <EditArray
                  type="categories"
                  OrderState={CategoriesNavigationState}
                  setOrderState={setCategoriesNavigationState}
                  OrderOptions={CategoriesOrderOptions}
                  limetToOne={true}
                />
                <p>   <small>עובד רק בתבנית מודולר, לא עובד על קבוצת קמפאי</small> </p>
              </div>
            </div>}

            {/* <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/3">
                    <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                      מיקום בקטגוריה
                    </label>
                  </div>
                  <div class="md:w-2/3">
                    <input
                      class="outline-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:border-gray-300"
                      id="order"
                      type="text"
                      name="order"
                      defaultValue={ItemData.order}
                    />
                  </div>
                </div> */}
            {/* <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/3">
                    <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                      באנר?
                    </label>
                  </div>
                  <div class="md:w-2/3">
                    <input
                      class="outline-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:border-gray-300"
                      id="istitle"
                      type="checkbox"
                      name="istitle"
                      defaultChecked={ItemData.isTitle}
                    />
                  </div>
                </div> */}
            {/* <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/3">
                    <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                      סניפים
                    </label>
                  </div>
                  <div class="md:w-2/3">
                    <Select
                      onChange={(e) => {
                        branchSelectedOptions = e;
                      }}
                      name="branches"
                      options={branchOptions}
                      defaultValue={branchSelectedOptions}
                      placeholder="בחרו מהרשימה"
                      isMulti
                      isRtl
                      noOptionsMessage={() => "אין אפשרויות"}
                    />
                  </div>
                </div> */}
            {/* <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/3">
                    <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                      קטגוריות
                    </label>
                  </div>
                  <div class="md:w-2/3">
                    <Select
                      onChange={(e) => {
                        selectedOptions = e;
                      }}
                      name="categories"
                      options={options}
                      defaultValue={selectedOptions}
                      placeholder="בחרו מהרשימה"
                      isMulti
                      isRtl
                      noOptionsMessage={() => "אין אפשרויות"}
                    />
                  </div>
                </div> */}
            <div class="md:flex md:items-center mb-6">
              <CustomLabel content={translatedWeb?.editModal?.aiTags[clientlanguage] + ":"} />
              <div class="md:w-2/3">
                <EditArray
                  type="icons"
                  OrderState={TagState}
                  setOrderState={setTagState}
                  OrderOptions={TagsOptions}
                />
              </div>
            </div>
            {ItemData.id && (
              <>
                <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/3">
                    <label className={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                      {translatedWeb?.editModal?.main_image[clientlanguage]}:
                    </label>
                    <input
                      className={`block w-16 text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 mr-4`}
                      id="imgBool"
                      type="number"
                      name="imgBool"
                      defaultValue={ItemData.img || 0}
                    />
                  </div>
                  <div class="md:w-2/3 min-h-[120px] relative">
                    <span class="min-h-[120px] flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg w-full">
                      <label
                        htmlFor="UploadModal"
                        onClick={() =>
                          setUpload({ path: "Items", isMultiple: false })
                        }
                        className="cursor-pointer p-3"
                      >
                        <img
                          src={
                            // ItemData.main_img ||
                            "/api/img?url=Items/" +
                            ImgSrc +
                            ".png?v=" +
                            ImgVersion
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "https://www.survivorsuk.org/wp-content/uploads/2017/01/no-image.jpg";
                          }}
                          alt=""
                          className="object-contain"
                        />
                      </label>
                    </span>
                    <i className="pointer-events-none absolute top-1 left-[7px] text-xl mt-1 px-2 pt-1 pb-1 bg-orange-500 hover:bg-orange-600 rounded-lg text-white ri-image-edit-line" />
                  </div>
                </div>
                <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/3">
                    <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                      {translatedWeb?.editModal?.gallery[clientlanguage]}:
                    </label>
                    <input
                      className={`block w-16 text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 mr-4`}
                      id="galleryBool"
                      type="number"
                      defaultValue={ItemData.gallery || 0}
                      name="galleryBool"
                    />
                  </div>
                  <div class="md:w-2/3 min-h-[120px] relative">
                    <span class="min-h-[120px] flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg w-full">
                      <label
                        htmlFor="UploadModal"
                        onClick={() =>
                          setUpload({
                            path: "ItemsGallery/" + ItemData.id,
                            isMultiple: true,
                          })
                        }
                        className="cursor-pointer p-3"
                      >
                        <img
                          src={
                            "/api/img?url=ItemsGallery/" +
                            GallerySrc +
                            "/0.png?v=" +
                            ImgVersion
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "https://www.survivorsuk.org/wp-content/uploads/2017/01/no-image.jpg";
                          }}
                          alt=""
                          className="object-contain"
                        />
                      </label>
                    </span>
                    <i className="pointer-events-none absolute top-1 left-[7px] text-xl mt-1 px-2 pt-1 pb-1 bg-orange-500 hover:bg-orange-600 rounded-lg text-white ri-image-edit-line" />
                  </div>
                </div>
                <div class="md:flex mb-6">
                  <input
                    rows="4"
                    id="HeaderImageBtn"
                    type="checkbox"
                    name="HeaderImageBtn"
                    defaultChecked={HeaderImageBtn}
                    onChange={e => setHeaderImageBtn(e.target.checked)}
                  />
                  <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                    {translatedWeb?.editModal?.toppingImage[clientlanguage]}
                  </label>
                </div>
                {/* Item */}
                {TabitData?.length && RestData?.details?.tabit?.enableSync &&
                  <>
                    <div class="md:flex md:items-center mb-6">
                      <div class="md:w-1/3">
                        <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                          {translatedWeb?.editModal?.tabitName[clientlanguage]}:
                        </label>
                        <small>
                          {translatedWeb?.editModal?.suggestedLabel[clientlanguage]}
                        </small>
                      </div>
                      <div class="md:w-2/3">
                        <EditArray
                          type="tabitData"
                          OrderState={TabitIdState}
                          setOrderState={setTabitIdState}
                          OrderOptions={TabitData?.map(item => ({ value: item.tabitId, label: item.name })) || []}
                          filterOptions={TabitData?.filter((item) => item?.uppsaleID
                          )?.map(item => ({ value: item.tabitId, label: item.name })) || []}
                          limetToOne={true}
                          suggestedName={ItemData.name}
                        />
                      </div>
                    </div>
                    {toggles?.topping && ItemData?.members && (
                      <>
                        <div class="md:flex md:items-center mb-6">
                          <CustomLabel content={"חישוב תוספות:"} />
                          <div class="md:w-2/3">
                            <table className=" bg-white">
                              <thead>
                                <tr>
                                  <th className="w-1/12 px-4 py-2">הוספה</th>
                                  <th className="w-1/2 px-4 py-2">שם</th>
                                  <th className="w-1/4 px-4 py-2">מחיר</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ToppingSelected?.length > 0 && ToppingSelected?.map(member => (
                                  <tr key={member.id} >
                                    <td className="border px-4 py-2">
                                      <input
                                        type="checkbox"
                                        defaultChecked={!!member.selected}
                                        onChange={() => handleCheckboxChange(member)}
                                      />
                                    </td>
                                    <td className="border px-4 py-2">{member.name}</td>
                                    <td className="border px-4 py-2">{member.price}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                }
                {HeaderImageBtn && <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/3">
                    <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                      {translatedWeb?.editModal?.toppingImage[clientlanguage]}:
                    </label>
                    <input
                      className={`block w-16 text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 mr-4`}
                      id="HeaderImageBtnImage"
                      type="number"
                      defaultValue={ItemData.id}
                      name="HeaderImageBtnImage"
                      disabled
                    />
                  </div>
                  <div class="md:w-2/3 min-h-[120px] relative">
                    <span class="min-h-[120px] flex flex-col items-center justify-center  border-2 border-gray-300 border-dashed rounded-lg w-full">
                      <label
                        htmlFor="UploadModal"
                        onClick={() =>
                          setUpload({
                            path: `RestImages/${RestData?.details?.id}/HeaderImageBtn`,
                            isMultiple: false,
                          })
                        }
                        className="cursor-pointer p-3"
                      >
                        <img
                          src={
                            ItemData.id
                              ? `/api/img?url=RestImages/${RestData?.details?.id}/HeaderImageBtn/` +
                              ItemData.id +
                              ".png?v=" +
                              ImgVersion
                              : ""
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "https://www.survivorsuk.org/wp-content/uploads/2017/01/no-image.jpg";
                          }}
                          alt=""
                          className="object-contain"
                        />
                      </label>
                    </span>
                    <i className="pointer-events-none absolute top-1 left-[7px] text-xl mt-1 px-2 pt-1 pb-1 bg-orange-500 hover:bg-orange-600 rounded-lg text-white ri-image-edit-line" />
                  </div>

                </div>}
              </>
            )}
          </>
        )}
        {templateData.action.name === "Cat" && (
          <>
            {ItemData.id && (
              <>
                <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/3">
                    <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                      {translatedWeb?.editModal?.main_image[clientlanguage]}:
                    </label>
                    <input
                      className={`block w-16 text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 mr-4`}
                      id="imgBool"
                      type="number"
                      name="imgBool"
                      defaultValue={ItemData.img || 0}
                    />
                  </div>
                  <div class="md:w-2/3 min-h-[120px] relative">
                    <span class="min-h-[120px] flex flex-col items-center justify-center border-2 border-gray-300 border-dashed rounded-lg w-full">
                      <label
                        htmlFor="UploadModal"
                        onClick={() =>
                          setUpload({
                            path: "Categories",
                            isMultiple: false,
                          })
                        }
                        className="cursor-pointer p-3"
                      >
                        <img
                          src={
                            // ItemData.main_img ||
                            "/api/img?url=Categories/" +
                            ImgSrc +
                            ".png?v=" +
                            ImgVersion
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "https://www.survivorsuk.org/wp-content/uploads/2017/01/no-image.jpg";
                          }}
                          alt=""
                          className="object-contain"
                        />
                      </label>
                    </span>
                    <i className="pointer-events-none absolute top-1 left-[7px] text-xl mt-1 px-2 pt-1 pb-1 bg-orange-500 hover:bg-orange-600 rounded-lg text-white ri-image-edit-line" />
                  </div>
                </div>
                <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/3">
                    <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                      {translatedWeb?.editModal?.gallery[clientlanguage]}:
                    </label>
                    <input
                      className={`block w-16 text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 mr-4`}
                      id="galleryBool"
                      type="number"
                      defaultValue={ItemData.gallery || 0}
                      name="galleryBool"
                    />
                  </div>
                  <div class="md:w-2/3 min-h-[120px] relative">
                    <span class="min-h-[120px] flex flex-col items-center justify-center  border-2 border-gray-300 border-dashed rounded-lg w-full">
                      <label
                        htmlFor="UploadModal"
                        onClick={() =>
                          setUpload({
                            path: "CategoriesGallery/" + ItemData.id,
                            isMultiple: true,
                          })
                        }
                        className="cursor-pointer p-3"
                      >
                        <img
                          src={
                            ItemData.id
                              ? "/api/img?url=CategoriesGallery/" +
                              GallerySrc +
                              "/0.png?v=" +
                              ImgVersion
                              : ""
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "https://www.survivorsuk.org/wp-content/uploads/2017/01/no-image.jpg";
                          }}
                          alt=""
                          className="object-contain"
                        />
                      </label>
                    </span>
                    <i className="pointer-events-none absolute top-1 left-[7px] text-xl mt-1 px-2 pt-1 pb-1 bg-orange-500 hover:bg-orange-600 rounded-lg text-white ri-image-edit-line" />
                  </div>

                </div>
                {TabitData?.length && RestData?.details?.tabit?.enableSync &&
                  <>
                    <div class="md:flex md:items-center mb-6">
                      <div class="md:w-1/3">
                        <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                          {translatedWeb?.editModal?.tabitName[clientlanguage]}:
                        </label>
                        <small>
                          {translatedWeb?.editModal?.suggestedLabel[clientlanguage]}
                        </small>
                      </div>
                      <div class="md:w-2/3">
                        <EditArray
                          type="tabitData"
                          OrderState={TabitIdState}
                          setOrderState={setTabitIdState}
                          OrderOptions={TabitData?.map(item => ({ value: item.tabitId, label: item.name })) || []}
                          limetToOne={true}
                          suggestedName={ItemData.name}
                        />
                      </div>
                    </div>
                  </>
                }
              </>
            )}
            {/* <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/3">
                    <label class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                      קטגוריות אב
                    </label>
                  </div>
                  <div class="md:w-2/3">
                    <Select
                      onChange={(e) => {
                        selectedOptions = e;
                      }}
                      name="categories"
                      options={options}
                      defaultValue={selectedOptions}
                      placeholder="בחרו מהרשימה"
                      isMulti
                      isRtl
                      noOptionsMessage={() => "אין אפשרויות"}
                    />
                  </div>
                </div> */}
            <div class="md:flex mb-2">
              <input
                rows="4"
                id="preventClick"
                type="checkbox"
                name="preventClick"
                defaultChecked={ItemData.preventClick}
              />
              <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"}  mb-1 md:mb-0 pr-4`}>
                {translatedWeb?.editModal?.preventClick[clientlanguage]}
              </label>
            </div>
            <div class="md:flex mb-2">
              <input
                rows="4"
                id="alertCat"
                type="checkbox"
                name="alertCat"
                defaultChecked={ItemData.alertCat}
              />
              <label class={`block text-gray-500 font-bold md:${isRtl ? " text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                {translatedWeb?.editModal?.alertCat[clientlanguage]}
              </label>
            </div>
            <div class="md:flex mb-6">
              <input
                rows="4"
                id="popupCat"
                type="checkbox"
                name="popupCat"
                defaultChecked={popupCat}
                onChange={e => setpopupCat(e.target.checked)}
              />
              <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                {translatedWeb?.editModal?.popupCat[clientlanguage]}
              </label>
            </div>

            {popupCat && <div class="md:flex md:items-center mb-6">
              <div class="md:w-1/3">
                <label class={`block text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 pr-4`}>
                  {translatedWeb?.editModal?.popupImage[clientlanguage]}:
                </label>
                <input
                  className={`block w-16 text-gray-500 font-bold md:${isRtl ? "text-right" : "text-left"} mb-1 md:mb-0 mr-4`}
                  id="popupImage"
                  type="number"
                  defaultValue={ItemData.id}
                  name="popupImage"
                  disabled
                />
              </div>
              <div class="md:w-2/3 min-h-[120px] relative">
                <span class="min-h-[120px] flex flex-col items-center justify-center  border-2 border-gray-300 border-dashed rounded-lg w-full">
                  <label
                    htmlFor="UploadModal"
                    onClick={() =>
                      setUpload({
                        path: `RestImages/${RestData?.details?.id}/popup`,
                        isMultiple: false,
                      })
                    }
                    className="cursor-pointer p-3"
                  >
                    <img
                      src={
                        ItemData.id
                          ? `/api/img?url=RestImages/${RestData?.details?.id}/popup/` +
                          ItemData.id +
                          ".png?v=" +
                          ImgVersion
                          : ""
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src =
                          "https://www.survivorsuk.org/wp-content/uploads/2017/01/no-image.jpg";
                      }}
                      alt=""
                      className="object-contain"
                    />
                  </label>
                </span>
                <i className="pointer-events-none absolute top-1 left-[7px] text-xl mt-1 px-2 pt-1 pb-1 bg-orange-500 hover:bg-orange-600 rounded-lg text-white ri-image-edit-line" />
              </div>

            </div>}

            <div class="md:flex md:items-center mb-6">
              <CustomLabel content={translatedWeb?.editModal?.categories[clientlanguage] + ":"} />
              <div class="md:w-2/3">
                <EditArray
                  type="categories"
                  OrderState={CategoriesOrderState}
                  setOrderState={setCategoriesOrderState}
                  OrderOptions={CategoriesOrderOptions}
                />

              </div>
            </div>
            <div class="md:flex md:items-center mb-6">
              <CustomLabel content={translatedWeb?.editModal?.meals[clientlanguage] + ":"} />
              <div class="md:w-2/3">
                <EditArray
                  type="items"
                  OrderState={OrderState}
                  setOrderState={setOrderState}
                  OrderOptions={OrderOptions}
                />

              </div>
            </div>
            {/* <div class="md:flex md:items-center mb-6">
              <CustomLabel content={translatedWeb?.editModal?.aiTags[clientlanguage] + ":"} />
              <div class="md:w-2/3">
                <EditArray
                  type="icons"
                  OrderState={TagState}
                  setOrderState={setTagState}
                  OrderOptions={TagsOptions}
                />
              </div>
            </div> */}
          </>
        )}
      </form>
      {/* submit button  */}
      <div style={{ display: "flex", flexWrap: 'wrap', justifyContent: "space-between" }} className="md:flex md:items-center pb-1">
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          {/* <span
                      onClick={() => {
                        let e = {
                          preventDefault: () => {},
                          isCopy: id,
                          target: document.getElementById("editModalForm"),
                        };
                        handleAddReq(e);
                      }}
                      className="cursor-pointer px-12 py-2 bg-pink-500 hover:bg-pink-600 rounded-lg text-white"
                    >
                      שכפל
                    </span> */}

          {!isAdd && type !== "restaurants" && type !== "users" && (
            <>
              <span
                onClick={handleDelete}
                className="cursor-pointer px-12 py-2 bg-red-500 hover:bg-red-600 rounded-lg text-white"
              >
                {translatedWeb?.editModal?.remove[clientlanguage]}
              </span>
            </>
          )}

          <button
            onClick={() => {
              const target = document.querySelector("form");
              handleAddReq({ target });
            }}
            className="mx-2 px-12 py-2 bg-sky-500 hover:bg-sky-600 rounded-lg text-white"
          >
            {isAdd ? translatedWeb?.editModal?.addItem[clientlanguage] : translatedWeb?.editModal?.update[clientlanguage]}
          </button>
        </div>
        {/* {!isAdd && (type === "menuItems" || type === "categories") && (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}> 
            <button
              onClick={(e) => handleTranslateReq(e)}
              className="mx-2 px-12 py-2 bg-teal-500 hover:bg-teal-600 rounded-lg text-white">
              {translatedWeb?.editModal.translate[clientlanguage]} {type == "menuItems" ? translatedWeb?.editModal?.dose[clientlanguage] : type == "categories" ? translatedWeb?.editModal.category[clientlanguage] : null}
            </button>
          </div>)} */}
      </div>

      {Upload && (
        <UploadModal
          data={{
            setModal: setUpload,
            isImg: true,
            // isMultiple: Upload.isMultiple,
            isMultiple: false,
            path: Upload.path,
            customFileName: Upload.isMultiple ? null : ItemData.id + ".png",
            numericName: Upload.isMultiple,
            onComplete: (files) => {
              // Swal.fire({
              //   title: "התמונה הועלתה בהצלחה",
              //   icon: "success",
              //   confirmButtonText: "תודה",
              // });
              console.log({ Upload });
              if (Upload.path.includes("Gallery/")) {
                document.getElementById("galleryBool").value = ItemData.id;
                setGallerySrc(ItemData.id);
              } else {
                document.getElementById("imgBool").value = ItemData.id;
                setImgSrc(ItemData.id);
              }
              // document.getElementById("ID").value = "Tamer Jarrar";
              setImgVersion((e) => e + 1);
              // setMainImg(url);
              setUpload(false);
            },
          }}
        />
      )
      }
    </Modal >
  );
};

export default EditModal;
